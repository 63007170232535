/*=========================================
=          24. Brand Area Css               =
===========================================*/

.brand-wrap {
     align-items: center;
    & .single-brand-wrap {
         text-align: center;
          margin-bottom: 50px;
          filter: grayscale(100);
          -webkit-filter: grayscale(100);
          opacity: .7;
          transition: all .25s ease-out;
          &:hover {
               filter: grayscale(0);
               -webkit-filter: grayscale(0);
          }
    }
}

.brand-logo-two {
     .single-brand-wrap {
          text-align: center;
     }
}