
.counterbox {
    display: flex;
    align-items: center;
    padding: 50px;

    &-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    &-block {
        width: 50%;
        min-height: 190px;
        display: flex;
        padding: 0px;
        margin: 0px;
        a {
            &:hover {
                color: $theme-color--default;
            }
        }

        h2 {
            margin-bottom: 10px;
            line-height: 1;
        }

        h6 {
            font-size: 16px;
            margin-bottom: 0;
        }

        border-right: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        justify-content: center;
        align-items: center;
        
        &:last-child {
            border-right: none;
            border-bottom: 0;
        }
        &:nth-child(2) {
            border-right: none;
        }
        &:nth-child(3) {
            border-bottom: none;
        }
        

        // &:nth-child(1){
        //     align-items: flex-end;
        //     border-bottom: 1px solid rgba(#333, 0.9);
        // }
        // &:nth-child(2) {
        //     justify-content: flex-start;
        //     border-left: 1px solid rgba(#333, 0.9);
        // }
        // &:nth-child(3) {
        //     align-items: flex-start;
        //     border-right: 1px solid rgba(#333, 0.9);
        // }

        // &:nth-child(4) {
        //     justify-content: flex-end;
        //     border-top: 1px solid rgba(#333, 0.9);
        // }
    }

//     @media #{$lg-layout} {
//         padding: 30px;

//         &-block {
//             min-width: 180px;
//             min-height: 180px;
//         }
//     }

//     @media #{$sm-layout} {
//         padding: 30px;

//         &-block {
//             min-width: 180px;
//             min-height: 180px;
//         }
//     }

//     @media #{$xs-layout} {
//         padding: 15px;

//         &-block {
//             min-width: 40%;
//             margin: 3px;
//             padding: 15px;
//         }
//     }

//     @media only screen and (max-width: 400px){
//         &-inner{
//             padding-top: 30px;
//             padding-bottom: 30px;
//         }
//         &-block{
//             display: block;
//             width: 100%;
//             justify-content: center;
//             align-items: center;
//             text-align: center;
//             min-height: auto;
//             min-width: auto;
//             margin: 0;
//             margin-top: -1px;
//             padding: 15px;
//             border: none !important;
//         }
//     }
}








.fun-fact-style-one {
    .single-fun-fact {
        text-align: center;
    }
    
}

.fun-fact {
    &--one {
        margin: 20px 0;
    }
    &__count {
        font-weight: 600;
        color: $white;
        font-size: 46px;
    }
    &__text {
        color: $white;
        font-size: 18px;
    }
}