@charset "UTF-8";
/*============================
    CSS Index         
==============================

    01. Default Css
        - Breadvrumb  
        - Scroll top 
    02. Section Spacing Css
    03. Preloader Css
    04. Offcanvas Search Area
    05. Offcanvas Menu Wrapper   
    06. Offcanvas Minicart Area
    07. Popup Search Css 
    08. Theme Color Css
    09. Humburger Area Css

    10. container Css 
    11. Animate Css 

    12. Header Css
        - Navigation Menu Css
        - Multilevel Submenu Css
        - Mega Menu Css
        - Mobile Menu Overlay Css
    13. Footer Css
    14. Sidebar Css
    15. Contact Form Css
    16. Services Css
    17. Feaures Css
    18. Work Process Css 
    19. Project Css 
    20. Button Css
    21. tastimonials Css 
    22. Team Css
    23. Counter Css  
    24. Brand Area Css 
    25. Product Area Css   
    26. Special Offer Area Css
    27. Newsletter Area Css 
    28. Properties Area Css 
    29. Accordions Css 
    30. Pricing table Css  
    31. Coming Soon Css 
    32. Career Css 
    33. Listing style Css
    34. Toggle Style Css
    35. Progress Bar
    36. Icon Style Css
    36. Blog pages Css
    37. About us pages Css
    38. Real Estate hero Slider css
    39. Architecture hero Slider Css 
    40. Shop style Css
    41. Single Product details Css  
    42. Product compare Css
    43. Cart and Checkout page Css 
    44. My account style Css 
    45. Preview Page Css   

/*=====  End of CSS Index  ======*/
/*=============================================
=             01. Default Css                 =
=============================================*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900|Roboto+Condensed:300,400,700|Alegreya:400,500,700,800,900|Roboto+Slab:300,400,500,600,700,800,900&display=swap");
*, *::after, *::before {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  line-height: 1.74;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  visibility: visible;
  font-family: "Montserrat", sans-serif;
  color: #111;
  position: relative;
  background-color: #ffffff;
}

body.no-overflow {
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  color: #111111;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.41;
}

h1 {
  font-size: 56px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h1 {
    font-size: 46px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 34px;
  }
}

h2 {
  font-size: 46px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h2 {
    font-size: 36px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h2 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 767px) {
  h2 {
    font-size: 30px;
  }
}

h3 {
  font-size: 36px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h3 {
    font-size: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h3 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  h3 {
    font-size: 25px;
  }
}

h4 {
  font-size: 32px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h4 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h4 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  h4 {
    font-size: 24px;
  }
}

h5 {
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  h5 {
    font-size: 20px;
  }
}

h6 {
  font-size: 18px;
}

p:last-child {
  margin-bottom: 0;
}

a, button {
  color: inherit;
  display: inline-block;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

a, button, img, input, span {
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

.btn.focus, .btn:focus {
  outline: none;
  box-shadow: none;
}

a:hover {
  text-decoration: none;
  color: #4241ff;
}

button, input[type="submit"] {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.mark, mark {
  padding: 0 0;
  background-color: transparent;
}

/*--
    Default Class
*/
.font-family-two {
  font-family: "Roboto Condensed", sans-serif;
}

.font-family-three {
  font-family: "Alegreya", serif;
}

.font-family-four {
  font-family: "Roboto Slab", serif;
}

.font-style-italic {
  font-style: italic;
}

.font-weight-xxl--bold {
  font-weight: 800;
}

.font-weight-xl--bold {
  font-weight: 700;
}

.font-weight--bold {
  font-weight: 600;
}

.font-weight--reguler {
  font-weight: 500;
}

.font-weight--normal {
  font-weight: 400;
}

.font-weight--light {
  font-weight: 300;
}

.text-color-primary {
  color: #4241ff !important;
}

.text-color-secondary {
  color: #d2a98e;
}

.text-color-three {
  color: #002FA6;
}

.text-color-four {
  color: #ea622b;
}

.text-color-five {
  color: #75ad1c;
}

.text-black {
  color: #111111 !important;
}

.text-red {
  color: #f04 !important;
}

.text-green {
  color: #23a455 !important;
}

.bg-white {
  background: #ffffff;
}

.bg-black {
  background: #212121;
}

.bg-gray {
  background: #F8F8F8;
}

.bg-gray-2 {
  background: #F6FAFE;
}

.bg-gray-3 {
  background: #f6f2ed;
}

.bg-gray-4 {
  background: #f9f9f9;
}

.bg-theme-default {
  background: #4241ff;
}

.theme-bg-secondary {
  background: #d2a98e;
}

.theme-bg-five {
  background: #75ad1c;
}

.sub-heading {
  color: #6d70a6;
}

.black-bg {
  background: #000000;
}

.border-radus-5 {
  border-radius: 5px;
}

.text-green {
  color: #d2a98e;
}

.bg-gradient {
  background: -webkit-linear-gradient(top, #FFF 0, #F5F5F5 100%);
}

.black-overlay {
  position: relative;
}

.black-overlay::before {
  background: rgba(0, 0, 0, 0.5);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

/*-- Tab Content & Pane Fix --*/
select {
  padding: 3px 20px;
  height: 56px;
  max-width: 100%;
  width: 100%;
  outline: none;
  border: 1px solid #f8f8f8;
  border-radius: 5px;
  background: #f8f8f8 url("../images/icons/selector-icon.png") no-repeat center right 20px;
  background-color: #f8f8f8;
  -moz-appearance: none;
  -webkit-appearance: none;
}

select:focus {
  background: #f8f8f8 url("../images/icons/selector-icon.png") no-repeat center right 20px !important;
}

.fixed-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/*------- Defauld Class --------*/
.text-black {
  color: #333;
}

.box-shadow-top {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
}

.border {
  border: 1px solid #ededed !important;
}

.border-top {
  border-top: 1px solid #ededed !important;
}

.border-right {
  border-right: 1px solid #ededed !important;
}

.border-bottom {
  border-bottom: 1px solid #ededed !important;
}

.border-left {
  border-left: 1px solid #ededed !important;
}

.border-top-dash {
  border-top: 1px dashed #ddd !important;
}

.border-bottom-dash {
  border-bottom: 1px dashed #ddd !important;
}

.border-top-thick {
  border-top: 2px solid #ededed !important;
}

.border-bottom-thick {
  border-bottom: 2px solid #ededed !important;
}

.border-top-drak {
  border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.border-bottom-drak {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.bg-img {
  background: #888;
}

.section-title p {
  font-size: 18px;
  max-width: 630px;
  margin: auto;
  color: #3E3E3E;
}

.section-title-left p {
  font-size: 20px;
}

.section-title--three .heading-title {
  position: relative;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.section-title--three .heading-title::after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  height: 4px;
  width: 90px;
  background: #75ad1c;
}

.section-title--three .heading-title.center::after {
  left: 50%;
  transform: translateX(-50%);
}

.section-title--three__defult .heading-title {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.section-title--three__defult .heading-title::after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  height: 4px;
  width: 90px;
  background: #4241ff;
}

.section-title--three__defult .heading-title.center::after {
  left: 50%;
  transform: translateX(-50%);
}

.section-title--three p {
  font-size: 20px;
  margin: auto;
  color: #3E3E3E;
}

.section-title--four {
  position: relative;
}

.section-title--four .heading-title {
  position: relative;
  display: inline-block;
  padding: 0 80px;
  text-transform: uppercase;
  font-size: 46px;
}

.section-title--four .heading-title::after {
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  height: 8px;
  width: 40px;
  background: #ea622b;
  transform: translateY(-50%);
}

.section-title--four .heading-title::before {
  position: absolute;
  content: '';
  right: 0;
  top: 50%;
  height: 8px;
  width: 40px;
  background: #ea622b;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .section-title--four .heading-title {
    padding: 0 50px;
    font-size: 24px;
  }
  .section-title--four .heading-title::after {
    width: 30px;
  }
  .section-title--four .heading-title::before {
    width: 30px;
  }
}

.section-title--four .heading-title-bottom {
  position: relative;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.section-title--four .heading-title-bottom::after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  height: 6px;
  width: 90px;
  background: #ea622b;
}

.section-title--four .heading-title-center {
  position: relative;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.section-title--four .heading-title-center::after {
  position: absolute;
  content: '';
  left: 50%;
  bottom: 0;
  height: 6px;
  width: 90px;
  background: #ea622b;
  transform: translateX(-50%);
}

.section-title--four p {
  font-size: 20px;
  margin: auto;
  color: #3E3E3E;
}

.title-two {
  font-size: 56px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .title-two {
    font-size: 46px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .title-two {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .title-two {
    font-size: 34px;
  }
}

.title-three {
  font-size: 46px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .title-three {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .title-three {
    font-size: 34px;
  }
}

.lg-font-type p {
  font-size: 18px;
}

.sm-font-p {
  font-size: 14px;
}

.xs-font-p {
  font-size: 16px;
}

.md-font-p {
  font-size: 18px;
}

.lg-font-p {
  font-size: 22px;
}

@media only screen and (max-width: 767px) {
  .lg-font-p {
    font-size: 18px;
  }
}

.img-width {
  width: 100%;
}

.title-dec-text {
  text-align: center;
  max-width: 760px;
  margin: auto;
}

::selection {
  color: #ffffff;
  background-color: #4241ff;
}

form {
  /* FF 4-18 */
  /* FF 19+ */
  /* IE 10+ */
}

form input:focus::-webkit-input-placeholder {
  color: transparent;
}

form input:focus:-moz-placeholder {
  color: transparent;
}

form input:focus::-moz-placeholder {
  color: transparent;
}

form input:focus:-ms-input-placeholder {
  color: transparent;
}

form input::placeholder, form textarea::placeholder {
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

input[type="text"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="number"]:focus, input[type="tel"]:focus, input[type="range"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="week"]:focus, input[type="time"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="color"]:focus, textarea:focus, select:focus, select:focus, textarea:focus {
  color: #4241ff;
  border-color: #4241ff;
  background: transparent;
}

input[type="checkbox"] {
  position: relative;
  background: 0 0;
  border-width: 0;
  box-shadow: none;
  margin: 0 10px 0 3px;
  cursor: pointer;
}

.single-element-wrap ul li {
  margin-bottom: 15px;
}

.single-element-wrap ul li:last-child {
  margin-bottom: 0;
}

/*===================================
    - Breadvrumb  
=================================*/
.breadcrumb-area {
  padding-top: 160px;
  padding-bottom: 131px;
  background-color: #ddd;
  background-position: bottom center !important;
  background-repeat: no-repeat !important;
  background-size: cover  !important;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-area {
    padding-top: 140px;
    padding-bottom: 80px;
  }
}

.breadcrumb-area .breadcrumb-title {
  font-size: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-area .breadcrumb-title {
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-area .breadcrumb-title {
    font-size: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-area .breadcrumb-title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .breadcrumb-area .breadcrumb-title {
    font-size: 26px;
  }
}

.breadcrumb-element {
  padding-top: 50px;
  padding-bottom: 50px;
  background-position: bottom center !important;
  background-repeat: no-repeat !important;
  background-size: cover  !important;
}

.breadcrumb-element .breadcrumb-title {
  font-size: 34px;
}

@media only screen and (max-width: 575px) {
  .breadcrumb-element .breadcrumb-title {
    font-size: 26px;
  }
}

.breadcrumb-element {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #ddd;
  background-position: bottom center !important;
  background-repeat: no-repeat !important;
  background-size: cover  !important;
}

.breadcrumb-element .breadcrumb-title {
  font-size: 34px;
}

@media only screen and (max-width: 575px) {
  .breadcrumb-element .breadcrumb-title {
    font-size: 26px;
  }
}

.breadcrumb-interior {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #ddd;
  background-position: bottom center !important;
  background-repeat: no-repeat !important;
  background-size: cover  !important;
}

.breadcrumb-interior .breadcrumb-title {
  font-size: 46px;
}

@media only screen and (max-width: 575px) {
  .breadcrumb-interior .breadcrumb-title {
    font-size: 26px;
  }
}

.breadcrumb-list {
  margin-top: 20px;
}

.breadcrumb-list li {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.58;
  text-transform: uppercase;
  color: #ffffff;
}

.breadcrumb-list li a {
  position: relative;
  color: #ffffff;
}

.breadcrumb-list li a::after {
  content: '';
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  color: #ffffff;
  z-index: -1;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: currentColor;
}

.breadcrumb-list li a:hover::after {
  width: 100%;
  left: 0;
  right: auto;
  z-index: 0;
}

.breadcrumb-list li.active {
  color: #fff;
}

.breadcrumb-list__black {
  margin-top: 20px;
}

.breadcrumb-list__black li {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.58;
  text-transform: uppercase;
  color: #222;
}

.breadcrumb-list__black li a {
  position: relative;
  color: #222;
}

.breadcrumb-list__black li a::after {
  content: '';
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  color: #222;
  z-index: -1;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: currentColor;
}

.breadcrumb-list__black li a:hover::after {
  width: 100%;
  left: 0;
  right: auto;
  z-index: 0;
}

.breadcrumb-list__black li.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: .5rem;
  color: #222;
  content: ">";
}

.breadcrumb-list__black li.active {
  color: #222;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: .5rem;
  color: #fff;
  content: ">";
}

.page-pagination li {
  display: inline-block;
}

.page-pagination li a {
  font-weight: 500;
  padding: 0 10px;
  display: block;
  text-align: center;
  line-height: 50px;
  min-width: 50px;
  height: 50px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 0px;
  border: 1px solid #ddd;
}

.page-pagination li a.current, .page-pagination li a:hover {
  background: #000000;
  color: #fff;
}

/*=============================================
   - Scroll top         
=============================================*/
.scroll-top {
  position: fixed;
  right: 30px;
  bottom: -60px;
  z-index: 999;
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.03);
  display: block;
  padding: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  font-size: 25px;
  line-height: 60px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  background-color: #4241ff;
  background-size: 200% auto;
  background-position: left center;
  color: #ffffff;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow: hidden;
}

@media only screen and (max-width: 479px) {
  .scroll-top {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
}

.scroll-top.show {
  visibility: visible;
  opacity: 1;
  bottom: 60px;
}

.scroll-top i {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #ffffff;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.scroll-top .arrow-top {
  transform: translate(-50%, -50%);
}

.scroll-top .arrow-bottom {
  transform: translate(-50%, 80px);
}

.scroll-top:hover {
  background-position: right center;
}

.scroll-top:hover .arrow-top {
  transform: translate(-50%, -80px);
}

.scroll-top:hover .arrow-bottom {
  transform: translate(-50%, -50%);
}

.error-content {
  background: url(../images/others/404-bg.png) no-repeat center center;
  background-size: contain;
  padding: 120px 0;
}

.error-content h1 {
  font-size: 320px;
  font-weight: 600;
  color: #4241ff;
  line-height: 0.8;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .error-content h1 {
    font-size: 260px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-content {
    padding: 80px 0;
  }
  .error-content h1 {
    font-size: 160px;
  }
}

@media only screen and (max-width: 767px) {
  .error-content {
    padding: 60px 0;
  }
  .error-content h1 {
    font-size: 120px;
  }
}

.sparator-border-bottom {
  border-bottom: 1px solid #777;
  padding-bottom: 80px;
}

/*=====  End of Default CSS  ======*/
/*============================================
=           02. Section Spacing Css          =
=============================================*/
.section-space {
  /* Section Padding Css */
  /* Section Margin Css */
}

.section-space--ptb_120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_120 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_120 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_120 {
  padding-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_120 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_120 {
    padding-top: 60px;
  }
}

.section-space--pb_120 {
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_120 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_120 {
    padding-bottom: 60px;
  }
}

.section-space--ptb_100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_100 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_100 {
  padding-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_100 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_100 {
    padding-top: 60px;
  }
}

.section-space--pb_100 {
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_100 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_100 {
    padding-bottom: 60px;
  }
}

.section-space--ptb_90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_90 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_90 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_90 {
  padding-top: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_90 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_90 {
    padding-top: 60px;
  }
}

.section-space--pb_90 {
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_90 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_90 {
    padding-bottom: 60px;
  }
}

.section-space--ptb_80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_80 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_80 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.section-space--pt_80 {
  padding-top: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_80 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_80 {
    padding-top: 40px;
  }
}

.section-space--pb_80 {
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_80 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_80 {
    padding-bottom: 40px;
  }
}

.section-space--ptb_70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_70 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_70 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.section-space--pt_70 {
  padding-top: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_70 {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_70 {
    padding-top: 30px;
  }
}

.section-space--pb_70 {
  padding-bottom: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_70 {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_70 {
    padding-bottom: 30px;
  }
}

.section-space--ptb_60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_60 {
  padding-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_60 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_60 {
    padding-top: 60px;
  }
}

.section-space--pb_60 {
  padding-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_60 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_60 {
    padding-bottom: 60px;
  }
}

.section-space--pt_40 {
  padding-top: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_40 {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_40 {
    padding-top: 30px;
  }
}

.section-space--pb_40 {
  padding-bottom: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_40 {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_40 {
    padding-bottom: 30px;
  }
}

.section-space--ptb_30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.section-space--pt_30 {
  padding-top: 30px;
}

.section-space--pb_30 {
  padding-bottom: 30px;
}

.section-space--mt_15 {
  margin-top: 15px;
}

.section-space--mt_20 {
  margin-top: 20px;
}

.section-space--mt_30 {
  margin-top: 30px;
}

.section-space--mt_40 {
  margin-top: 40px;
}

.section-space--mt_50 {
  margin-top: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_50 {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_50 {
    margin-top: 30px;
  }
}

.section-space--mt_60 {
  margin-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_60 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_60 {
    margin-top: 30px;
  }
}

.section-space--mt_70 {
  margin-top: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_70 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_70 {
    margin-top: 30px;
  }
}

.section-space--mt_80 {
  margin-top: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_80 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_80 {
    margin-top: 30px;
  }
}

.section-space--mt_100 {
  margin-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_100 {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_100 {
    margin-top: 60px;
  }
}

.section-space--mt_120 {
  margin-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_120 {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_120 {
    margin-top: 60px;
  }
}

.section-space--mb_15 {
  margin-bottom: 15px;
}

.section-space--mb_20 {
  margin-bottom: 20px;
}

.section-space--mb_30 {
  margin-bottom: 30px;
}

.section-space--mb_40 {
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .section-space--mb_40 {
    margin-bottom: 30px;
  }
}

.section-space--mb_50 {
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_50 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_50 {
    margin-bottom: 30px;
  }
}

.section-space--mb_60 {
  margin-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_60 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_60 {
    margin-bottom: 30px;
  }
}

.section-space--mb_70 {
  margin-bottom: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_70 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_70 {
    margin-bottom: 30px;
  }
}

.section-space--mb_80 {
  margin-bottom: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_80 {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_80 {
    margin-bottom: 40px;
  }
}

.section-space--mb_100 {
  margin-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_100 {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_100 {
    margin-bottom: 60px;
  }
}

.section-space--mb_120 {
  margin-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_120 {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_120 {
    margin-bottom: 60px;
  }
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

@media only screen and (max-width: 767px) {
  .small-mt__0 {
    margin-top: 0px;
  }
  .small-mt__10 {
    margin-top: 10px;
  }
  .small-mt__20 {
    margin-top: 20px;
  }
  .small-mt__30 {
    margin-top: 30px;
  }
  .small-mt__40 {
    margin-top: 40px;
  }
  .small-mt__50 {
    margin-top: 50px;
  }
  .small-mt__60 {
    margin-top: 60px;
  }
  .small-mb__30 {
    margin-bottom: 30px;
  }
  .small-mb__40 {
    margin-bottom: 40px;
  }
  .small-mb__50 {
    margin-bottom: 50px;
  }
  .small-mb__60 {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tablet-mt__0 {
    margin-top: 0px;
  }
  .tablet-mt__30 {
    margin-top: 30px;
  }
  .tablet-mt__40 {
    margin-top: 40px;
  }
  .tablet-mt__50 {
    margin-top: 50px;
  }
  .tablet-mt__60 {
    margin-top: 60px;
  }
  .tablet-mb__30 {
    margin-bottom: 30px;
  }
  .tablet-mb__40 {
    margin-bottom: 40px;
  }
  .tablet-mb__50 {
    margin-bottom: 50px;
  }
  .tablet-mb__60 {
    margin-bottom: 60px;
  }
}

.padding-content {
  padding: 60px;
}

@media only screen and (max-width: 767px) {
  .padding-content {
    padding: 40px 30px;
  }
}

.section-padding-60 {
  padding: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-60 {
    padding: 40px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-60 {
    padding: 40px 30px;
  }
}

.mt_dicrest-30 {
  margin-top: -30px;
}

.bg-images-section-htb {
  min-height: 400px;
}

/*=====  End of spacing  ======*/
/*============================================
=           03. Preloader Css          =
=============================================*/
.la-loader.spinner1, .la-loader.spinner2, .la-loader.spinner3 [class*="bounce"], .la-loader.spinner4 [class*="dot"], .la-loader.spinner5 div {
  background-color: #4241ff;
}

.la-image-loading.loaded {
  opacity: 0;
  visibility: hidden;
}

.la-image-loading {
  opacity: 1;
  position: fixed;
  z-index: 999999;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  overflow: hidden;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  visibility: visible;
}

.la-image-loading .content {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
}

.la-loader.spinner1 {
  width: 40px;
  height: 40px;
  margin: 5px;
  display: block;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  -webkit-animation: la-rotateplane 1.2s infinite ease-in-out;
  animation: la-rotateplane 1.2s infinite ease-in-out;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.la-image-loading .content::after {
  content: 'zephys';
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 15px;
  text-transform: uppercase;
  white-space: pre;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
}

@-webkit-keyframes la-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes la-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

@-webkit-keyframes la-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes la-scaleout {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes la-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes la-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@-webkit-keyframes la-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes la-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes la-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes la-bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

@-webkit-keyframes la-cubeGridScaleDelay {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3d(0, 0, 1);
  }
  70% {
    -webkit-transform: scale3d(1, 1, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
  }
}

@keyframes la-cubeGridScaleDelay {
  0% {
    transform: scale3d(1, 1, 1);
  }
  35% {
    transform: scale3d(0, 0, 1);
  }
  70% {
    transform: scale3d(1, 1, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

/*=============================================
=           07. Popup Search Css             =
==============================================*/
.offcanvas-search_wrapper .offcanvas-menu-inner {
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100vh;
  right: 0;
  transform-origin: center;
  visibility: visible;
  opacity: 0;
  top: 0;
  position: absolute;
  z-index: -99;
}

.offcanvas-search_wrapper .offcanvas-menu-inner .btn-close {
  background: transparent;
  color: #ffffff;
  top: 65px;
  right: 30px;
  left: auto;
  font-size: 40px;
  color: #ffffff;
  position: absolute;
  z-index: 44;
}

@media only screen and (max-width: 767px) {
  .offcanvas-search_wrapper .offcanvas-menu-inner .btn-close {
    top: 30px;
  }
}

.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  transform: scale(0.5);
}

.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search > span.searchbox-info {
  color: #ffffff;
  font-size: 20px;
}

.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox {
  position: relative;
  transform-origin: top bottom;
  width: calc(100% - 30px);
}

.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox > input {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #ffffff;
  height: 100px;
  line-height: 100px;
  width: 100%;
  padding: 0 50px 0 0;
  color: #ffffff;
  transform-origin: top bottom;
  text-align: center;
  font-size: 30px;
}

.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox > input::placeholder {
  color: #ffffff;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox > input {
    height: 60px;
    font-size: 20px;
  }
}

.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox > .search_btn {
  position: absolute;
  font-size: 30px;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  border: 0;
  background: transparent;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox > .search_btn {
    font-size: 20px;
  }
}

.offcanvas-search_wrapper.open .offcanvas-menu-inner {
  left: auto;
  right: 0;
  padding: 0;
  transform: scale(1);
  visibility: visible;
  opacity: 1;
  z-index: 999;
  position: fixed;
  width: 100%;
}

.offcanvas-search_wrapper.open .offcanvas-menu-inner .offcanvas-search {
  transform: scale(1);
}

/*========================================
=	     05. Offcanvas Menu Wrapper        =
==========================================*/
.offcanvas-menu_wrapper .offcanvas-menu-inner {
  padding: 60px 60px 0;
  position: fixed;
  top: 0;
  right: -285px;
  width: 470px;
  height: 100%;
  background: #212121;
  z-index: 9999;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  text-align: left;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .offcanvas-menu_wrapper .offcanvas-menu-inner {
    width: 300px;
    padding: 60px 20px 0;
  }
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .btn-close {
  top: 45px;
  left: 25px;
  width: auto;
  line-height: 1;
  height: auto;
  color: #ffffff;
  font-size: 30px;
  display: block;
  background-color: transparent;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .btn-close:hover {
  background: transparent;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_nav {
  margin-top: 40px;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_nav ul li {
  color: #ffffff;
  font-size: 20px;
  margin-bottom: 10px;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_nav ul li:last-child {
  margin-bottom: 0px;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_nav ul li .menu-50 {
  overflow: hidden;
  max-width: 340px;
  margin: auto;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_nav ul li .menu-50 li {
  width: 50%;
  float: left;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_nav ul li .menu-50 li a {
    font-size: 14px;
  }
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_banner {
  padding-top: 40px;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_banner .inner-img {
  position: relative;
  cursor: pointer;
  width: 100%;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_banner .inner-img a {
  display: block;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_banner .inner-img img {
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_banner .inner-img:before {
  background-color: #000000;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_banner .inner-img:hover:before {
  width: calc(100% - 40px);
  height: calc(100% - 30px);
  top: 15px;
  left: 20px;
  opacity: 0.3;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_banner .inner-img:hover .banner-add-content {
  transform: translateY(-10px);
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_banner .inner-img .banner-add-content {
  position: absolute;
  bottom: 0;
  padding: 30px;
  transition: all 0.4s ease-in-out;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_banner .inner-img .banner-add-content h5 {
  color: #ffffff;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_banner .inner-img .banner-add-content p {
  color: #ffffff;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_info {
  padding-top: 35px;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_info > span {
  display: block;
  font-size: 14px;
  color: #ffffff;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_info > span:not(:last-child) {
  padding-bottom: 10px;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_info .social-link li {
  display: inline-block;
  color: #ffffff;
  margin-right: 20px;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_info .copyright {
  padding: 20px 0 25px;
  color: #ffffff;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_info .copyright span a {
  font-size: 14px;
}

.offcanvas-menu_wrapper.open .offcanvas-menu-inner {
  left: auto;
  right: 0;
  opacity: 1;
  visibility: visible;
}

.offcanvas-menu_wrapper.fullscreen-menu .offcanvas-menu-inner {
  background-color: rgba(0, 0, 0, 0.94);
  width: 100%;
  padding: 100px 0;
}

@media only screen and (max-width: 767px) {
  .offcanvas-menu_wrapper.fullscreen-menu .offcanvas-menu-inner {
    padding: 40px 0;
  }
}

.offcanvas-menu_wrapper.fullscreen-menu .offcanvas-menu-inner .btn-close {
  color: #ffffff;
  top: 0;
  left: 0;
  right: auto;
  font-size: 40px;
  text-align: right;
  padding: 0 100px;
}

.offcanvas-menu_wrapper.fullscreen-menu .offcanvas-menu-inner .btn-close:hover {
  color: #4241ff;
}

@media only screen and (max-width: 767px) {
  .offcanvas-menu_wrapper.fullscreen-menu .offcanvas-menu-inner .btn-close {
    padding: 0 20px;
  }
}

.offcanvas-menu_wrapper.fullscreen-menu .offcanvas-menu-inner .offcanvas-inner_nav {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 0;
}

.offcanvas-menu_wrapper.fullscreen-menu .offcanvas-menu-inner .offcanvas-inner_nav > ul {
  max-height: 520px;
  overflow-y: none;
  width: 100%;
  margin-bottom: 50px;
}

.offcanvas-menu_wrapper.fullscreen-menu .offcanvas-menu-inner .offcanvas-inner_nav > ul > li.active > a {
  color: #ffffff;
}

.offcanvas-menu_wrapper.fullscreen-menu .offcanvas-menu-inner .offcanvas-inner_nav > ul > li:hover > a {
  color: #ffffff;
}

.offcanvas-menu_wrapper.fullscreen-menu .offcanvas-menu-inner .offcanvas-inner_nav > ul > li.has-sub > ul {
  display: none;
}

.offcanvas-menu_wrapper.fullscreen-menu .offcanvas-menu-inner .offcanvas-inner_nav > ul > li.has-sub > ul > li.inner-sub > a {
  width: auto;
}

.offcanvas-menu_wrapper.fullscreen-menu .offcanvas-menu-inner .offcanvas-inner_nav > ul > li.has-sub > ul > li.inner-sub > a > i {
  position: relative;
  right: -5px;
  vertical-align: middle;
  font-size: 12px;
}

.offcanvas-menu_wrapper.fullscreen-menu .offcanvas-menu-inner .offcanvas-inner_nav > ul > li > ul > li:hover > a {
  color: #ffffff;
}

.offcanvas-menu_wrapper.fullscreen-menu .offcanvas-menu-inner .offcanvas-inner_nav > ul > li > ul > li a {
  color: #919191;
  font-size: 20px;
  font-family: "Alegreya", serif;
}

.offcanvas-menu_wrapper.fullscreen-menu .offcanvas-menu-inner .offcanvas-inner_nav > ul > li > ul > li a:hover {
  color: #ffffff;
}

.offcanvas-menu_wrapper.fullscreen-menu .offcanvas-menu-inner .offcanvas-inner_nav > ul > li > a {
  color: #919191;
  font-size: 46px;
  font-family: "Alegreya", serif;
  font-weight: 700;
}

.offcanvas-menu_wrapper.fullscreen-menu .offcanvas-menu-inner .offcanvas-inner_nav > ul > li > a > i {
  position: relative;
  vertical-align: middle;
  left: 10px;
  right: auto;
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  .offcanvas-menu_wrapper.fullscreen-menu .offcanvas-menu-inner .offcanvas-inner_nav > ul > li > a {
    font-size: 24px;
  }
}

.offcanvas-menu_wrapper.fullscreen-menu.open .offcanvas-menu-inner {
  opacity: 1;
}

/*======================================
=        06. Offcanvas Minicart Area
=======================================*/
.offcanvas-minicart_wrapper .offcanvas-menu-inner {
  padding: 60px !important;
  width: 450px;
  box-shadow: 0px 0 15px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .offcanvas-minicart_wrapper .offcanvas-menu-inner {
    width: 100%;
    padding: 60px 15px !important;
  }
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .close-btn-box {
  text-align: right;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .btn-close {
  background: transparent;
  color: #333;
  top: 0;
  right: 0;
  left: auto;
  text-align: right;
  font-size: 30px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .btn-close:hover {
  color: #212121;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-heading > h4 {
  margin-bottom: 0;
  padding-bottom: 25px;
  font-size: 18px;
  line-height: 21.6px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list {
  max-height: 311px;
  position: relative;
  overflow: auto;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li {
  padding-bottom: 30px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li:last-child {
  padding-bottom: 0;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product {
  display: flex;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product > a.product-item_remove > i {
  position: absolute;
  font-size: 14px;
  top: auto;
  right: 15px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_img {
  border: 1px solid #ddd;
  flex-basis: 70px;
  max-width: 70px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_content {
  flex-basis: calc(100% - 70px);
  max-width: calc(100% - 70px);
  padding-left: 20px;
  padding-right: 10px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_content a.product-item_title {
  color: #333;
  line-height: 1.4;
  font-size: 14px;
  width: 90%;
  display: block;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_content a.product-item_title:hover {
  color: #333;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_content .product-item_quantity {
  display: block;
  padding-top: 10px;
  font-size: 13px;
  line-height: 24.7px;
  color: #333;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-item_total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-btn_area {
  padding-bottom: 15px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-btn_area .hiraola-btn {
  height: 60px;
  line-height: 60px;
  color: #ffffff !important;
}

/* ---Offcanvas--- */
.offcanvas-minicart_wrapper .offcanvas-menu-inner {
  background-color: #fff;
  position: fixed;
  top: 0;
  right: -440px;
  width: 440px;
  height: 100%;
  z-index: 9999;
  visibility: hidden;
  overflow-y: auto;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .offcanvas-inner_logo {
  padding: 30px 0;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .offcanvas-minicart_wrapper .offcanvas-menu-inner {
    width: 300px;
    padding: 60px 20px 0 !important;
  }
}

.offcanvas-minicart_wrapper.open .offcanvas-menu-inner {
  visibility: visible;
  left: auto;
  right: 0;
  opacity: 1;
  z-index: 999;
}

/*========================================
=	     08. Theme Color Css        =
==========================================*/
.theme-color--five .la-loader.spinner1, .theme-color--five .la-loader.spinner2, .theme-color--five .la-loader.spinner3 [class*="bounce"], .theme-color--five .la-loader.spinner4 [class*="dot"], .theme-color--five .la-loader.spinner5 div {
  background-color: #75ad1c;
}

.theme-color--five .submenu > li.active > a {
  color: #75ad1c;
}

.theme-color--five a:hover {
  text-decoration: none;
  color: #75ad1c;
}

.theme-color--five .navigation-menu > ul > li:hover > a span, .theme-color--five .navigation-menu > ul > li.active > a span {
  color: #75ad1c;
}

.theme-color--five .navigation-menu > ul > li:hover > a:after, .theme-color--five .navigation-menu > ul > li.active > a:after {
  color: #75ad1c;
}

.theme-color--five .navigation-menu > ul > li > a:before {
  background-color: #75ad1c;
}

.theme-color--five .submenu > li > a > span:after {
  background-color: #75ad1c;
}

.theme-color--five .submenu > li > a:hover {
  color: #75ad1c;
}

.theme-color--five .btn-lg-border:hover {
  background: #75ad1c;
  border: 2px solid #75ad1c;
}

.theme-color--five .ht-tab-menu-one button.is-checked {
  color: #75ad1c;
}

.theme-color--five .single-blog-item .post-info .post-categories {
  color: #75ad1c;
}

.theme-color--five .btn--border:hover {
  background: #75ad1c;
  color: #fff;
  border: 1px solid #75ad1c;
}

.theme-color--five .blog-posttestimonial-inner-box {
  background: #75ad1c;
}

.theme-color--five .sidebar-widget-title {
  margin-bottom: 30px;
}

.theme-color--five .sidebar-widget-title::after {
  background: #75ad1c;
}

.theme-color--five .widget-tag .ht-btn:hover {
  border: 1px solid #75ad1c;
  color: #fff;
  background: #75ad1c;
}

.theme-color--five .video-btn--small {
  color: #75ad1c;
}

.theme-color--five .widget-search .search-submit:hover {
  color: #75ad1c;
}

.theme-color--five .menu-primary-menu-1 li.has-dropdown > a.is-visiable {
  color: #75ad1c;
}

.theme-color--five .btn--black:hover {
  background: #75ad1c !important;
}

.theme-color--four .la-loader.spinner1, .theme-color--four .la-loader.spinner2, .theme-color--four .la-loader.spinner3 [class*="bounce"], .theme-color--four .la-loader.spinner4 [class*="dot"], .theme-color--four .la-loader.spinner5 div {
  background-color: #ea622b;
}

.theme-color--four .submenu > li.active > a {
  color: #ea622b;
}

.theme-color--four a:hover {
  text-decoration: none;
  color: #ea622b;
}

.theme-color--four input[type="text"]:focus, .theme-color--four input[type="email"]:focus, .theme-color--four input[type="url"]:focus, .theme-color--four input[type="password"]:focus, .theme-color--four input[type="search"]:focus, .theme-color--four input[type="number"]:focus, .theme-color--four input[type="tel"]:focus, .theme-color--four input[type="range"]:focus, .theme-color--four input[type="date"]:focus, .theme-color--four input[type="month"]:focus, .theme-color--four input[type="week"]:focus, .theme-color--four input[type="time"]:focus, .theme-color--four input[type="datetime"]:focus, .theme-color--four input[type="datetime-local"]:focus, .theme-color--four input[type="color"]:focus, .theme-color--four textarea:focus, .theme-color--four select:focus, .theme-color--four select:focus, .theme-color--four textarea:focus {
  color: #ea622b;
  border-color: #ea622b;
  background: transparent;
}

.theme-color--four .navigation-menu > ul > li:hover > a span, .theme-color--four .navigation-menu > ul > li.active > a span {
  color: #ea622b;
}

.theme-color--four .navigation-menu > ul > li:hover > a:after, .theme-color--four .navigation-menu > ul > li.active > a:after {
  color: #ea622b;
}

.theme-color--four .navigation-menu > ul > li > a:before {
  background-color: #ea622b;
}

.theme-color--four .submenu > li > a > span:after {
  background-color: #ea622b;
}

.theme-color--four .submenu > li > a:hover {
  color: #ea622b;
}

.theme-color--four .btn-lg-border:hover {
  background: #ea622b;
  border: 2px solid #ea622b;
}

.theme-color--four .ht-tab-menu-one button.is-checked {
  color: #ea622b;
}

.theme-color--four .single-blog-item .post-info .post-categories {
  color: #ea622b;
}

.theme-color--four .btn--border:hover {
  background: #ea622b;
  color: #fff;
  border: 1px solid #ea622b;
}

.theme-color--four .blog-posttestimonial-inner-box {
  background: #ea622b;
}

.theme-color--four .sidebar-widget-title {
  margin-bottom: 30px;
}

.theme-color--four .sidebar-widget-title::after {
  background: #ea622b;
}

.theme-color--four .widget-tag .ht-btn:hover {
  border: 1px solid #ea622b;
  color: #fff;
  background: #ea622b;
}

.theme-color--four .video-btn--small {
  color: #ea622b;
}

.theme-color--four .widget-search .search-submit:hover {
  color: #ea622b;
}

.theme-color--four .menu-primary-menu-1 li.has-dropdown > a.is-visiable {
  color: #ea622b;
}

.theme-color--four .btn--black:hover {
  background: #ea622b !important;
}

.font-family-two body {
  font-size: 20px;
}

.font-family-two h1, .font-family-two h2, .font-family-two h3, .font-family-two h4, .font-family-two h5, .font-family-two h6 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.41;
}

.font-family-two h1 {
  font-size: 110px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .font-family-two h1 {
    font-size: 46px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .font-family-two h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .font-family-two h1 {
    font-size: 34px;
  }
}

.font-family-two h2 {
  font-size: 66px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .font-family-two h2 {
    font-size: 36px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .font-family-two h2 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .font-family-two h2 {
    font-size: 30px;
  }
}

.font-family-two h2.title-three {
  font-size: 46px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .font-family-two h2.title-three {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .font-family-two h2.title-three {
    font-size: 34px;
  }
}

.font-family-two h3 {
  font-size: 36px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .font-family-two h3 {
    font-size: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .font-family-two h3 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .font-family-two h3 {
    font-size: 25px;
  }
}

.font-family-two h4 {
  font-size: 32px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .font-family-two h4 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .font-family-two h4 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .font-family-two h4 {
    font-size: 24px;
  }
}

.font-family-two h5 {
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  .font-family-two h5 {
    font-size: 20px;
  }
}

.font-family-two h6 {
  font-size: 18px;
}

.font-family-two p {
  font-size: 20px;
}

@media only screen and (max-width: 767px) {
  .font-family-two p {
    font-size: 16px;
  }
}

/*=======================================       
=         09. Humburger Area Css        =
========================================*/
.hamburger-area {
  text-align: left;
  transition: all .52s ease;
  position: fixed;
  height: 100% !important;
  top: 0;
  margin: 0;
  z-index: 99;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  width: 470px;
  padding: 70px;
  color: #fff;
  background-color: #2f2f2f;
  right: 0;
  transform: translateX(470px);
  -webkit-transform: translateX(470px);
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .hamburger-area {
    left: -280px;
    width: 280px;
    transform: inherit;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 0;
    padding: 0;
    padding-top: 70px;
  }
}

.hamburger-area.is-visible {
  transform: translateX(0);
  -webkit-transform: translateX(0);
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .hamburger-area.is-visible {
    left: 0;
    transform: inherit;
    opacity: 1;
    visibility: visible;
  }
}

/* Hambeger Area */
.hamburger-area .btn-close-search {
  position: absolute;
  left: 30px;
  font-size: 30px;
  line-height: 30px;
  height: 30px;
  top: 50px;
  transition: all .25s ease-out;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .hamburger-area .btn-close-search {
    top: 35px;
    right: 24px;
    left: auto;
    font-size: 20px;
  }
}

.hamburger-area .btn-close-search button {
  padding: 0;
  border: 0 none;
  color: #fff;
  background: transparent;
}

.hamburger-area .btn-close-search button:hover {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  color: #f53e6a;
}

.hamburger-area .hamburger-menu-main {
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
}

.hamburger-area .hamburger-menu-main .humberger-top {
  flex-grow: 2;
  -webkit-flex-grow: 2;
}

.hamburger-area .hamburger-menu-main .humberger-top .hum-mainmenu ul {
  margin-top: 20px;
  margin-bottom: 10px;
}

.hamburger-area .hamburger-menu-main .humberger-top .hum-mainmenu ul li a {
  font-weight: 400;
  text-transform: capitalize;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
  padding: 10px 0;
  position: relative;
  display: block;
  color: #fff;
}

.hamburger-area .hamburger-menu-main .humberger-bottom {
  margin: 50px 0 0;
}

.hamburger-area .hamburger-menu-main .humberger-bottom p {
  color: #fff;
  font-size: 14px;
  margin: 20px 0 30px;
}

.hamburger-area .hamburger-menu-main .humberger-bottom .hm-address li {
  color: #fff;
}

.hamburger-area .hamburger-menu-main .humberger-bottom .hm-address li a {
  color: #fff;
}

.hamburger-area .hamburger-menu-main .humberger-bottom .hm-address li:not(:last-child) {
  padding-bottom: calc(15px/2);
}

.hamburger-area .hamburger-menu-main .humberger-bottom .hm-address li:not(:first-child) {
  margin-top: calc(15px/2);
}

.hamburger-area .hamburger-menu-main .humberger-bottom .social-icons {
  margin: 0 -4px;
  margin-top: 20px;
}

.hamburger-area .hamburger-menu-main .humberger-bottom .social-icons a {
  margin: 0 4px;
}

.hamburger-area .hamburger-menu-main .humberger-bottom .hm-copyright {
  margin: 30px 0 0;
}

.hamburger-area .hamburger-menu-main .humberger-bottom .hm-copyright p {
  font-size: 12px;
  line-height: 1.4074;
  color: #fff;
}

/* ================================
    Responsive Menu 
==================================*/
.menu-primary-menu-1 li a {
  color: silver;
  text-transform: capitalize;
}

.menu-primary-menu-1 li.has-dropdown > a {
  position: relative;
}

.menu-primary-menu-1 li.has-dropdown > a::after {
  position: absolute;
  content: "\f107";
  font-family: FontAwesome;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  width: 50px;
}

.menu-primary-menu-1 li.has-dropdown > a.is-visiable {
  color: #4241ff;
}

.menu-primary-menu-1 li.has-dropdown > a.is-visiable::after {
  content: "\f106";
}

.menu-primary-menu-1 > li > a {
  display: block;
  padding: 14px 20px;
  font-size: 16px;
}

.menu-primary-menu-1 > li .sub-menu {
  display: none;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 5px 0;
  margin: 5px 0;
}

.menu-primary-menu-1 > li .sub-menu > li {
  width: 100%;
}

.menu-primary-menu-1 > li .sub-menu > li > a {
  padding: 10px 30px;
  font-size: 1em;
  display: block;
}

.menu-primary-menu-1 > li .sub-menu > li.has-label--3 .lavel--3 {
  display: none;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 5px 0;
  margin: 5px 0;
}

.menu-primary-menu-1 > li .sub-menu > li.has-label--3 .lavel--3 li {
  width: 100%;
}

.menu-primary-menu-1 > li .sub-menu > li.has-label--3 .lavel--3 li a {
  padding: 10px 30px;
  font-size: 1em;
  padding-right: 40px;
  padding-left: 40px;
  display: block;
}

.menu-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #333333;
  overflow: auto;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  opacity: 0;
}

.menu-overlay.active {
  visibility: visible;
  opacity: 1;
}

/*=============================================
=            10. container Css            =
=============================================*/
@media (min-width: 1600px) {
  .container-fluid--cp-150 {
    padding: 0 150px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1664px) {
  .container-fluid--cp-150 {
    padding: 0 120px !important;
  }
}

@media (min-width: 1200px) {
  .container-fluid--cp-150 {
    padding: 0 50px;
  }
}

@media (min-width: 1700px) {
  .container-fluid--cp-140 {
    padding: 0 140px !important;
  }
}

@media (min-width: 1700px) and (max-width: 1663px) {
  .container-fluid--cp-140 {
    padding: 0 100px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1664px) {
  .container-fluid--cp-140 {
    padding: 0 30px !important;
  }
}

@media (min-width: 1200px) {
  .container-fluid--cp-140 {
    padding: 0 30px;
  }
}

@media (min-width: 1600px) {
  .container-fluid--cp-120 {
    padding: 0 120px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1664px) {
  .container-fluid--cp-120 {
    padding: 0 110px !important;
  }
}

@media (min-width: 1200px) {
  .container-fluid--cp-120 {
    padding: 0 50px;
  }
}

.container-fluid--cp-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

@media (min-width: 1600px) {
  .container-fluid--cp-80 {
    padding-left: 50px !important;
    padding-right: 50px;
  }
}

@media (min-width: 1200px) {
  .container-fluid--cp-80 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .container-fluid--cp-80 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container-fluid--cp-80 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

@media only screen and (max-width: 767px) {
  .container-fluid--cp-80 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

@media only screen and (max-width: 575px) {
  .container-fluid--cp-80 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.container-fluid--cp-60 {
  padding: 0 60px;
}

@media only screen and (max-width: 767px) {
  .container-fluid--cp-60 {
    padding: 0 15px;
  }
}

.container-fluid--cp-30 {
  padding: 0 30px;
}

@media only screen and (max-width: 767px) {
  .container-fluid--cp-30 {
    padding: 0 15px;
  }
}

@media (max-width: 1919px) {
  .container-fluid--cp-60 {
    padding: 0 30px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.container_fl {
  padding-left: 15px;
  padding-right: 15px;
}

.col-06__left {
  width: 600px;
  max-width: 100%;
  float: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .col-06__left {
    float: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
}

.col-06__right {
  width: 600px;
  max-width: 100%;
  float: left;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .col-06__right {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}

.banner-image__content {
  width: 570px;
  max-width: 100%;
}

.row--10 {
  margin-left: -10px;
  margin-right: -10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--10 {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--10 {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media only screen and (max-width: 767px) {
  .row--10 {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
}

.row--10 > [class*="col"],
.row--10 > [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--10 > [class*="col"],
  .row--10 > [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--10 > [class*="col"],
  .row--10 > [class*="col-"] {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--10 > [class*="col"],
  .row--10 > [class*="col-"] {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.row--20 {
  margin-left: -20px;
  margin-right: -20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--20 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--20 > [class*="col"],
.row--20 > [class*="col-"] {
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--20 > [class*="col"],
  .row--20 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--20 > [class*="col"],
  .row--20 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--20 > [class*="col"],
  .row--20 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--25 {
  margin-left: -25px;
  margin-right: -25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--25 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--25 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--25 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--25 > [class*="col"],
.row--25 > [class*="col-"] {
  padding-left: 25px;
  padding-right: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--25 > [class*="col"],
  .row--25 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--25 > [class*="col"],
  .row--25 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--25 > [class*="col"],
  .row--25 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--30 {
  margin-left: -30px;
  margin-right: -30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--30 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--30 > [class*="col"],
.row--30 > [class*="col-"] {
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--30 > [class*="col"],
  .row--30 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--30 > [class*="col"],
  .row--30 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--30 > [class*="col"],
  .row--30 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--35 {
  margin-left: -35px;
  margin-right: -35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--35 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--35 > [class*="col"],
.row--35 > [class*="col-"] {
  padding-left: 35px;
  padding-right: 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

/*=====  End of container  ======*/
/*=============================================
=            11. Animate Css            =
=============================================*/
.about-us-bg-02 {
  background: url("../images/banners/about-img-2.jpg");
}

.full-width-row {
  display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .full-width-row {
    flex-direction: column;
  }
}

@media only screen and (max-width: 767px) {
  .full-width-row {
    flex-direction: column;
  }
}

.elementor-col-50 {
  width: 50%;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .elementor-col-50 {
    width: 100%;
  }
}

/*=============================================
=              12. Header Css            =
=============================================*/
.header-area .is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  animation: .95s ease-in-out 0s normal none 1 running fadeInDown;
  z-index: 999;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.header-absolute {
  position: absolute;
  z-index: 1;
  width: 100%;
}

.header-height--120 {
  height: 120px;
  align-items: center;
  display: flex;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-height--120 {
    height: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .header-height--120 {
    height: 80px;
  }
}

.header {
  display: flex;
  align-items: center;
  /* logo */
}

.header__logo {
  flex-basis: 15%;
  padding: 14px 0;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.header__logo.top-logo {
  padding: 25px 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .header__logo {
    flex-basis: 15%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__logo {
    flex-basis: 15%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__logo {
    flex-basis: 30%;
    padding: 20px 0;
  }
}

@media only screen and (max-width: 767px) {
  .header__logo {
    flex-basis: 40%;
    padding: 20px 0;
  }
  .header__logo.top-logo {
    padding: 15px 0;
  }
}

.header__logo > a img {
  width: 160px;
}

.header__logo .dark-logo {
  display: none;
}

.header__logo .light-logo {
  display: inherit;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header__logo .dark-logo {
    display: inherit;
  }
  .header__logo .light-logo {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header__logo.mt-20 {
    margin-top: 0;
  }
  .header__logo.mb-20 {
    margin-bottom: 0;
  }
}

.header-right {
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.real-estate__header-center {
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  display: flex;
  justify-content: center;
}

.real-estate__right {
  display: flex;
}

.real-estate__right a {
  font-size: 20px;
  margin-left: 15px;
}

.real-estate__right .minicart-btn {
  font-size: 20px;
}

.header-col__right {
  display: flex;
  align-items: center;
  color: #ffffff;
}

.header-col__right a {
  font-size: 30px;
  margin-left: 25px;
}

.header-col__right a.search-icon {
  font-size: 20px;
}

@media only screen and (max-width: 767px) {
  .header-col__right a {
    margin-left: 13px;
    font-size: 24px;
  }
}

.header-col__right .header-icon {
  font-size: 20px;
}

.minicart-count_area {
  position: relative;
}

span.cart-item_count {
  position: absolute;
  background: #333;
  color: #fff;
  font-size: 10px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  text-align: center;
  margin-left: -50%;
  transform: translateX(-50%);
}

.real-estate__header-info-right {
  display: flex;
  color: #ffffff;
  justify-content: flex-end;
  align-items: center;
}

.real-estate__header-info-right .item {
  margin-left: 25px;
}

@media only screen and (max-width: 575px) {
  .real-estate__header-info-right .item {
    margin-left: 0px;
  }
}

.real-estate__header-info-right .item i {
  margin-right: 6px;
}

.text-style-black {
  color: #222;
}

.real_estate-header-top .header-social-top {
  margin: 20px 0;
}

.real_estate-header-top .header-social-top .header-social-left li {
  display: inline-block;
}

.real_estate-header-top .header-social-top .header-social-left li a {
  margin-right: 20px;
  font-size: 20px;
}

@media only screen and (max-width: 575px) {
  .real_estate-header-top .header-social-top .header-social-left li a {
    margin-right: 10px;
    font-size: 16px;
  }
}

.real_estate-header-top .hamburger-trigger-top {
  margin-left: 15px;
  font-size: 20px;
}

.header-box-area {
  padding: 20px 30px;
  background-color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-box-area {
    padding: 5px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .header-box-area {
    padding: 0px 15px;
  }
}

/*============================== 
    - Navigation Menu Css
===============================*/
.navigation-menu > ul {
  display: flex;
}

.navigation-menu > ul > li {
  margin: 0 20px;
}

@media only screen and (min-width: 1500px) and (max-width: 1599px) {
  .navigation-menu > ul > li {
    margin: 0 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .navigation-menu > ul > li {
    margin: 0 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navigation-menu > ul > li {
    margin: 0 10px;
  }
}

.navigation-menu > ul > li > a {
  display: block;
  color: #ffffff;
  padding: 20px 2px;
  position: relative;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.18;
  text-transform: uppercase;
}

.navigation-menu > ul > li > a:before {
  content: '';
  width: 0;
  height: 3px;
  bottom: 0;
  position: absolute;
  left: 0;
  background-color: #4241ff;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.navigation-menu > ul > li.has-children > a {
  position: relative;
}

.navigation-menu > ul > li.has-children > a:after {
  position: static;
  margin-left: 5px;
  content: '\ea10';
  font-size: 12px;
  vertical-align: middle;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-family: 'LaStudioIcons';
}

.navigation-menu > ul > li.has-children--multilevel-submenu {
  position: relative;
}

.navigation-menu > ul > li.has-children:hover .megamenu {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.navigation-menu > ul > li.has-children:hover .megamenu--home-variation__item {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.navigation-menu > ul > li.has-children:hover > .submenu {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.navigation-menu > ul > li:hover > a:after, .navigation-menu > ul > li.active > a:after {
  color: #4241ff;
}

.navigation-menu > ul > li:hover > a span, .navigation-menu > ul > li.active > a span {
  color: #4241ff;
}

.navigation-menu > ul > li:hover > a:before, .navigation-menu > ul > li.active > a:before {
  width: 100%;
  left: 0;
}

.navigation-menu.nav-style-one > ul > li {
  margin: 30px 8px;
}

.navigation-menu.nav-style-one > ul > li > a {
  padding: 10px 2px;
}

.navigation-menu.nav-style-one > ul > li > a:before {
  top: 0;
}

.navigation-menu.nav-style-one > ul > li:hover > a:after, .navigation-menu.nav-style-one > ul > li.active > a:after {
  color: #4241ff;
}

.navigation-menu.nav-style-one > ul > li:hover > a span, .navigation-menu.nav-style-one > ul > li.active > a span {
  color: #4241ff;
}

.navigation-menu.nav-style-one > ul > li:hover > a:before, .navigation-menu.nav-style-one > ul > li.active > a:before {
  width: 80%;
  left: 0;
}

.navigation-menu.nav-style-three > ul > li {
  padding: 30px 15px;
  margin: 0;
}

.navigation-menu.nav-style-three > ul > li > a {
  padding: 20px 2px;
  color: #ffffff;
}

.navigation-menu.nav-style-three > ul > li > a:before {
  display: none;
}

.navigation-menu.nav-style-three > ul > li > a:after {
  display: none;
}

.navigation-menu.nav-style-three > ul > li:hover, .navigation-menu.nav-style-three > ul > li.active {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
}

.navigation-menu.nav-style-three > ul > li:hover > a span, .navigation-menu.nav-style-three > ul > li.active > a span {
  color: #ffffff;
}

.white-icon a {
  color: #ffffff;
}

.black-menu-style .nav-style-one > ul > li {
  margin: 30px 8px;
}

.black-menu-style .nav-style-one > ul > li > a {
  padding: 10px 2px;
  color: #111;
}

.black-menu-style .nav-style-one > ul > li > a:before {
  top: 0;
}

.black-menu-style .nav-style-one > ul > li:hover > a:after, .black-menu-style .nav-style-one > ul > li.active > a:after {
  color: #4241ff;
}

.black-menu-style .nav-style-one > ul > li:hover > a span, .black-menu-style .nav-style-one > ul > li.active > a span {
  color: #4241ff;
}

.black-menu-style .nav-style-one > ul > li:hover > a:before, .black-menu-style .nav-style-one > ul > li.active > a:before {
  width: 80%;
  left: 0;
}

/*===================================
    - Multilevel Submenu Css
====================================*/
.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  transform: translateY(50px);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0.2s;
  transition-duration: 0.4s;
  visibility: hidden;
  opacity: 0;
  min-width: 240px;
  padding: 20px 0;
  z-index: 9;
}

.submenu > li {
  position: relative;
}

.submenu > li > a {
  display: block;
  padding: 5px 30px;
  color: #444;
  font-weight: 400;
  transition: 0s;
  font-size: 14px;
}

.submenu > li > a > span {
  position: relative;
}

.submenu > li > a > span:after {
  content: '';
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  background-color: #4241ff;
  transition: 0.3s;
}

.submenu > li > a:hover {
  color: #4241ff;
}

.submenu > li > a:hover > span:after {
  width: 100%;
  left: 0;
  right: auto;
}

.submenu > li:hover > .submenu {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
  z-index: 9;
}

.submenu > li.active > a {
  color: #4241ff;
}

.submenu > li.has-children > a {
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.submenu > li.has-children > a:after {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  content: "\ea12";
  font-size: 12px;
  vertical-align: middle;
  color: #ababab;
  font-family: 'LaStudioIcons';
}

.submenu > li.has-children > a:hover:after {
  color: #4241ff;
}

.submenu > li.has-children.active > a {
  color: #4241ff;
}

.submenu .submenu {
  top: 0px;
  left: 100%;
  right: auto;
}

.submenu .submenu.left {
  right: 100%;
  left: auto;
}

.submenu .submenu .submenu {
  top: 0;
  left: 100%;
  right: auto;
}

.submenu .submenu .submenu.left {
  right: 100%;
  left: auto;
}

.submenu .submenu .submenu .submenu {
  top: 0;
  left: 100%;
  right: auto;
}

.submenu .submenu .submenu .submenu.left {
  right: 100%;
  left: auto;
}

.submenu .submenu .submenu .submenu .submenu {
  top: 0;
  left: 100%;
  right: auto;
}

.submenu .submenu .submenu .submenu .submenu.left {
  right: 100%;
  left: auto;
}

/*=========================================
    - Mega Menu Css
===========================================*/
.megamenu {
  position: absolute;
  top: 100%;
  box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  transform: translateY(50px);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0.2s;
  transition-duration: 0.4s;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
}

.megamenu--mega {
  width: 1170px;
  padding: 40px 30px;
  display: flex;
  justify-content: space-around;
  left: auto;
  right: 80px;
}

.megamenu--mega__03 {
  width: 870px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .megamenu--mega {
    width: 970px;
    right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .megamenu--mega {
    width: 100%;
    left: 0;
    right: 0;
  }
}

.megamenu--mega > li {
  flex-basis: 22%;
  padding-left: 15px;
  padding-right: 15px;
}

.megamenu--mega > li .page-list-title {
  font-size: 14px;
  margin-bottom: 20px;
  color: #111111;
  font-weight: 600;
}

.megamenu--mega > li > ul > li > a {
  padding: 10px 0;
  color: #333;
  line-height: 1.2;
  transition: 0.1s;
  font-size: 14px;
}

.megamenu--mega > li > ul > li > a:hover {
  color: #4241ff;
}

.megamenu--mega > li > ul > li > a:hover span:after {
  width: 100%;
  left: 0;
  right: auto;
}

.megamenu--mega > li > ul > li > a > span {
  position: relative;
}

.megamenu--mega > li > ul > li > a > span:after {
  content: '';
  width: 0;
  height: 0.8px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  background-color: #4241ff;
  transition: 0.3s;
}

.megamenu--mega > li > ul > li.active > a {
  color: #4241ff;
}

/* Mobile Mavigation icon */
.mobile-navigation-icon {
  width: 24px;
  height: 25px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  margin-left: 30px;
}

.mobile-navigation-icon:hover i {
  background-color: #4241ff;
}

.mobile-navigation-icon:hover i:before {
  width: 80%;
  background-color: #4241ff;
}

.mobile-navigation-icon:hover i:after {
  background-color: #4241ff;
  width: 60%;
}

.mobile-navigation-icon i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 2px;
  background-color: #333;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-navigation-icon i:before {
  position: absolute;
  bottom: 8px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #333;
  content: "";
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-navigation-icon i:after {
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #333;
  content: "";
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mobile-navigation-icon.white-md-icon i {
    background-color: #fff;
  }
  .mobile-navigation-icon.white-md-icon i:before {
    background-color: #fff;
  }
  .mobile-navigation-icon.white-md-icon i:after {
    background-color: #fff;
  }
  .mobile-navigation-icon.white-md-icon:hover i {
    background-color: #4241ff;
  }
  .mobile-navigation-icon.white-md-icon:hover i:before {
    width: 80%;
    background-color: #4241ff;
  }
  .mobile-navigation-icon.white-md-icon:hover i:after {
    background-color: #4241ff;
    width: 60%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .is-sticky .white-md-icon i {
    background-color: #333;
  }
  .is-sticky .white-md-icon i:before {
    background-color: #333;
  }
  .is-sticky .white-md-icon i:after {
    background-color: #333;
  }
}

/*================================ 
    - Mobile Menu Overlay Css
==================================*/
.mobile-menu-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #111111;
  overflow: auto;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  opacity: 0;
}

.mobile-menu-overlay__inner {
  transform: translateX(120%);
  width: 400px;
  height: 100%;
  float: right;
  cursor: default;
  background: #4241ff;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow-y: auto;
}

@media only screen and (max-width: 479px) {
  .mobile-menu-overlay__inner {
    width: 300px;
  }
}

.mobile-menu-overlay.active {
  visibility: visible;
  opacity: 1;
}

.mobile-menu-overlay.active .mobile-menu-overlay__inner {
  transform: translateX(0%);
}

.mobile-menu-overlay__header {
  background-color: #ffffff;
  padding: 15px 0;
}

.mobile-menu-overlay__header .mobile-navigation-close-icon {
  position: relative;
  cursor: pointer;
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: inline-block;
}

.mobile-menu-overlay__header .mobile-navigation-close-icon:before {
  position: absolute;
  top: 23px;
  left: 8px;
  content: '';
  width: 24px;
  height: 3px;
  background: #111111;
  transform-origin: 50% 50%;
  transform: rotate(45deg);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__header .mobile-navigation-close-icon:after {
  position: absolute;
  top: 23px;
  left: 8px;
  content: '';
  width: 24px;
  height: 3px;
  background: #111111;
  transform-origin: 50% 50%;
  transform: rotate(-45deg);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__header .mobile-navigation-close-icon:hover {
  color: #4241ff;
}

.mobile-menu-overlay__header .mobile-navigation-close-icon:hover:before, .mobile-menu-overlay__header .mobile-navigation-close-icon:hover:after {
  transform: none;
}

.mobile-menu-overlay__body {
  padding: 20px 40px 100px;
}

.mobile-menu-overlay__body .offcanvas-navigation {
  /* onepage style */
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li > a {
  display: block;
  color: #ffffff;
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li > a:hover {
  color: #ffffff;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li:last-child {
  border-bottom: 0;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children {
  position: relative;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children.active .menu-expand:before {
  content: '\f106';
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .menu-expand {
  position: absolute;
  right: 0;
  top: 12px;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .menu-expand:hover {
  background: rgba(255, 255, 255, 0.2);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .menu-expand:before {
  content: '\f107';
  font-size: 18px;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu {
  padding: 12px 0 14px 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li a {
  display: block;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  line-height: 1.5;
  padding: 10px 0;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li a:hover {
  color: #ffffff;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li:last-child {
  border-bottom: 0;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children {
  position: relative;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children.active .menu-expand:before {
  content: "\f106";
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand {
  position: absolute;
  right: 0;
  top: 6px;
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand:hover {
  background: rgba(255, 255, 255, 0.2);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand:before {
  content: '\f107';
  font-size: 16px;
  font-weight: 500;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children {
  position: relative;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children.active .menu-expand:before {
  content: "\f106";
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children .menu-expand:before {
  content: '\f107';
  font-size: 16px;
  font-weight: 500;
}

.mobile-menu-overlay__body .offcanvas-navigation--onepage > ul > li.active > a {
  font-weight: 700;
}

.text-black .navigation-menu > ul > li > a {
  margin: 30px auto;
  color: #222;
  font-size: 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .text-black .navigation-menu > ul > li > a {
    font-size: 14px;
  }
}

.menu-style-four .navigation-menu > ul > li > a {
  margin: 30px auto;
  color: #222;
}

.menu-style-five .navigation-menu > ul > li {
  margin: 0 0px;
}

.menu-style-five .navigation-menu > ul > li.active > a, .menu-style-five .navigation-menu > ul > li:hover > a {
  background: #ea622b;
}

.menu-style-five .navigation-menu > ul > li.active > a span, .menu-style-five .navigation-menu > ul > li:hover > a span {
  color: #fff;
}

.menu-style-five .navigation-menu > ul > li > a {
  color: #fff;
  padding: 30px 20px;
}

.menu-style-five .navigation-menu > ul > li > a:before {
  display: none;
}

.menu-style-five .navigation-menu > ul > li > a::after {
  display: none;
}

/*======================================
    - offset search
======================================*/
.page-oppen-off-sidebar {
  right: 0;
}

.page-oppen-off-sidebar__inner {
  transform: translateX(100%);
  width: 400px;
  float: right;
  background: #ffffff;
}

@media only screen and (max-width: 479px) {
  .page-oppen-off-sidebar__inner {
    width: 300px;
  }
}

.page-oppen-off-sidebar.active .page-oppen-off-sidebar__inner {
  transform: translateX(0%);
}

.page-oppen-off-sidebar__header {
  background: #ffffff;
  padding: 15px 35px;
}

.page-oppen-off-sidebar .off-sidebar-contact-info--list .item {
  display: flex;
  margin-bottom: 12px;
}

.page-oppen-off-sidebar .off-sidebar-contact-info--list .item .icon {
  color: #6d70a6;
  min-width: calc(1em + 18px);
  font-size: 16px;
}

.page-oppen-off-sidebar .off-sidebar-contact-info--list .item a:hover {
  color: #38cb89;
}

.page-oppen-off-sidebar .off-sidebar-contact-info .off-sidebar-widget__list {
  overflow: hidden;
}

.page-oppen-off-sidebar .off-sidebar-contact-info .off-sidebar-widget__list li {
  width: 50%;
  float: left;
  margin-bottom: 8px;
}

.page-oppen-off-sidebar .off-sidebar-contact-info .off-sidebar-widget__list li a {
  line-height: 17px;
}

.header-social-left li {
  display: inline-block;
  color: #fff;
}

.header-social-left.black-icon li {
  color: #111;
}

.header-social-left.black-icon li a {
  font-size: 16px;
}

.header-info-right {
  display: flex;
  justify-content: flex-end;
  color: #fff;
}

.header-search-input-box {
  position: relative;
}

.header-search-input-box input {
  border: 1px solid #ddd;
  padding: 10px 10px;
  min-width: 500px;
  color: #ea622b;
}

.header-search-input-box input:focus {
  border: 1px solid #ddd;
  color: #ea622b;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-search-input-box input {
    min-width: 380px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-search-input-box input {
    min-width: 340px;
  }
}

@media only screen and (max-width: 767px) {
  .header-search-input-box {
    display: none;
  }
}

.header-search-input-box .search-btn-top {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  padding: 18px;
  border: none;
}

.header-search-input-box .search-btn-top:hover {
  color: #ea622b;
}

.lg-icon {
  font-size: 28px !important;
}

.menu_inner-img {
  position: relative;
  cursor: pointer;
  width: 100%;
}

.menu_inner-img a {
  display: block;
}

.menu_inner-img:before {
  background-color: #000000;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in;
}

.menu_inner-img .banner-add-content {
  position: absolute;
  bottom: 0;
  padding: 30px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.menu_inner-img .banner-add-content h5 {
  color: #ffffff;
  font-weight: 600;
}

.menu_inner-img .banner-add-content p {
  color: #ffffff;
  font-size: 14px;
}

.menu_inner-img:hover:before {
  opacity: 0.4;
}

.menu_inner-img:hover .banner-add-content {
  opacity: 1;
}

/*=====  End of Header  ======*/
/*=========================================
=               13. Footer Css            =
===========================================*/
.align-tems-style .footer-widget:first-child, .align-tems-style .footer-widget:last-child {
  align-items: center;
  display: flex;
}

.footer-widget__logo {
  max-width: 220px;
}

.reveal-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .reveal-footer {
    position: static;
  }
}

.footer-widget-wrapper {
  margin-bottom: -35px;
}

.footer-widget {
  margin-bottom: 30px;
}

.footer-widget__title {
  margin-top: -3px;
  color: #111111;
}

.footer-widget__list li {
  margin-bottom: 25px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
}

.footer-widget__list li:last-child {
  margin-bottom: 0;
}

.footer-widget__list li .image_btn {
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(51, 51, 51, 0.1);
  overflow: hidden;
}

.footer-widget__list-real_estate {
  margin-left: 70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget__list-real_estate {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget__list-real_estate {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget__list-real_estate {
    margin-left: 0;
  }
}

.footer-widget__list-real_estate li {
  margin-bottom: 15px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.footer-widget__list-real_estate li:last-child {
  margin-bottom: 0;
}

.footer-widget__list-real_estate li .image_btn {
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(51, 51, 51, 0.1);
  overflow: hidden;
}

.footer-newsletter {
  position: relative;
}

.footer-newsletter::before {
  content: "\ea02";
  font-family: 'LaStudioIcons';
  position: absolute;
  left: 0;
  top: 50%;
  font-size: 24px;
  line-height: 20px;
  margin-top: -10px;
  height: 20px;
  width: 20px;
  color: #ffffff;
}

.footer-newsletter input {
  background: transparent;
  border: none;
  border-bottom: 1px solid #ddd;
  width: 100%;
  position: relative;
  padding: 10px 100px 10px 30px;
}

.footer-newsletter input:focus {
  border-bottom: 1px solid #ddd;
  color: #fff;
}

.footer-newsletter button {
  position: absolute;
  right: 0;
  background: transparent;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  color: #ffffff;
  font-weight: 600;
}

.footer-newsletter button:hover {
  color: #4241ff;
}

.footer-menu ul li {
  display: inline-block;
  color: #fff;
  font-size: 14px;
  margin: 0 15px;
}

.footer-menu ul li a {
  font-weight: 600;
  text-transform: uppercase;
}

.footer-social-white li {
  color: #ffffff;
  display: inline-block;
  margin-right: 20px;
  font-size: 20px;
}

.footer-social-black li {
  color: #000000;
  display: inline-block;
  margin-right: 20px;
  font-size: 20px;
}

.social-group__list .ht-social-networks.tilte-style .link-text {
  color: #CCCCCC;
}

.social-group__list .ht-social-networks.tilte-style .link-text::before {
  background: rgba(255, 255, 255, 0.2);
}

.social-group__list .ht-social-networks.tilte-style .link-text:hover {
  color: #ffffff;
}

.social-group__list .ht-social-networks.tilte-style .social-link::before {
  color: #ccc;
}

.copyright-text {
  display: inline-block;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .copyright-text {
    margin-bottom: 15px;
  }
}

.copyright-text-two {
  font-size: 14px;
}

/*=====  End of footer ======*/
/*=======================================
=            14. Sidebar Css           =
=========================================*/
.sidebar-widget + .sidebar-widget {
  margin-top: 60px;
}

.sidebar-widget-title {
  margin-bottom: 25px;
  font-weight: 500;
  position: relative;
  padding-bottom: 25px;
}

.sidebar-widget-title::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  height: 4px;
  width: 40px;
  background: #4241ff;
}

.widget-search {
  position: relative;
}

.widget-search input {
  width: 100%;
  border: 1px solid #333;
  border-radius: 0px;
  padding: 3px 20px;
  padding-right: 20px;
  height: 46px;
  color: #333;
  font-size: 15px;
  font-weight: 400;
  padding-right: 72px;
}

.widget-search .search-submit {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  border: 0;
  border-radius: 0 5px 5px 0;
  width: 56px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  background: none;
  box-shadow: none;
  transform: none;
}

.widget-search .search-submit:hover {
  color: #4241ff;
}

.widget-blog-recent-post ul li {
  margin-bottom: 15px;
  font-size: 14px;
}

.widget-blog-categories-post ul .single-categories-post {
  display: flex;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.widget-blog-categories-post ul .single-categories-post .pr-item-left-img {
  display: block;
  max-width: 110px;
}

.widget-blog-categories-post ul .single-categories-post .pr-item--right {
  margin-left: 15px;
}

.widget-blog-categories-post ul .single-categories-post .pr-item--right h6 {
  font-size: 14px;
  font-weight: 600;
}

.widget-blog-categories-post ul .single-categories-post .pr-item--right span {
  font-size: 12px;
}

.select-down {
  border: 1px solid #ddd;
  border-radius: 0;
  font-size: 14px;
  background: transparent url("../images/icons/selector-icon.png") no-repeat center right 20px;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.select-down:focus {
  background: transparent url("../images/icons/selector-icon.png") no-repeat center right 20px !important;
}

.widget-tag .ht-btn {
  color: #333;
  background: transparent;
  margin-right: 5px;
  font-weight: 500;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 12px;
  padding: 4px 10px;
}

.widget-tag .ht-btn:hover {
  border: 1px solid #4241ff;
  color: #fff;
  background: #4241ff;
}

.widget-product-item {
  overflow: hidden;
  margin-bottom: 20px;
}

.widget-product-item .thumbnail {
  max-width: 150px;
  float: left;
  margin-right: 20px;
  margin-left: 0;
  width: 80px;
}

.widget-product-item .info .product-title {
  font-weight: 400;
  font-size: 16px;
}

.widget-product-item .info .star-rating a {
  display: inline-block;
  color: #ffb805;
  line-height: 1;
  font-size: 14px;
  margin: 0;
}

.widget-product-item .info .price-box {
  margin-top: 5px;
}

.widget-product-item .info .price-box .old-price {
  font-size: .88em;
  font-weight: 400;
  color: #b6b7d2;
  text-decoration: line-through;
}

.widget-product-item .info .price-box .new-price {
  font-size: 14px;
  font-weight: 500;
  color: #6d70a6;
}

/*=====  End of Sidebar  ======*/
.shop-widget {
  margin-bottom: 40px;
}

.shop-widget .shop-widget-title {
  margin-bottom: 20px;
  font-weight: 600;
}

.widget-shop-categories ul li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 14px;
}

.widget-shop-categories ul li:last-child {
  margin-bottom: 0;
}

.widget-nav-list li {
  position: relative;
  margin-bottom: 8px;
  padding: 8px 0;
  width: 49%;
  display: inline-block;
  font-size: 14px;
}

.widget-nav-list li:last-child {
  margin-bottom: 0;
}

.shop-tag a {
  font-size: 14px;
}

/*=========================================
=          15. Contact Form Css            =
===========================================*/
.contact-form__two input[type="text"], .contact-form__two input[type="email"], .contact-form__two textarea {
  border: none;
  border-bottom: 1px solid #ddd;
  width: 100%;
  padding: 8px 0;
  margin-bottom: 30px;
  background: transparent;
  color: #ffffff;
}

.contact-form__two textarea {
  height: 200px;
}

.contact-form__two--white input[type="text"], .contact-form__two--white input[type="email"], .contact-form__two--white textarea {
  border: none;
  border-bottom: 2px solid #333;
  width: 100%;
  padding: 8px 0;
  margin-bottom: 30px;
  background: transparent;
  color: #111;
}

.contact-form__two--white textarea {
  height: 150px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form__two--white textarea {
    height: 100px;
  }
}

.contact-form__two--black input[type="text"], .contact-form__two--black input[type="email"], .contact-form__two--black textarea {
  border: none;
  border-bottom: 2px solid #fff;
  width: 100%;
  padding: 8px 0;
  margin-bottom: 30px;
  background: transparent;
  color: #fff;
}

.contact-form__two--black textarea {
  height: 150px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form__two--black textarea {
    height: 100px;
  }
}

.contact-form__three input[type="text"], .contact-form__three input[type="email"], .contact-form__three textarea {
  border: none;
  border-bottom: none;
  background-color: #eaeaea;
  color: #212121;
  font-size: 16px;
  line-height: 20px;
  padding: 30px;
  margin: 0 0 30px;
  border-style: solid;
  border-width: 0;
  width: 100%;
}

.contact-form__three textarea {
  height: 260px;
}

.contact-form__three textarea.height-140 {
  height: 140px;
}

.contact-form__three .contact-btn-three {
  background: #111;
  color: #ffffff;
  border: none;
  padding: 15px 60px;
  margin-top: -70px;
  position: absolute;
  right: 15px;
  font-weight: 600;
}

.single-contact-info__one {
  text-align: center;
  margin-top: 30px;
}

.single-contact-info__one .icon {
  font-size: 55px;
  color: #212121;
  margin-bottom: 20px;
}

.single-contact-info__one h6 {
  font-weight: 600;
}

.quick-start-content {
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .quick-start-content {
    flex-direction: column;
  }
  .quick-start-content .button-box {
    margin-top: 20px;
  }
}

.contact-wrap-box {
  padding: 100px;
  background: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-wrap-box {
    padding: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-wrap-box {
    padding: 30px;
  }
}

.contact-wrap-box .contact-form__two input[type="text"], .contact-wrap-box .contact-form__two input[type="email"], .contact-wrap-box .contact-form__two textarea {
  border-bottom: 1px solid #333;
  color: #333;
}

.contact-wrap-box .contact-form__two input[type="text"]::placeholder, .contact-wrap-box .contact-form__two input[type="email"]::placeholder, .contact-wrap-box .contact-form__two textarea::placeholder {
  color: #ffffff;
}

.contact-wrap-box .submit-btn-transparent {
  background: transparent;
  font-weight: 600;
  font-size: 25px;
  border: none;
}

.single-asking-wrap {
  margin-bottom: 40px;
}

.single-asking-wrap .content a {
  color: #4241ff;
}

.googleMap-1 {
  height: 800px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .googleMap-1 {
    height: 400px;
  }
}

.googleMap-2, .googleMap-3, .googleMap-4 {
  height: 400px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .googleMap-2, .googleMap-3, .googleMap-4 {
    height: 300px;
  }
}

/*=========================================
=          16. Services Css            =
===========================================*/
.single-architecture-service {
  display: block;
  position: relative;
  overflow: hidden;
}

.single-architecture-service::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: rgba(66, 65, 254, 0.8);
  opacity: 0;
}

.single-architecture-service:hover .services-content .services-title {
  animation-duration: 1.25s;
  animation-name: m1ServicesIn;
}

.single-architecture-service:hover .services-content p {
  display: block;
  animation-duration: 1.45s;
  animation-name: m1ServicesIn;
}

.single-architecture-service:hover::after {
  opacity: 8;
}

.single-architecture-service .services-images img {
  width: 100%;
}

.single-architecture-service .services-content {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
  text-align: left;
  padding: 20px 30px;
}

.single-architecture-service .services-content .services-title {
  width: 100%;
  padding-bottom: 20px;
  animation-duration: .7s;
  animation-name: m1ServicesOut;
  color: #fff;
  font-weight: 600;
}

.single-architecture-service .services-content p {
  overflow: hidden;
  display: none;
  animation-duration: .7s;
  animation-name: m1ServicesIn;
  color: #fff;
  padding-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 575px) {
  .single-architecture-service .services-content p {
    padding-bottom: 0;
    font-size: 14px;
  }
}

@keyframes m1ServicesIn {
  0% {
    transform: translate3d(0, 100px, 0);
  }
  100% {
    transform: none;
  }
}

@keyframes m1ServicesOut {
  0% {
    transform: translate3d(0, -100px, 0);
  }
  100% {
    transform: none;
  }
}

.sevices-all-view .all-view-full-xl {
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
  color: #2c2c2c;
  background-color: #f9f9f9;
  border-radius: 0;
  padding: 50px;
  display: block;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .sevices-all-view .all-view-full-xl {
    padding: 30px;
  }
}

.sevices-all-view .all-view-full-xl:hover {
  background: #222;
  color: #fff;
}

.single-service--two {
  display: flex;
  max-width: 600px;
  margin: auto;
  margin-top: 50px;
}

.single-service--two .service-image {
  min-width: 100px;
  margin: 0 35px 0 0;
}

@media only screen and (max-width: 767px) {
  .single-service--two .service-image {
    min-width: 70px;
    margin: 0 15px 0 0;
  }
}

.single-service--two .service-title {
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  margin-bottom: 15px;
}

.single-service--two p {
  font-size: 20px;
  margin: 0 0 25px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .single-service--two p {
    font-size: 18px;
  }
}

.single-service--two .services-more {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.single-service--two .services-more i {
  margin-left: 20px;
}

.single-service--three {
  background: #fff;
  border: 1px solid #ddd;
  padding: 40px 20px;
  transition: all 0.4s ease-in-out;
  margin-bottom: 40px;
}

.single-service--three .service-image {
  width: 80px;
  height: 80px;
  margin: auto;
}

.single-service--three .services-content .text-dec {
  font-size: 16px;
}

.single-service--three .services-content .services-more {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
}

.single-service--three:hover {
  transform: translateY(-20px);
}

.single-service--three:hover .services-more {
  color: #4241ff;
  border-bottom: 1px solid #4241ff;
}

.single-service--four {
  margin-top: 60px;
}

.single-service--four .service-image {
  width: 140px;
  height: 140px;
  line-height: 140px;
  margin: auto;
  background: #4241ff;
  text-align: center;
  color: #ffffff;
  border-radius: 100%;
  font-size: 48px;
}

.single-service--four .services-content {
  margin-top: 25px;
}

.single-service--four .services-content p {
  margin-top: 15px;
  font-size: 20px;
}

.single-service--five {
  overflow: hidden;
}

.single-service--five .service-image {
  display: block;
  position: relative;
}

.single-service--five .service-image img {
  width: 100%;
}

.single-service--five .service-image::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  content: '';
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.single-service--five .service__content {
  position: absolute;
  bottom: 0;
  z-index: 2;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 20px;
  padding: 25px 50px;
  background: #fff;
  font-family: "Roboto Condensed", sans-serif;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 767px) {
  .single-service--five .service__content {
    padding: 15px 25px;
  }
}

.single-service--five .service__content--two {
  position: absolute;
  left: 50%;
  z-index: 2;
  top: 50%;
  transform: translate(-50%, -50%);
}

.single-service--five .service__content--two .banner__title {
  font-size: 30px;
  color: #ffffff;
  text-transform: uppercase;
}

.single-service--five:hover .service-image img {
  transform: scale(1.1);
}

.single-service--five:hover .service-image::after {
  opacity: 1;
  visibility: visible;
}

.single-service--six {
  position: relative;
  overflow: hidden;
  display: block;
}

.single-service--six .services-img img {
  width: 100%;
}

.single-service--six .default-overlay::after {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  content: '';
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  z-index: 1;
}

.single-service--six .services-content {
  position: absolute;
  bottom: 0;
  margin-bottom: 30px;
  z-index: 2;
}

.single-service--six .services-content::before {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  height: 100%;
  width: 15px;
  background: #4241ff;
}

.single-service--six .services-content h6 {
  margin-left: 30px;
  font-weight: 700;
  color: #fff;
}

.single-service--six .services-content__two {
  position: absolute;
  bottom: 0;
  margin-bottom: 30px;
  z-index: 2;
}

.single-service--six .services-content__two h5 {
  margin-left: 30px;
  font-weight: 600;
  color: #fff;
}

.single-service--six:hover .default-overlay::after {
  opacity: 1;
}

.single-service--six:hover .services-img img {
  transform: scale(1.05);
}

.single-service--seven .service-content {
  margin-top: 20px;
}

.single-service--seven .service-content .text-dec {
  font-size: 20px;
  margin-top: 5px;
}

.single-award .award-image {
  width: 150px;
  margin: auto;
}

.what-we-work-inner {
  background-color: rgba(33, 33, 33, 0.9);
}

.single-count-box {
  background: #ffffff;
  padding: 80px 30px;
}

.single-count-box .icon {
  color: #4241ff;
  font-size: 40px;
}

.single-service-page-style-01 {
  margin-bottom: 60px;
  padding-bottom: 60px;
}

.single-service-page-style-01 .services-images {
  display: block;
}

.single-service-page-style-01 .services-images img {
  width: 100%;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .single-service-page-style-01 .services-pages-content_one {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-service-page-style-01 p {
    font-size: 20px;
  }
}

.single-service-page-style-01 .service-btn-mt_80 {
  margin-top: 80px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-service-page-style-01 .service-btn-mt_80 {
    margin-top: 30px;
  }
}

.service-pages-title-one {
  position: relative;
  padding-bottom: 25px;
  margin-bottom: 25px;
  max-width: 840px;
}

.service-pages-title-one::after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  height: 3px;
  width: 120px;
  background: #4241ff;
}

.service-pages-title-one.center::after {
  left: 50%;
  transform: translateX(-50%);
}

.services-images__three .parallax-imgae-01 {
  max-width: 400px;
  margin-right: 0;
  margin-left: auto;
}

.services-images__three .parallax-imgae-02 {
  margin-top: -300px;
  max-width: 400px;
  margin-right: auto;
}

/*=========================================
=          17. Feaures Css            =
===========================================*/
.single-feaures--one {
  margin-bottom: 40px;
}

.single-feaures--one .feaures-title {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 20px;
}

.single-feaures--two {
  background: #212121;
  margin-bottom: 30px;
  padding: 40px 30px 80px;
}

.single-feaures--two .feaures-icon {
  margin-bottom: 30px;
}

.single-feaures--two .feaures-icon img {
  width: 90px;
}

.single-feaures--two .feaures-title {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
  padding-bottom: 25px;
  margin-bottom: 25px;
  position: relative;
}

.single-feaures--two .feaures-title::after {
  position: absolute;
  content: '';
  left: 50%;
  bottom: 0;
  height: 4px;
  width: 60px;
  background: #fff;
  transform: translateX(-50%);
}

.single-feaures--two p {
  font-size: 18px;
}

.single-feaures--three .feaures-icon {
  font-size: 90px;
  line-height: 1;
  margin-bottom: 12px;
}

.single-feaures--three .feaures-content .service-title {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 26px;
}

.single-feaures--three .feaures-content p {
  font-size: 22px;
}

.single-feaures--four {
  margin-top: 60px;
}

.single-feaures--four .feaures-icon {
  margin-bottom: 30px;
}

.single-feaures--four .feaures-content p {
  font-size: 18px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-feaures--four {
    margin-top: 40px;
  }
}

/*=========================================
=          18. Work Process Css            =
===========================================*/
.single-process {
  text-align: center;
  background: #4241ff;
  text-align: center;
  height: 220px;
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 500px;
  margin: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .single-process {
    height: 170px;
    width: 170px;
  }
}

@media only screen and (max-width: 767px) {
  .single-process {
    margin-bottom: 30px;
  }
}

.single-process .process-box .process-icon {
  font-size: 42px;
  color: #ffffff;
}

.single-process .process-box .text p {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 600;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .single-process .process-box .text p {
    font-size: 16px;
  }
}

.single-process::after {
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translateY(-50%);
  content: "\ead0";
  font-family: 'dliconoutline';
  font-size: 45px;
  color: #ffffff;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .single-process::after {
    display: none;
  }
}

.workprocess-one .col-lg-3:last-child .single-process::after {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .workprocess-one .col-lg-3:last-child .single-process::after {
    display: none;
  }
}

.workprocess-three .col-lg-3:last-child .process-box::after {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .workprocess-three .col-lg-3:nth-child(2) .process-box::after {
    display: none;
  }
}

.single-process--two .process-icon {
  font-size: 46px;
  height: 118px;
  width: 118px;
  background-color: #212121;
  color: #ea622b;
  border-radius: 50%;
  text-align: center;
  line-height: 130px;
  margin: auto;
}

.single-process--two .process-content {
  margin-top: 20px;
}

.single-process--two .process-content .title {
  font-weight: 600;
  font-size: 28px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 8px;
}

.single-process--two .process-content p {
  font-size: 20px;
}

.single-process--three .process-box {
  position: relative;
}

.single-process--three .process-box::after {
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translateY(-50%);
  content: "\ead0";
  font-family: 'dliconoutline';
  font-size: 45px;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .single-process--three .process-box::after {
    display: none;
  }
}

.single-process--three .process-box .process-icon {
  text-align: center;
  background: #ea622b;
  text-align: center;
  height: 220px;
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 500px;
  margin: auto;
  position: relative;
  font-size: 68px;
  color: #ffffff;
}

.single-process--three .process-box .process-icon::before {
  content: '';
  border-top: 34px solid #ea622b;
  border-left: 34px solid transparent;
  border-right: 34px solid transparent;
  display: block;
  position: absolute;
  left: calc(50% - 34px);
  top: calc(100% - 8px);
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-process--three .process-box .process-icon {
    height: 140px;
    width: 140px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-process--three .process-box .process-icon {
    height: 140px;
    width: 140px;
  }
}

.single-process--three .process-content {
  margin-top: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .single-process--three {
    margin-bottom: 30px;
  }
}

/*=========================================
=          19. Project Css               =
===========================================*/
.ht-tab-menu-two button {
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.4em;
  letter-spacing: -2px;
  padding: 0 20px;
  background: transparent;
  border: none;
  color: #ffffff;
}

.ht-tab-menu-two button.is-checked, .ht-tab-menu-two button:hover {
  color: #ea622b;
}

.single-project--one .project-top-wrap {
  display: block;
  position: relative;
}

.single-project--one .project-top-wrap .project-image {
  display: block;
}

.single-project--one .project-top-wrap .project-image img {
  width: 100%;
}

.single-project--one .project-top-wrap .default-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.single-project--one .project-top-wrap .default-overlay::after {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(66, 65, 255, 0.9);
  content: '';
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.single-project--one .project-top-wrap .project-link-icon {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
  text-align: center;
}

.single-project--one .project-top-wrap .project-link-icon .icon-box {
  background: #ffffff;
  width: 50px;
  height: 50px;
  line-height: 55px;
  color: #111;
  margin: 10px;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
}

.single-project--one .project-top-wrap .project-link-icon .icon-box:hover {
  transform: scale(1.2);
}

@media only screen and (max-width: 767px) {
  .single-project--one .project-top-wrap .project-link-icon .icon-box {
    margin: 3px;
  }
}

.single-project--one:hover .default-overlay::after {
  opacity: 1;
}

.single-project--one:hover .project-link-icon {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.populere-portfolio__inner {
  display: flex;
  flex-wrap: wrap;
}

.populer-portfolio__content {
  -webkit-flex: 0 0 35.15%;
  -ms-flex: 0 0 35.15%;
  flex: 0 0 35.15%;
  max-width: 35.15%;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .populer-portfolio__content {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.populere-portfolio__image {
  -webkit-flex: 0 0 64.85%;
  -ms-flex: 0 0 64.85%;
  flex: 0 0 64.85%;
  max-width: 64.85%;
  position: relative;
}

.populere-portfolio__image a {
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .populere-portfolio__image {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.populer-portfolio__content_inner {
  background: #000;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
  text-align: center;
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
}

.populer-portfolio__content_inner .project-num-title {
  font-weight: 600;
  padding-top: 20px;
  position: relative;
}

.populer-portfolio__content_inner .project-num-title::after {
  position: absolute;
  left: 50%;
  bottom: -10px;
  content: '';
  height: 2px;
  width: 30px;
  background: #fff;
  transform: translateX(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .populer-portfolio__content_inner {
    padding-bottom: 50px;
  }
}

.populer-project-slider .slick-arrow {
  position: absolute;
  align-items: center;
  cursor: pointer;
  z-index: 999;
  transition: all .2s;
  border: none;
  width: 50px;
  height: 50px;
  top: calc(100% - 100px);
  background: #fff;
  color: #212121;
  border-radius: 0;
  left: calc(35% + 50px);
  margin-top: 0;
  right: auto;
}

.populer-project-slider .slick-arrow.slick-next {
  margin-left: 60px;
}

.populer-project-slider .slick-arrow:hover {
  background: #111;
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .populer-project-slider .slick-arrow {
    top: calc(100% - 400px);
  }
}

@media only screen and (max-width: 767px) {
  .populer-project-slider .slick-arrow {
    top: calc(100% - 400px);
  }
}

@media only screen and (max-width: 575px) {
  .populer-project-slider .slick-arrow {
    top: calc(100% - 420px);
    left: calc(35% + 0px);
  }
}

.single-project--two:hover .project-top-wrap .project-image {
  transform: scale(1.1);
}

.single-project--two:hover .project-top-wrap .project-image::after {
  opacity: 1;
}

.single-project--two .project-top-wrap {
  overflow: hidden;
}

.single-project--two .project-top-wrap .project-image {
  position: relative;
  display: block;
  overflow: hidden;
  transform: scale(1);
}

.single-project--two .project-top-wrap .project-image::after {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.single-project--two .project-top-wrap .project-image img {
  width: 100%;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .single-project--two .project-text-content {
    margin-top: 30px;
  }
}

.single-project--two .project-text-content .text-dec {
  font-size: 18px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-project--two .project-text-content p {
    font-size: 16px;
  }
}

.single-project--two .project-text-content .more-arrow-btn {
  width: 70px;
  margin-top: 30px;
}

.single-project--two .project-text-content .more-arrow-btn svg > * {
  color: #666;
}

.single-project--three {
  position: relative;
  overflow: hidden;
}

.single-project--three .project-top-wrap {
  display: block;
  position: relative;
}

.single-project--three .project-top-wrap .project-image {
  display: block;
}

.single-project--three .project-top-wrap .project-image img {
  width: 100%;
}

.single-project--three .project-top-wrap .project-link-icon {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}

.single-project--three .project-top-wrap .project-link-icon .icon-box {
  background: #ffffff;
  width: 50px;
  height: 50px;
  line-height: 55px;
  color: #111;
  margin: 10px;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
}

.single-project--three .project-top-wrap .project-link-icon .icon-box:hover {
  transform: scale(1.2);
}

@media only screen and (max-width: 767px) {
  .single-project--three .project-top-wrap .project-link-icon .icon-box {
    margin: 5px;
  }
}

.single-project--three .project-text-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  position: absolute;
  bottom: -50px;
  left: 0;
  background: #212121;
  color: #fff;
  z-index: 2;
  text-align: left;
  padding: 30px;
  transition: all .6s ease-in-out;
  visibility: hidden;
  opacity: 0;
}

.single-project--three .project-text-content p {
  font-size: 16px;
}

.single-project--three:hover .project-text-content {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}

.single-project--three:hover .project-link-icon {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.single-project--four {
  position: relative;
  overflow: hidden;
  background: #fff;
  margin-top: 40px;
}

.single-project--four .project-top-wrap {
  display: block;
  position: relative;
}

.single-project--four .project-top-wrap .project-image {
  display: block;
  position: relative;
}

.single-project--four .project-top-wrap .project-image::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.single-project--four .project-top-wrap .project-link-icon {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}

.single-project--four .project-top-wrap .project-link-icon .icon-box {
  background: #ffffff;
  width: 50px;
  height: 50px;
  line-height: 55px;
  color: #111;
  margin: 10px;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
}

.single-project--four .project-top-wrap .project-link-icon .icon-box:hover {
  transform: scale(1.2);
}

.single-project--four .project-text-content {
  padding: 0 20px 40px;
}

.single-project--four .project-text-content .project_catag {
  font-size: 18px;
  margin-bottom: 5px;
}

.single-project--four .project-text-content .text-dec {
  font-size: 18px;
}

.single-project--four:hover .project-image::after {
  visibility: visible;
  opacity: 1;
}

.single-project--four:hover .project-link-icon {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.single-project--five {
  position: relative;
  overflow: hidden;
}

.single-project--five .project-image {
  display: block;
}

.single-project--five .project-image img {
  width: 100%;
}

.single-project--five .project-content {
  background: rgba(0, 0, 0, 0.8);
  padding: 26px;
  position: absolute;
  left: 20px;
  right: 20px;
  top: 50px;
  bottom: -20px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}

.single-project--five .project-content h5 {
  font-size: 20px;
  margin-bottom: 5px;
}

.single-project--five .project-content .product-number {
  color: #ffffff;
  padding-bottom: 12px;
  font-size: 12px;
}

.single-project--five .project-content p {
  margin-top: 20px;
  color: #fff;
  font-size: 16px;
}

.single-project--five .project-content .btn-lg {
  background: #fff;
  color: #111;
  font-size: 14px;
}

.single-project--five .project-content .btn-lg:hover {
  background: #ea622b;
  color: #fff;
}

.single-project--five:hover .project-content {
  top: 20px;
  bottom: 20px;
  opacity: 1;
  visibility: visible;
}

.single-project--six .images-thum {
  display: block;
}

.single-project--six .images-thum img {
  width: 100%;
}

.single-project--six .project-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.single-project--six .project-content .content-box {
  width: 65%;
}

@media only screen and (max-width: 767px) {
  .single-project--six .project-content {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .single-project--six .project-content .content-box {
    width: 100%;
  }
  .single-project--six .project-content p {
    font-size: 15px;
  }
  .single-project--six .project-content .button-box {
    margin-top: 20px;
  }
  .single-project--six .project-content .button-box .btn--md {
    padding: 18px 35px;
    font-size: 14px;
    font-weight: 600;
  }
}

.prject-divider-one {
  position: relative;
}

.prject-divider-one::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  background: #ea622b;
  width: 40px;
  height: 4px;
}

.project-slider-nine-active .slick-arrow {
  position: absolute;
  align-items: center;
  transition: all .2s;
  border: none;
  width: 70px;
  height: 70px;
  bottom: -80px;
  background: #fff;
  color: #212121;
  border-radius: 50%;
  box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.13);
  margin-top: 0;
  left: 45%;
  transform: translateX(-50%);
  font-size: 30px;
}

.project-slider-nine-active .slick-arrow.slick-next {
  margin-left: 90px;
}

.project-slider-nine-active .slick-arrow:hover {
  background: #4241ff;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .project-slider-nine-active .slick-arrow {
    width: 60px;
    height: 60px;
    font-size: 20px;
  }
  .project-slider-nine-active .slick-arrow.slick-next {
    margin-left: 70px;
  }
}

.project-slider-nine-active .slick-slide {
  opacity: 0.5;
}

.project-slider-nine-active .slick-slide.slick-current {
  opacity: 1;
}

.single-custom-style-03 {
  padding: 250px 0;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-custom-style-03 {
    padding: 160px 0;
  }
}

@media only screen and (max-width: 767px) {
  .single-custom-style-03 {
    padding: 100px 0;
  }
}

.single-custom-style-03 .project-text-content {
  z-index: 1;
}

.single-custom-style-03 .more-arrow-btn .icon {
  font-size: 60px;
  color: #fff;
}

.single-custom-style-03 .more-arrow-btn .icon:hover {
  transform: scale(1.1);
}

.single-project-custom-style--04 .project-top-wrap, .single-project-custom-style--05 .project-top-wrap {
  display: block;
  position: relative;
}

.single-project-custom-style--04 .project-top-wrap .project-image, .single-project-custom-style--05 .project-top-wrap .project-image {
  display: block;
}

.single-project-custom-style--04 .project-top-wrap .project-image img, .single-project-custom-style--05 .project-top-wrap .project-image img {
  width: 100%;
}

.single-project-custom-style--04 .project-top-wrap .default-overlay, .single-project-custom-style--05 .project-top-wrap .default-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.single-project-custom-style--04 .project-top-wrap .default-overlay::after, .single-project-custom-style--05 .project-top-wrap .default-overlay::after {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(66, 65, 255, 0.9);
  content: '';
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.single-project-custom-style--04 .project-top-wrap .project-link-icon, .single-project-custom-style--05 .project-top-wrap .project-link-icon {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
  text-align: center;
}

.single-project-custom-style--04 .project-top-wrap .project-link-icon .icon-box, .single-project-custom-style--05 .project-top-wrap .project-link-icon .icon-box {
  background: #ffffff;
  width: 50px;
  height: 50px;
  line-height: 55px;
  color: #111;
  margin: 10px;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
}

.single-project-custom-style--04 .project-top-wrap .project-link-icon .icon-box:hover, .single-project-custom-style--05 .project-top-wrap .project-link-icon .icon-box:hover {
  transform: scale(1.2);
}

@media only screen and (max-width: 767px) {
  .single-project-custom-style--04 .project-top-wrap .project-link-icon .icon-box, .single-project-custom-style--05 .project-top-wrap .project-link-icon .icon-box {
    margin: 3px;
  }
}

.single-project-custom-style--04 .project-text-content, .single-project-custom-style--05 .project-text-content {
  padding: 20px 30px 30px 30px;
  border: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.single-project-custom-style--04 .project-text-content .icon, .single-project-custom-style--05 .project-text-content .icon {
  width: 40px;
}

.single-project-custom-style--04:hover .default-overlay::after, .single-project-custom-style--05:hover .default-overlay::after {
  opacity: 1;
}

.single-project-custom-style--04:hover .project-link-icon, .single-project-custom-style--05:hover .project-link-icon {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.single-project-custom-style--05 .project-text-content {
  position: absolute;
  width: 80%;
  margin: auto;
  left: 0;
  right: 0;
  background: #fff;
  bottom: 0;
  padding: 20px 30px 30px 30px;
  border: 1px solid transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.single-project-custom-style--05 .project-text-content .icon {
  width: 40px;
}

.single-project-custom-style--05:hover .default-overlay::after {
  opacity: 1;
}

.single-project-custom-style--05:hover .project-link-icon {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.project-breadcrumb-list .breadcrumb-item {
  display: inline-block;
  font-size: 12px;
}

.project-breadcrumb-list .breadcrumb-item a {
  position: relative;
}

.project-breadcrumb-list .breadcrumb-item a:hover::after {
  width: 100%;
  left: 0;
  right: auto;
  z-index: 0;
}

.project-breadcrumb-list .breadcrumb-item.active {
  color: #222;
}

.project-breadcrumb-list .breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: .5rem;
  content: ">";
  color: #111;
}

.bullet-icon-list-items {
  margin-top: 25px;
}

.bullet-icon-list-items .icon-list-item {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}

.bullet-icon-list-items .icon-list-item .list-icon {
  font-size: 12px;
  color: #4241ff;
  margin-right: 20px;
}

.project-item-list {
  margin-top: 25px;
}

.project-item-list li {
  display: flex;
  font-size: 18px;
  margin-bottom: 20px;
  align-items: center;
}

.project-item-list li .list-icon {
  margin-right: 20px;
}

.social-list-wrap li {
  display: inline-block;
  margin-right: 10px;
}

.social-list-wrap li a {
  font-size: 16px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  background: #111;
  color: #fff;
  border-radius: 5000px;
}

.social-list-wrap li a:hover {
  background: #4241ff;
}

.view-all-page-wrap {
  font-size: 24px;
  font-weight: 600;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  padding: 30px 0;
}

.view-all-page-wrap i {
  margin-right: 10px;
  font-size: 18px;
}

.single-project-slider-03--active .slick-arrow {
  position: absolute;
  align-items: center;
  transition: all .2s;
  border: none;
  width: 70px;
  height: 70px;
  bottom: 50px;
  background: #fff;
  color: #212121;
  border-radius: 50%;
  box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.13);
  margin-top: 0;
  left: 50%;
  transform: translateX(-55%);
  font-size: 30px;
  z-index: 9;
}

.single-project-slider-03--active .slick-arrow.slick-next {
  margin-left: 90px;
}

.single-project-slider-03--active .slick-arrow:hover {
  background: #4241ff;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .single-project-slider-03--active .slick-arrow {
    width: 40px;
    height: 40px;
    line-height: 40px;
    bottom: 30px;
    font-size: 16px;
  }
  .single-project-slider-03--active .slick-arrow.slick-next {
    margin-left: 50px;
  }
}

.single-project-slider-03--active .slick-slide {
  opacity: 0.5;
}

.single-project-slider-03--active .slick-slide.slick-current {
  opacity: 1;
}

.single-project-slider-08--active {
  position: relative;
}

.single-project-slider-08--active .slick-arrow {
  position: absolute;
  align-items: center;
  transition: all .2s;
  border: none;
  width: 70px;
  height: 70px;
  bottom: 50px;
  background: #fff;
  color: #212121;
  border-radius: 50%;
  box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.13);
  margin-top: 0;
  left: 50%;
  transform: translateX(-55%);
  font-size: 30px;
  z-index: 9;
}

.single-project-slider-08--active .slick-arrow.slick-next {
  margin-left: 90px;
}

.single-project-slider-08--active .slick-arrow:hover {
  background: #4241ff;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .single-project-slider-08--active .slick-arrow {
    width: 40px;
    height: 40px;
    line-height: 40px;
    bottom: 30px;
    font-size: 16px;
  }
  .single-project-slider-08--active .slick-arrow.slick-next {
    margin-left: 50px;
  }
}

.pages-breadcrumb-area {
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 575px) {
  .pages-breadcrumb-area {
    flex-direction: column;
  }
}

.post-new-item-list {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

@media only screen and (max-width: 575px) {
  .post-new-item-list {
    margin-top: 0;
    margin-bottom: 0px;
  }
}

.post-new-item-list li {
  margin: 0 10px;
}

.post-new-item-list li:last-child {
  margin-right: 0;
}

.single-project-post-details .post-list {
  margin-top: 30px;
}

.single-project-post-details .post-list h6 {
  font-weight: 600;
  margin-bottom: 10px;
}

.single-project-post-details .post-list p {
  font-size: 14px;
  color: #4241ff;
}

@media only screen and (max-width: 767px) {
  .details-listing .btn--md {
    padding: 12px 30px;
  }
}

/*=========================================
=          20. Button Css               =
===========================================*/
.btn {
  display: inline-block;
  background: #212121;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  margin: 0;
  padding: 20px 40px;
  border: 0;
  cursor: pointer;
  text-align: center;
  line-height: 1;
  transition: all .3s ease;
  display: inline-block;
  border-radius: 0px;
}

.btn--default {
  background: #4241ff;
}

.btn--default:hover {
  background: #111;
  color: #fff;
}

.btn--black {
  background: #222 !important;
}

.btn--black:hover {
  background: #4241ff !important;
  color: #fff !important;
}

.btn--gray {
  background: #818a91 !important;
}

.btn--gray:hover {
  background: #4241ff;
  color: #fff !important;
}

.btn--info {
  background: #5bc0de;
}

.btn--info:hover {
  background: #4241ff;
  color: #fff;
}

.btn--success {
  background: #5cb85c;
}

.btn--success:hover {
  background: #4241ff;
  color: #fff;
}

.btn--warning {
  background: #f0ad4e;
}

.btn--warning:hover {
  background: #4241ff;
  color: #fff;
}

.btn--xs {
  padding: 10px 26px;
  font-size: 14px;
}

.btn--sm {
  padding: 14px 30px;
  font-size: 14px;
}

.btn--md {
  padding: 25px 55px;
  font-size: 14px;
  font-weight: 600;
}

.btn--lg {
  padding: 28px 65px;
}

.btn--xl {
  padding: 30px 80px;
}

.btn--border-radius {
  border-radius: 50px;
}

.btn--bg-white {
  background: #ffffff;
  color: #333;
}

.btn--bg-white:hover {
  background: #212121;
  color: #fff;
}

.btn--white {
  background: #ffffff;
  color: #333;
}

.btn--white:hover {
  background: #212121;
  color: #fff;
}

.btn--four {
  background: #ea622b;
  color: #ffffff;
}

.btn--four:hover {
  background: #111;
}

.btn--border {
  display: inline-block;
  background: transparent;
  border: 1px solid #d8d8d8 !important;
  color: #333;
  font-weight: 600;
  margin: 0;
  padding: 20px 40px;
  border: 0;
  cursor: pointer;
  text-align: center;
  line-height: 1;
  transition: all .3s ease;
  display: inline-block;
  border-radius: 0px;
}

.btn--border:hover {
  background: #4241ff;
  color: #fff;
  border: 1px solid #4241ff;
}

.btn--border--default {
  background: #4241ff;
}

.btn--border--default:hover {
  background: #111;
  color: #fff;
}

.btn--border--black {
  background: #222 !important;
}

.btn--border--black:hover {
  background: #4241ff !important;
  color: #fff !important;
}

.btn--border--gray {
  background: #818a91 !important;
}

.btn--border--gray:hover {
  background: #4241ff !important;
  color: #fff !important;
}

.btn--border--sx {
  padding: 16px 30px;
  font-size: 12px;
  min-width: 150px;
}

.btn--border--sm {
  padding: 14px 30px;
  font-size: 14px;
}

.btn--border--md {
  padding: 25px 60px;
  font-size: 14px;
  font-weight: 600;
}

.btn--border--lg {
  padding: 30px 60px;
}

.btn--border--xl {
  padding: 30px 80px;
}

.btn--border--border-radius {
  border-radius: 50px;
}

.btn--border--white {
  background: #ffffff;
  color: #333;
}

.btn--border--white:hover {
  background: #212121;
  color: #fff;
}

.hero-btn-white {
  background: #ffffff;
  color: #222;
  min-width: 210px;
  max-width: 210px;
  height: 70px;
  border-radius: 40px;
  line-height: 70px;
  font-weight: 600;
  font-family: "Roboto Slab", serif;
  text-transform: uppercase;
}

.hero-btn-white:hover {
  background: #222;
  color: #fff;
}

.btn-full {
  background: #4241ff;
  width: 100%;
}

.btn-full:hover {
  color: #ffffff !important;
  background-color: #111;
}

.btn-lg-border {
  border: 2px solid #ddd;
  background: transparent;
  color: #333;
  text-transform: uppercase;
}

.btn-lg-border:hover {
  color: #fff !important;
  background: #4241ff;
  border: 2px solid #4241ff;
}

.btn-lg-border-1 {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  text-transform: uppercase;
}

.btn-lg-border-1:hover {
  color: #fff !important;
  background: #4241ff;
  border: 1px solid #4241ff;
}

.video-btn {
  background: #fff;
  color: #111;
  height: 120px;
  width: 120px;
  line-height: 120px;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  color: #4241ff;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .video-btn {
    height: 80px;
    width: 80px;
    line-height: 80px;
  }
}

.video-btn:hover {
  transform: scale(1.2);
}

.video-btn--small {
  background: #fff;
  color: #111;
  height: 80px;
  width: 80px;
  line-height: 80px;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  color: #4241ff;
  transition: all 0.3s ease-in-out !important;
}

.video-btn--small:hover {
  transform: scale(1.2);
}

.absolue-video-btn {
  position: absolute;
  z-index: 9;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.btn-three {
  background: #75ad1c;
  font-weight: 600;
  font-size: 18px;
}

.btn-three:hover {
  background: #000000;
  color: #ffffff;
}

.x-small-btn {
  min-width: 88px;
  text-align: center;
  padding: 6px 10px;
  font-size: 14px;
}

.small-btn {
  min-width: 98px;
  text-align: center;
  padding: 8px 10px;
  font-size: 14px;
}

.medium-btn {
  min-width: 128px;
  text-align: center;
  padding: 14px 25px;
  font-size: 16px;
}

.large-btn {
  min-width: 148px;
  text-align: center;
  padding: 18px 38px;
  font-size: 16px;
}

.extra-large-btn {
  min-width: 170px;
  text-align: center;
  padding: 22px 48px;
  font-size: 18px;
}

.btn--border-1 {
  border: 1px solid #333;
}

.btn--border-1:hover {
  border: 1px solid #4241ff;
  background: #4241ff;
  color: #ffffff;
}

.btn--border-2 {
  border: 2px solid #333;
}

.btn--border-2:hover {
  border: 2px solid #4241ff;
  background: #4241ff;
  color: #ffffff;
}

.btn--border-3 {
  border: 3px solid #333;
}

.btn--border-3:hover {
  border: 3px solid #4241ff;
  background: #4241ff;
  color: #ffffff;
}

.btn--border-4 {
  border: 4px solid #333;
}

.btn--border-4:hover {
  border: 4px solid #4241ff;
  background: #4241ff;
  color: #ffffff;
}

.btn--border-solid-default {
  border: 1px solid #4241ff;
}

.btn--border-solid-default:hover {
  border: 1px solid #4241ff;
  background: #4241ff;
  color: #ffffff !important;
}

.btn--padding-8 {
  text-align: center;
  padding: 8px;
  font-size: 14px;
  line-height: 1;
}

.btn--padding-12 {
  text-align: center;
  padding: 12px;
  font-size: 14px;
  line-height: 1;
}

.btn--padding-16 {
  text-align: center;
  padding: 16px;
  font-size: 14px;
  line-height: 1;
}

.btn--padding-20 {
  text-align: center;
  padding: 20px;
  font-size: 14px;
  line-height: 1;
}

.btn-radius-2 {
  border-radius: 2px;
}

.btn-radius-10 {
  border-radius: 10px;
}

.btn-radius-18 {
  border-radius: 18px;
}

.btn-radius-50 {
  border-radius: 50px;
}

.btn--border-double {
  border-style: double;
  border-width: 4px;
  border-color: #2f2f2f;
}

.btn--border-double:hover {
  border-color: #4241ff;
  background: #4241ff;
  color: #ffffff;
}

.btn--border-dotted {
  border: 2px dotted #333;
}

.btn--border-dotted:hover {
  border: 2px dotted #4241ff;
  background: #4241ff;
  color: #ffffff;
}

.btn--border-dashsed {
  border: 2px dashed #333;
}

.btn--border-dashsed:hover {
  border: 2px solid #4241ff;
  background: #4241ff;
  color: #ffffff;
}

/*=========================================
=          21. tastimonials Css               =
===========================================*/
.tastimonials-bg-01 {
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
}

.testimonials-content-one {
  padding: 150px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonials-content-one {
    padding: 100px 0;
  }
}

@media only screen and (max-width: 767px) {
  .testimonials-content-one {
    padding: 80px 0;
  }
}

.testimonials-content-one .testimonials-text {
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.testimonials-content-one .testimonials-text p {
  font-size: 26px;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .testimonials-content-one .testimonials-text p {
    font-size: 20px;
  }
}

.testimonials-content-one .testimonials-text::after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  width: 80px;
  content: '';
  background: #fff;
}

.testimonials-content-one .testimonials-author {
  display: flex;
  align-items: center;
}

.testimonials-content-one .testimonials-author .author-img {
  padding-right: 25px;
}

.testimonials-content-one .testimonials-author .author-img img {
  width: 70px;
}

.testimonials-content-one .testimonials-author .author-name {
  width: 350px;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
}

@media only screen and (max-width: 767px) {
  .testimonials-content-one .testimonials-author .author-name {
    font-size: 18px;
  }
}

.testimonials-content-two .testimonials-text {
  font-size: 26px;
  font-family: "Alegreya", serif;
}

.testimonials-content-two .testimonials-author .author-img {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.testimonials-content-two .testimonials-author .author-img img {
  margin: auto;
}

.testimonials-content-two .testimonials-author .author-name h6 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 5px;
}

.testimonials-content-two .testimonials-author .author-name span {
  font-style: italic;
  font-size: 18px;
}

.testimonials-content-three {
  border: 1px solid #555;
  padding: 30px;
  text-align: center;
  flex-direction: column;
  height: 100%;
  margin-top: 30px;
}

.testimonials-content-three .testimonials-text {
  font-size: 20px;
  font-family: "Alegreya", serif;
  line-height: 1.875em;
}

.testimonials-content-three .testimonials-author .author-img {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.testimonials-content-three .testimonials-author .author-img img {
  margin: auto;
}

.testimonials-content-three .testimonials-author .author-name h6 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 5px;
}

.testimonials-content-three .testimonials-author .author-name span {
  font-style: italic;
  font-size: 18px;
}

.testimonials-content-three .testimonials-inner {
  padding: 30px 50px;
}

.testimonials-content-three .testimonials-inner .testimonials-text {
  font-size: 24px;
  font-family: "Alegreya", serif;
  line-height: 1.875em;
}

.testimonials-content-three .testimonials-inner .testimonials-author .author-img {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.testimonials-content-three .testimonials-inner .testimonials-author .author-img img {
  margin: auto;
}

.testimonials-content-three .testimonials-inner .testimonials-author .author-name span {
  font-style: normal;
  font-size: 18px;
  font-weight: 600;
}

.testimonials-slider-3 .slick-dots, .testimonials-slider-2 .slick-dots {
  text-align: center;
  margin-top: 30px;
}

.testimonials-slider-3 .slick-dots li, .testimonials-slider-2 .slick-dots li {
  display: inline-block;
  margin: 5px;
}

.testimonials-slider-3 .slick-dots li button, .testimonials-slider-2 .slick-dots li button {
  background: #ddd;
  border-radius: 100%;
  height: 16px;
  width: 16px;
  font-size: 0px;
  border: none;
}

.testimonials-slider-3 .slick-dots li.slick-active button, .testimonials-slider-2 .slick-dots li.slick-active button {
  background: #222;
}

.testimonials-slider-2 .slick-dots li.slick-active button {
  background: #333;
}

.testimonials-content-four {
  padding: 150px 0;
  text-align: center;
  margin: auto;
}

.testimonials-content-four .testimonials-text {
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.testimonials-content-four .testimonials-text p {
  font-size: 26px;
  color: #fff;
}

.testimonials-content-four .testimonials-text::after {
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 4px;
  width: 80px;
  content: '';
  background: #fff;
  transform: translateX(-50%);
}

.testimonials-content-four .author-img {
  margin: auto;
}

.testimonials-content-four .author-img img {
  margin: auto;
}

.testimonials-content-four .author-name {
  width: 350px;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  margin: auto;
}

.testimonials-content-five {
  padding: 150px 0;
  text-align: center;
  margin: auto;
}

.testimonials-content-five .testimonials-text {
  position: relative;
  margin-bottom: 20px;
}

.testimonials-content-five .testimonials-text p {
  font-size: 26px;
  color: #fff;
}

.testimonials-content-five .author-img {
  margin: auto;
}

.testimonials-content-five .author-img img {
  margin: auto;
  width: 60px;
}

.testimonials-content-five .author-name {
  width: 350px;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  margin: 20px auto 0;
}

.testimonials-content-six {
  max-width: 970px;
  padding: 150px 0;
  text-align: center;
  margin: auto;
  display: block !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonials-content-six {
    padding: 100px 0;
  }
}

@media only screen and (max-width: 767px) {
  .testimonials-content-six {
    padding: 80px 0;
  }
}

.testimonials-content-six .testimonials-text {
  position: relative;
  margin-bottom: 20px;
}

.testimonials-content-six .testimonials-text p {
  font-size: 30px;
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonials-content-six .testimonials-text p {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonials-content-six .testimonials-text p {
    font-size: 20px;
  }
}

.testimonials-content-six .author-img {
  margin: auto;
}

.testimonials-content-six .author-img img {
  margin: auto;
  width: 60px;
}

.testimonials-content-six .author-name {
  width: 350px;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  margin: 20px auto 0;
}

.testimonials-slider-4 .slick-arrow {
  position: absolute;
  left: 0;
  top: 40%;
  transform: translateX(-40%);
  background: transparent;
  font-size: 50px;
  border: none;
  color: #fff;
}

.testimonials-slider-4 .slick-arrow.slick-next {
  right: 0;
  left: auto;
}

.testimonials-content-seven .testimonials-text {
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-bottom: 20px;
}

.testimonials-content-seven .testimonials-text::after {
  background: #ddd;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  content: "";
  height: 1px;
  width: 50px;
}

.testimonials-content-seven .author-img {
  margin: auto;
}

.testimonials-content-seven .author-img img {
  margin: auto;
}

.testimonials-content-interior-one {
  padding: 100px 0;
  text-align: center;
  margin: auto;
}

.testimonials-content-interior-one .testimonials-text {
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.testimonials-content-interior-one .testimonials-text p {
  font-size: 24px;
  color: #fff;
  font-family: "Alegreya", serif;
}

.testimonials-content-interior-one .testimonials-text::after {
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 6px;
  width: 60px;
  content: '';
  background: #ea622b;
  transform: translateX(-50%);
}

.testimonials-content-interior-one .author-img {
  margin: auto;
}

.testimonials-content-interior-one .author-img img {
  margin: auto;
}

.testimonials-content-interior-one .author-name {
  width: 350px;
  color: #fff;
  font-size: 10px;
  margin: auto;
}

.testimonials-content-interior-one .author-name h5 {
  color: #ffffff;
}

.testimonials-content-interior-one .author-name h5 span {
  font-size: 14px;
  color: #ddd;
}

.testimonials-content-element-one {
  border: 1px solid #ddd;
  padding: 50px;
  text-align: center;
}

.testimonials-content-element-one .testimonials-text {
  line-height: 1.875em;
}

.testimonials-content-element-one .testimonials-author .author-img {
  margin-top: 15px;
  margin-bottom: 10px;
  text-align: center;
}

.testimonials-content-element-one .testimonials-author .author-img img {
  margin: auto;
  width: 100px;
}

.testimonials-content-element-one .testimonials-author .author-name span {
  font-size: 14px;
  line-height: 1;
}

.testimonials-content-element-two {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .testimonials-content-element-two {
    flex-direction: column;
  }
}

.testimonials-content-element-two .testimonials-text {
  line-height: 1.875em;
  margin-left: 20px;
}

.testimonials-content-element-two .testimonials-text p {
  font-size: 14px;
}

.testimonials-content-element-two .author-name span {
  font-size: 14px;
  line-height: 1;
}

.testimonials-content-element-two .author-name span.name {
  color: #9b9b9b;
}

.testimonials-content-element-two .testimonials-author .author-img {
  margin-bottom: 10px;
  text-align: center;
}

.testimonials-content-element-two .testimonials-author .author-img img {
  margin: auto;
  width: 60px;
}

.testimonials-content-element-two .rating li {
  display: inline-block;
}

.testimonials-content-element-two .rating li i {
  color: #f5b223;
}

.testimonials-content-element-three {
  padding: 50px 50px 0;
  text-align: center;
}

.testimonials-content-element-three .testimonials-text {
  line-height: 1.875em;
}

.testimonials-content-element-three .testimonials-text p {
  font-size: 14px;
}

.testimonials-content-element-three .author-img {
  margin-bottom: 20px;
  text-align: center;
}

.testimonials-content-element-three .author-img img {
  margin: auto;
  width: 100px;
}

.testimonials-content-element-three .testimonials-author .author-name span {
  font-size: 14px;
  line-height: 1;
}

.testimonials-content-element-three .testimonials-author .author-name span.author {
  font-weight: 600;
}

/*=========================================
=          22. Team Css               =
===========================================*/
.single-team-wrap .team-image-box {
  display: block;
  position: relative;
}

.single-team-wrap .team-image-box .team-image {
  display: block;
}

.single-team-wrap .team-image-box .team-image img {
  width: 100%;
}

.single-team-wrap .team-image-box .default-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.single-team-wrap .team-image-box .default-overlay::after {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(66, 65, 255, 0.9);
  content: '';
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.single-team-wrap .team-image-box .black-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.single-team-wrap .team-image-box .black-overlay::after {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  content: '';
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.single-team-wrap .team-image-box .team-link-icon {
  position: absolute;
  bottom: -10%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
  width: 100%;
  text-align: center;
}

.single-team-wrap .team-image-box .team-link-icon .icon-box {
  background: transparent;
  width: 38px;
  height: 38px;
  line-height: 38px;
  color: #fff;
  margin: 5px;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  border: 1px solid #fff;
}

.single-team-wrap .team-image-box .team-link-icon .icon-box:hover {
  background: #4241ff;
}

.single-team-wrap .team-member__content h6 {
  text-transform: uppercase;
}

.single-team-wrap .team-member__content .author {
  font-size: 12px;
}

.single-team-wrap .team-member__content .team-member__desc {
  font-size: 16px;
  line-height: 2em;
}

.single-team-wrap .team-member__content .boder-inner {
  position: relative;
}

.single-team-wrap .team-member__content .boder-inner::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 40px;
  height: 4px;
  background: #ddd;
}

.single-team-wrap .team-member__content .team-member__position span {
  font-size: 14px;
}

.single-team-wrap:hover .default-overlay::after,
.single-team-wrap:hover .black-overlay::after {
  opacity: 1;
}

.single-team-wrap:hover .team-link-icon {
  opacity: 1;
  visibility: visible;
  bottom: 0%;
}

.single-team--two {
  margin-bottom: 40px;
  overflow: hidden;
}

.single-team--two .team-image-box {
  display: block;
  position: relative;
}

.single-team--two .team-image-box .team-image {
  display: block;
  filter: grayscale(100);
}

.single-team--two .team-image-box .team-member__position {
  position: absolute;
  bottom: 0;
  background: #4241ff;
  padding: 6px 15px;
  left: -100px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.single-team--two .team-image-box .team-member__position span {
  color: #fff;
}

.single-team--two .team-member__content .team-member__desc {
  font-size: 16px;
  line-height: 2em;
}

.single-team--two:hover .team-image-box .team-image {
  filter: grayscale(0);
}

.single-team--two:hover .team-member__position {
  left: 0;
  opacity: 1;
}

.single-team--three .team-image-box {
  display: block;
  position: relative;
}

.single-team--three .team-image-box .team-image {
  display: block;
  filter: grayscale(100);
}

.single-team--three .team-image-box .team-image img {
  width: 100%;
}

.single-team--three .team-image-box .default-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.single-team--three .team-image-box .default-overlay::after {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  transform: scale(0.95);
}

.single-team--three .team-image-box .team-link-icon {
  position: absolute;
  bottom: -10%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
  width: 100%;
  text-align: center;
}

.single-team--three .team-image-box .team-link-icon .icon-box {
  background: transparent;
  width: 38px;
  height: 38px;
  line-height: 38px;
  color: #fff;
  margin: 5px;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  border: 1px solid #fff;
}

.single-team--three .team-image-box .team-link-icon .icon-box:hover {
  background: #4241ff;
  border-color: #4241ff;
}

.single-team--three .team-image-box .team-member__content {
  position: absolute;
  bottom: 12%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
  width: 100%;
  text-align: center;
  opacity: 0;
  overflow: hidden;
}

.single-team--three .team-image-box .team-member__content h6 {
  text-transform: uppercase;
  color: #fff;
}

.single-team--three .team-image-box .team-member__content .team-member__position {
  font-size: 16px;
  line-height: 2em;
}

.single-team--three .team-image-box .team-member__content .team-member__position span {
  color: #fff;
}

.single-team--three:hover .team-image-box .team-image {
  filter: grayscale(0);
}

.single-team--three:hover .default-overlay::after {
  opacity: 1;
  transform: scale(1);
}

.single-team--three:hover .team-member__content {
  opacity: 1;
  visibility: visible;
}

.single-team--three:hover .team-member__content h6 {
  animation-duration: .5s;
  animation-name: m1ServicesIn;
}

.single-team--three:hover .team-member__content .team-member__position {
  animation-duration: .6s;
  animation-name: m1ServicesIn;
}

.single-team--three:hover .team-link-icon {
  opacity: 1;
  visibility: visible;
  bottom: 0%;
}

.single-agents {
  margin-top: 30px;
}

.single-agents .agents-image {
  display: block;
  position: relative;
}

.single-agents .agents-image::after {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(117, 173, 28, 0.9);
  content: '';
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.single-agents:hover .agents-image::after {
  opacity: 1;
}

.single-agents .agents-content {
  padding: 30px;
  background-color: #fff;
}

.single-agents .agents-content .title {
  font-weight: 600;
  margin-bottom: 6px;
}

.single-agents .agents-content .team-member__position {
  color: #75ad1c;
  font-size: 12px;
}

.single-agents .agents-content .team-member__contact-info li {
  margin-top: 10px;
}

.single-agents .agents-content .team-member__contact-info li i {
  height: 30px;
  width: 30px;
  border: 1px solid #ddd;
  line-height: 30px;
  text-align: center;
  border-radius: 5000px;
}

.single-agents .agents-content .team-member__contact-info li span {
  margin-left: 10px;
}

.single-agents .agents-content .member-social {
  margin-top: 20px;
}

.single-agents .agents-content .member-social a {
  margin-right: 15px;
}

.single-agents .agents-content .member-social a:hover {
  color: #75ad1c;
}

.agents-slider-1 .slick-dots {
  margin-top: 30px;
  text-align: center;
}

.agents-slider-1 .slick-dots li {
  display: inline-block;
  margin: 5px;
}

.agents-slider-1 .slick-dots li button {
  background: #ddd;
  font-size: 0;
  border: none;
  width: 13px;
  height: 13px;
  border-radius: 100%;
}

.agents-slider-1 .slick-dots li.slick-active button {
  background: #75ad1c;
}

/*=========================================
=          23. Counter Css               =
===========================================*/
.counter__style--1 {
  margin-top: 30px;
}

.counter__style--1 .icon {
  color: #4241ff;
  margin-bottom: 20px;
}

.counter__style--1 .icon i {
  width: 1em;
  height: 1em;
  font-size: 60px;
}

.counter__style--1 .content .title {
  color: #2f2f2f;
  font-size: 32px;
  line-height: 1.4em;
}

.counter__style--1 .content .title2 {
  font-size: 32px;
  line-height: 1.4em;
}

.counter__style--2 {
  margin-top: 30px;
}

.counter__style--2 .counter-text {
  font-weight: 400;
}

.counter__style--2 .title {
  color: #666;
  font-weight: 300;
  line-height: 1.5;
}

.counter__style--3 {
  margin-top: 30px;
}

.counter__style--3 .title {
  color: #666;
  font-weight: 300;
  line-height: 1.5;
}

.counter__style--3 .counter-text {
  font-weight: 400;
  position: relative;
  display: inline-block;
  padding: 0 20px;
}

.counter__style--3 .counter-text::before {
  position: absolute;
  content: "~";
  left: 0;
}

.counter__style--4 {
  margin-top: 30px;
}

.counter__style--4 .title {
  color: #666;
  line-height: 2.5;
}

.counter__style--4 .counter-text {
  font-weight: 400;
  position: relative;
  display: inline-block;
  padding: 0 20px;
}

.counter__style--4 .counter-text::before {
  position: absolute;
  content: "+";
  right: 0;
}

.counterbox {
  display: flex;
  align-items: center;
  padding: 50px;
}

.counterbox-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.counterbox-block {
  width: 50%;
  min-height: 190px;
  display: flex;
  padding: 0px;
  margin: 0px;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  justify-content: center;
  align-items: center;
}

.counterbox-block a:hover {
  color: #4241ff;
}

.counterbox-block h2 {
  margin-bottom: 10px;
  line-height: 1;
}

.counterbox-block h6 {
  font-size: 16px;
  margin-bottom: 0;
}

.counterbox-block:last-child {
  border-right: none;
  border-bottom: 0;
}

.counterbox-block:nth-child(2) {
  border-right: none;
}

.counterbox-block:nth-child(3) {
  border-bottom: none;
}

.fun-fact-style-one .single-fun-fact {
  text-align: center;
}

.fun-fact--one {
  margin: 20px 0;
}

.fun-fact__count {
  font-weight: 600;
  color: #ffffff;
  font-size: 46px;
}

.fun-fact__text {
  color: #ffffff;
  font-size: 18px;
}

/*=========================================
=          24. Brand Area Css               =
===========================================*/
.brand-wrap {
  align-items: center;
}

.brand-wrap .single-brand-wrap {
  text-align: center;
  margin-bottom: 50px;
  filter: grayscale(100);
  -webkit-filter: grayscale(100);
  opacity: .7;
  transition: all .25s ease-out;
}

.brand-wrap .single-brand-wrap:hover {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
}

.brand-logo-two .single-brand-wrap {
  text-align: center;
}

/*=========================================
=          25. Product Area Css               =
===========================================*/
.ht-tab-menu-three li {
  margin-bottom: 10px;
}

.ht-tab-menu-three li a {
  text-align: center;
  color: #212121;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  padding: 20px 40px;
  margin: 0 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #dedede;
}

.ht-tab-menu-three li a.active, .ht-tab-menu-three li a:hover {
  background: #ea622b;
  color: #ffffff;
  border: 1px solid #ea622b;
}

@media only screen and (max-width: 767px) {
  .ht-tab-menu-three li a {
    padding: 14px 20px;
  }
}

.single-product-item {
  margin-top: 40px;
}

.single-product-item .prodict-image {
  display: block;
}

.single-product-item .prodict-image img {
  width: 100%;
}

.single-product-item .prodict-image.overlay-black {
  position: relative;
}

.single-product-item .prodict-image.overlay-black::after {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  content: '';
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.single-product-item .product-image-wrap {
  position: relative;
}

.single-product-item .product-action {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 2;
  text-align: center;
}

.single-product-item .product-action a {
  background-color: #fff;
  color: #212121;
  border-radius: 50%;
  padding: 0;
  position: relative;
  margin: 5px;
  opacity: 0;
  -webkit-transform: translate(0, 50px);
  transform: translate(0, 50px);
  transition-delay: 100ms;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  font-size: 22px;
}

.single-product-item .product-action a + a {
  transition-delay: 150ms;
}

.single-product-item .product-action a .tooltip {
  visibility: hidden;
  position: absolute;
  background-color: #fff;
  text-align: center;
  padding: 5px 12px;
  z-index: 3;
  opacity: 0;
  transition: opacity .6s, margin .3s;
  white-space: pre;
  font-size: 11px;
  line-height: 14px;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  color: #333;
}

.single-product-item .product-action a .tooltip::after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  content: "";
  height: 0;
  position: absolute;
  bottom: 10px;
  left: 50%;
  width: 0;
  bottom: -6px;
  transform: translateX(-50%) rotate(90deg);
}

.single-product-item .product-action a:hover {
  background: #ea622b;
  color: #ffffff;
}

.single-product-item .product-action a:hover .tooltip {
  visibility: visible;
  opacity: 1;
  top: -35px;
}

.single-product-item .prodect-content {
  margin-top: 15px;
}

.single-product-item .prodect-content h6 {
  font-size: 16px;
  margin: 0 0 5px;
  line-height: inherit;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.single-product-item .prodect-content .prodect-price {
  font-weight: 600;
}

.single-product-item .prodect-content-absolut {
  position: absolute;
  bottom: 0;
  transform: translate(0, 50px);
  transition-delay: 100ms;
  opacity: 0;
  padding: 20px 30px;
  transition: all 0.3s ease-in-out;
}

.single-product-item .prodect-content-absolut h6 {
  font-size: 16px;
  margin: 0 0 2px;
  line-height: inherit;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
}

.single-product-item .prodect-content-absolut h6 a {
  color: #fff;
}

.single-product-item .prodect-content-absolut .prodect-price {
  font-weight: 600;
}

.single-product-item .prodect-content-absolut .prodect-price .new-price {
  color: #ffffff;
}

.single-product-item:hover a {
  opacity: 1;
  transform: translate(0, 0);
}

.single-product-item:hover .overlay-black::after {
  opacity: 1;
}

.single-product-item:hover .prodect-content-absolut {
  transform: translate(0, 0);
  opacity: 1;
}

.single-product-instagram {
  margin-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-product-instagram {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .single-product-instagram {
    margin-bottom: 30px;
  }
}

.single-product-instagram .prodict-image {
  display: block;
}

.single-product-instagram .prodict-image img {
  width: 100%;
}

.single-product-instagram .prodict-image.overlay-default {
  position: relative;
}

.single-product-instagram .prodict-image.overlay-default::after {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(66, 65, 255, 0.9);
  height: 100%;
  width: 100%;
  content: '';
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.single-product-instagram .product-instagram__meta {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 2;
  text-align: center;
  color: #ffffff;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.single-product-instagram .product-instagram__meta .icon-list {
  display: inline-block;
  margin: 5px;
}

.single-product-instagram .product-instagram__meta .icon-list i {
  margin-right: 3px;
}

.single-product-instagram:hover .product-instagram__meta {
  opacity: 1;
}

.single-product-instagram:hover .overlay-default::after {
  opacity: 1;
}

.product-list-item {
  margin-top: 40px;
}

.product-list-item .single-product-item {
  margin-top: 0px;
}

.product-list-item .prodect-content {
  margin-top: 0px;
}

@media only screen and (max-width: 767px) {
  .product-list-item .prodect-content {
    margin-top: 30px;
  }
}

.product-list-item .prodect-content .prodect-price {
  margin-top: 15px;
  margin-bottom: 15px;
}

.product-list-item .prodect-content .product-action a {
  margin-right: 20px;
}

.product-list-item .prodect-content .product-action a i {
  font-size: 20px;
}

.product-list-item .prodect-content p {
  font-size: 14px;
}

.modal-dialog {
  margin: 5% auto 8%;
  max-width: 1358px;
  width: 1358px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .modal-dialog {
    width: 1300px;
    max-width: 1300px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .modal-dialog {
    width: 1150px;
    max-width: 1150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modal-dialog {
    width: 960px;
    max-width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal-dialog {
    width: 720px;
    max-width: 720px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-dialog {
    width: 100%;
    max-width: 100%;
    padding: 35px 0;
  }
}

.modal-dialog .modal-content {
  border-radius: 0;
}

.modal-dialog .modal-content .modal-header {
  border: none;
  padding: 0;
  right: -13px;
  top: -31px;
  position: absolute;
  z-index: 9999;
}

@media only screen and (max-width: 767px) {
  .modal-dialog .modal-content .modal-header {
    right: 0px;
    top: -10px;
  }
}

.modal-dialog .modal-content .modal-header .close {
  color: #fff;
  cursor: pointer;
  opacity: 1;
  padding: 0;
  margin: 0;
  width: 54px;
  height: 54px;
  line-height: 60px;
  background-color: #292929;
  border-radius: 100%;
  font-size: 18px;
  text-shadow: none;
}

@media only screen and (max-width: 767px) {
  .modal-dialog .modal-content .modal-header .close {
    width: 34px;
    height: 34px;
    line-height: 34px;
    font-size: 15px;
  }
}

.modal-dialog .modal-content .modal-header .close:hover {
  color: #4241ff;
}

.modal-dialog .modal-content .modal-body {
  padding: 0px;
  overflow-y: auto;
  max-height: 562px;
}

.quickview-content-wrap {
  padding: 42px 35px 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .quickview-content-wrap {
    padding: 15px 35px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quickview-content-wrap {
    padding: 20px 35px 35px;
  }
}

@media only screen and (max-width: 767px) {
  .quickview-content-wrap {
    padding: 15px 15px 15px;
  }
}

.quickview-content-wrap h2 {
  font-size: 26px;
  font-weight: 600;
  margin: 0 0 2px;
}

@media only screen and (max-width: 767px) {
  .quickview-content-wrap h2 {
    font-size: 26px;
  }
}

/*=========================================
=       26. Special Offer Area Css          =
===========================================*/
.special-offer-area {
  background: #e3e6e4;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .countdown-area {
    margin-top: 30px;
  }
}

.counter-style--one {
  display: flex;
  justify-content: center;
}

.counter-style--one .single-countdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
}

.counter-style--one .single-countdown__time {
  font-size: 46px;
  line-height: 1em;
}

.counter-style--one .single-countdown__text {
  font-size: 16px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-style--one .single-countdown {
    padding: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter-style--one .single-countdown {
    padding: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .counter-style--one .single-countdown {
    padding: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .counter-style--one .single-countdown {
    padding: 10px;
  }
  .counter-style--one .single-countdown__time {
    font-size: 20px;
  }
}

.spacel-prodect-price .old-price {
  color: #212121;
  font-size: 24px;
  text-decoration: line-through;
  line-height: 1.2em;
  text-align: center;
  display: block;
}

.spacel-prodect-price .new-price {
  color: #212121;
  font-size: 36px;
  font-weight: 700;
  font-style: italic;
  line-height: 1.2em;
  text-align: center;
  display: block;
}

/*=========================================
=       27. Newsletter Area Css          =
===========================================*/
.newsletter-box-one input {
  border: none;
  border-bottom: 2px solid #111;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  color: #111;
  position: relative;
  padding: 10px 0;
}

.newsletter-box-one input:focus {
  border-color: #111;
  color: #111;
}

.newsletter-box-one .submit-newsletter-btn {
  border: none;
  background: transparent;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 26px;
  background-color: rgba(0, 0, 0, 0);
  color: #212121;
  padding: 15px 0 13px;
}

.newsletter-box-three--inner {
  position: relative;
}

.newsletter-box-three--inner input {
  padding: 25px 125px 25px 30px;
  color: #fff;
  font-size: 18px;
  line-height: 26px;
  width: 100%;
  border: 2px solid #ddd;
  background: transparent;
}

.newsletter-box-three--inner input::placeholder {
  color: #ffffff;
}

.newsletter-box-three--inner input:focus {
  border: 2px solid #ddd;
  background: transparent;
  color: #fff;
}

.newsletter-box-three--inner .submit-btn {
  position: absolute;
  right: 0;
  top: 0;
  padding: 25px 25px 25px 30px;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.newsletter-box-three--inner .submit-btn:hover {
  color: #ea622b;
}

.single-order-element {
  position: relative;
  margin-top: 60px;
}

.single-order-element .order-element-icon {
  height: 46px;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.single-order-element .order-element-content {
  position: relative;
}

.single-order-element .order-element-content h6 {
  margin-bottom: 10px;
}

.single-order-element .order-element-content p {
  font-size: 18px;
  max-width: 250px;
  margin: auto;
}

.single-order-element .order-element-content::after {
  position: absolute;
  right: -25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "";
  color: #333;
  height: 50px;
  width: 1px;
  background: #333;
}

@media only screen and (max-width: 767px) {
  .single-order-element .order-element-content::after {
    display: none;
  }
}

.col-lg-3:last-child .single-order-element .order-element-content::after {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-lg-3:nth-child(2) .single-order-element .order-element-content::after {
    display: none;
  }
}

/*=========================================
=       28. Properties Area Css          =
===========================================*/
.ht-tab-menu-one li a {
  text-align: center;
  font-size: 32px;
  font-weight: 400;
  line-height: 1.4em;
  letter-spacing: -2px;
  padding: 0 30px;
}

.ht-tab-menu-one li a.active {
  color: #4241ff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ht-tab-menu-one li a {
    font-size: 28px;
    padding: 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .ht-tab-menu-one li a {
    font-size: 24px;
    padding: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .ht-tab-menu-one li a {
    font-size: 22px;
    padding: 0 15px;
    margin-bottom: 5px;
  }
}

.ht-tab-menu-one button {
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.4em;
  padding: 0 20px;
  background: transparent;
  border: none;
}

.ht-tab-menu-one button.is-checked {
  color: #4241ff;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .ht-tab-menu-one button {
    padding: 0 12px;
  }
}

.ht-tab-preview-menu button {
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.4em;
  padding: 3px 0px;
  margin: 5px 20px 0;
  background: transparent;
  border: none;
  border-bottom: 4px solid transparent;
}

.ht-tab-preview-menu button.is-checked {
  border-bottom: 4px solid #4241ff;
}

.single-properties .properties-images {
  display: block;
  position: relative;
}

.single-properties .listing__content-types {
  position: absolute;
  bottom: 0;
  left: 0;
}

.single-properties .listing__content-types .job-type {
  background-color: #3377fa;
  font-family: sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  font-size: 12px;
  padding: 7px 15px 5px;
  vertical-align: middle;
  line-height: 20px;
  color: #fff;
}

.single-properties .listing__content-types .job-type.for-sale {
  background-color: #ea622b;
}

.properties-content {
  padding: 40px 30px;
  background: #212121;
}

.properties-content .properties-location {
  color: #ffffff;
}

.properties-content .properties-location i {
  color: #ea622b;
}

.properties-content .properties-price {
  color: #75ad1c;
  font-size: 28px;
  letter-spacing: -1px;
  margin: 0 0 5px;
}

.properties-content .listing__content-metaitem {
  display: inline-block;
  margin-right: 30px;
}

.properties-content .listing__content-metaitem i {
  color: #ea622b;
}

.properties-content .listing__content-metaitem .listing__content-metavalue {
  color: #ffffff;
  margin-left: 5px;
}

@media only screen and (max-width: 575px) {
  .properties-content .listing__content-metaitem {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .properties-content {
    padding: 30px 20px;
  }
}

.apps-image-01 {
  height: 400px;
  width: auto;
  background-size: cover;
}

.apps-img--1 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.apps-img--1::before {
  padding-top: 67.6%;
  content: '';
  display: block;
}

/*=============================================
=            29. Accordions Css           =
=============================================*/
.faq-wrapper .panel.panel-default {
  border: 1px solid #ebebeb;
  margin-bottom: 20px;
}

.faq-wrapper .card, .faq-wrapper .card-header {
  border-radius: 0;
}

.faq-wrapper .card {
  border-bottom: none;
  margin-bottom: 20px;
  border: none;
  border: 1px solid #ebebeb;
}

.faq-wrapper .card:last-child {
  margin-bottom: 0;
}

.faq-wrapper .card-header {
  padding: 0;
  border-bottom: 0;
  background-color: #ffffff;
}

.faq-wrapper .card-header h5 button {
  border: none;
  width: 100%;
  text-align: left;
  color: #4241ff;
  padding: 20px 30px;
  position: relative;
  overflow-wrap: break-word;
  white-space: normal;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
  border-radius: 5px;
  background: #ffffff;
}

@media only screen and (max-width: 479px) {
  .faq-wrapper .card-header h5 button {
    padding-left: 15px;
    padding-right: 20px;
  }
}

.faq-wrapper .card-header h5 button span {
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 479px) {
  .faq-wrapper .card-header h5 button span {
    right: 15px;
  }
}

.faq-wrapper .card-header h5 button span i {
  font-size: 14px;
  visibility: hidden;
  opacity: 0;
  display: none;
}

.faq-wrapper .card-header h5 button:hover, .faq-wrapper .card-header h5 button:focus {
  text-decoration: none;
  color: #000000;
}

.faq-wrapper .card-header h5 button[aria-expanded="true"] {
  color: #333;
  background: #ffffff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.faq-wrapper .card-header h5 button[aria-expanded="true"] span i:nth-child(2) {
  visibility: visible;
  opacity: 1;
  display: inline;
}

.faq-wrapper .card-header h5 button[aria-expanded="true"]:before {
  height: 100%;
}

.faq-wrapper .card-header h5 button[aria-expanded="false"] span i:nth-child(1) {
  visibility: visible;
  opacity: 1;
  display: inline;
}

.faq-wrapper .card-body {
  padding: 10px 30px 29px;
  font-size: 14px;
}

/*=============================================
=            30. Pricing table Css           =
==============================================*/
.pricing-table-wrapper {
  margin-bottom: -30px;
}

.pricing-table-one .pricing-table {
  margin-bottom: 30px;
}

.pricing-table-one .pricing-table__inner {
  position: relative;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  text-align: center;
  padding: 34px 40px 40px;
  border: 1px solid #ddd;
  border-radius: 0px;
  background-color: #ffffff;
}

.pricing-table-one .pricing-table__header {
  margin-bottom: 25px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ddd;
}

.pricing-table-one .pricing-table__title {
  margin-bottom: 10px;
}

.pricing-table-one .pricing-table__price-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.pricing-table-one .pricing-table__price-wrap .price {
  font-size: 30px;
  line-height: .8;
  margin: 0 4px;
  font-weight: 700;
  color: #333;
}

.pricing-table-one .pricing-table__price-wrap .period {
  font-size: 15px;
  margin-bottom: 0;
  color: #ababab;
  align-self: flex-end;
}

.pricing-table-one .pricing-table__list li {
  position: relative;
  display: block;
  padding: 5px 0;
}

.pricing-table-one .pricing-table__list li .featured {
  font-weight: 500;
}

.pricing-table-one .pricing-table__footer {
  margin-top: 30px;
}

.pricing-table-one .pricing-table__footer .ht-btn {
  width: 180px;
  max-width: 100%;
}

.pricing-table-one .pricing-table__footer .ht-btn:hover {
  background: #4241ff;
}

.pricing-table-one .pricing-table__feature-mark {
  position: absolute;
  top: 0;
  right: 1px;
  height: auto;
}

.pricing-table-two .pricing-table {
  margin-bottom: 30px;
}

.pricing-table-two .pricing-table__inner {
  position: relative;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 34px 40px 40px;
  border: 1px solid #ddd;
  border-radius: 0px;
  background-color: #ffffff;
}

.pricing-table-two .pricing-table__header {
  margin-bottom: 25px;
}

.pricing-table-two .pricing-table__title {
  margin-bottom: 10px;
}

.pricing-table-two .pricing-table__price-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.pricing-table-two .pricing-table__price-wrap .currency {
  font-size: 15px;
  margin-bottom: 0;
  color: #ababab;
}

.pricing-table-two .pricing-table__price-wrap .price {
  font-size: 50px;
  line-height: .8;
  margin: 0 4px;
  font-weight: 700;
}

.pricing-table-two .pricing-table__price-wrap .period {
  font-size: 15px;
  margin-bottom: 0;
  color: #ababab;
  align-self: flex-end;
}

.pricing-table-two .pricing-table p {
  font-size: 14px;
}

.pricing-table-two .pricing-table__list li {
  position: relative;
  display: block;
  padding: 7px 0;
}

.pricing-table-two .pricing-table__list li i {
  margin-right: 10px;
  color: #4241ff;
}

.pricing-table-two .pricing-table__list li .featured {
  font-weight: 700;
}

.pricing-table-two .pricing-table__list li .featured.item-excluded:after {
  content: 'hot';
  background-color: #ea622b;
  color: #fff;
  font-size: 11px;
  padding: 3px 8px;
  position: absolute;
  line-height: 1;
  top: -8px;
}

.pricing-table-two .pricing-table__list li .featured.item-new:after {
  content: 'new';
  background-color: green;
  color: #fff;
  font-size: 11px;
  padding: 3px 8px;
  position: absolute;
  line-height: 1;
  top: -8px;
}

.pricing-table-two .pricing-table__footer {
  margin-top: 30px;
}

.pricing-table-two .pricing-table__footer .ht-btn {
  width: 180px;
  max-width: 100%;
}

.pricing-table-two .pricing-table__footer .ht-btn:hover {
  background: #4241ff;
}

.pricing-table-two .pricing-table__feature-mark {
  position: absolute;
  top: 0;
  right: 1px;
  height: auto;
}

.pricing-table-three .pricing-table {
  margin-bottom: 30px;
}

.pricing-table-three .pricing-table__inner {
  position: relative;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 34px 40px 40px;
  border: 1px solid #ddd;
  border-radius: 0px;
  background-color: #ffffff;
  text-align: center;
}

.pricing-table-three .pricing-table__header {
  margin-bottom: 25px;
}

.pricing-table-three .pricing-table__title {
  margin-bottom: 10px;
}

.pricing-table-three .pricing-table__icon-box * {
  max-width: 140px;
  max-height: 140px;
  margin: auto;
}

.pricing-table-three .pricing-table__price-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 20px 0;
}

.pricing-table-three .pricing-table__price-wrap .currency {
  font-size: 15px;
  margin-bottom: 0;
  color: #ababab;
}

.pricing-table-three .pricing-table__price-wrap .price {
  font-size: 50px;
  line-height: .8;
  margin: 0 4px;
  font-weight: 700;
}

.pricing-table-three .pricing-table__price-wrap .period {
  font-size: 15px;
  margin-bottom: 0;
  color: #ababab;
  align-self: flex-end;
}

.pricing-table-three .pricing-table p {
  font-size: 14px;
}

.pricing-table-three .pricing-table__list li {
  position: relative;
  display: block;
  padding: 7px 0;
}

.pricing-table-three .pricing-table__list li i {
  margin-right: 10px;
  color: #4241ff;
}

.pricing-table-three .pricing-table__list li .featured {
  font-weight: 700;
}

.pricing-table-three .pricing-table__list li .featured.item-excluded:after {
  content: 'hot';
  background-color: #ea622b;
  color: #fff;
  font-size: 11px;
  padding: 3px 8px;
  position: absolute;
  line-height: 1;
  top: -8px;
}

.pricing-table-three .pricing-table__list li .featured.item-new:after {
  content: 'new';
  background-color: green;
  color: #fff;
  font-size: 11px;
  padding: 3px 8px;
  position: absolute;
  line-height: 1;
  top: -8px;
}

.pricing-table-three .pricing-table__footer {
  margin-top: 30px;
}

.pricing-table-three .pricing-table__footer .ht-btn {
  width: 180px;
  max-width: 100%;
}

.pricing-table-three .pricing-table__footer .ht-btn:hover {
  background: #4241ff;
}

.pricing-table-three .pricing-table__feature-mark {
  position: absolute;
  top: 0;
  right: 1px;
  height: auto;
}

/*=====  End of pricing table  ======*/
/*=============================================
=            31. Coming Soon Css           =
==============================================*/
.coming-soon-page-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-page-wrapper {
    height: 100%;
  }
}

.bg-yollo {
  background: #fcd54f;
}

.counter-coming-soon {
  display: flex;
  justify-content: flex-start;
}

.counter-coming-soon .single-countdown {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 30px;
}

.counter-coming-soon .single-countdown__time {
  font-size: 36px;
  line-height: 1em;
  font-weight: 600;
}

.counter-coming-soon .single-countdown__text {
  font-size: 16px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-coming-soon .single-countdown {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter-coming-soon .single-countdown {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .counter-coming-soon .single-countdown {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .counter-coming-soon .single-countdown {
    margin-right: 20px;
  }
  .counter-coming-soon .single-countdown__time {
    font-size: 20px;
  }
}

.counter-coming-soon.timer-white {
  color: #fff;
}

.newsletter-box-coming-soon {
  position: relative;
}

.newsletter-box-coming-soon input {
  border: none;
  border-bottom: 2px solid #111;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: #111;
  position: relative;
  padding: 10px 100px 10px 0;
  background: transparent;
}

.newsletter-box-coming-soon input:focus {
  border-color: #111;
  color: #111;
}

.newsletter-box-coming-soon .submit-newsletter-btn {
  border: none;
  background: transparent;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0);
  color: #212121;
  padding: 15px 0 13px;
  font-weight: 700;
}

.newsletter-box-coming-soon--white input {
  border-bottom: 2px solid #fff;
  color: #fff;
}

.newsletter-box-coming-soon--white input:focus {
  border-color: #fff;
  color: #fff;
}

.newsletter-box-coming-soon--white .submit-newsletter-btn {
  color: #fff;
}

/*=============================================
=            32. Career Css           =
==============================================*/
.single-featured-jobs {
  border: 1px solid #ddd;
  padding: 30px;
  background: #fff;
}

.single-featured-jobs .job-type {
  margin-top: 10px;
  font-size: 14px;
}

.single-featured-jobs .job-type a {
  color: #4241ff;
}

.single-featured-jobs .icon-list-items {
  margin-top: 25px;
}

.single-featured-jobs .icon-list-items .icon-list-item {
  font-size: 14px;
  display: flex;
  margin-bottom: 20px;
}

.single-featured-jobs .icon-list-items .icon-list-item .list-icon {
  margin-top: 3px;
  font-size: 10px;
  color: #4241ff;
  margin-right: 10px;
}

/*=============================================
=            33. Listing style Css           =
==============================================*/
.list-style--default li {
  font-size: 14px;
  margin-bottom: 10px;
}

.list-style--default li:last-child {
  margin-bottom: 0;
}

.list-style--default li i {
  margin-right: 5px;
}

.list-style--divider li {
  font-size: 14px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.list-style--divider li:last-child {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.list-style--divider li i {
  margin-right: 5px;
}

.list-style--dpace-between_lg​ li {
  font-size: 14px;
  margin-bottom: 20px;
}

.list-style--dpace-between_lg​ li:last-child {
  margin-bottom: 0;
}

.list-style--dpace-between_lg​ li i {
  margin-right: 5px;
}

.list-style--dpace-between_md li {
  font-size: 14px;
  margin-bottom: 14px;
}

.list-style--dpace-between_md li:last-child {
  margin-bottom: 0;
}

.list-style--dpace-between_md li i {
  margin-right: 5px;
}

.list-style--dpace-between_sm li {
  font-size: 14px;
  margin-bottom: 8px;
}

.list-style--dpace-between_sm li:last-child {
  margin-bottom: 0;
}

.list-style--dpace-between_sm li i {
  margin-right: 5px;
}

.list-style--text-color li {
  font-size: 14px;
  margin-bottom: 10px;
  color: #4241ff;
}

.list-style--text-color li:last-child {
  margin-bottom: 0;
}

.list-style--text-color li i {
  margin-right: 5px;
  color: #222;
}

.list-style--icon-color li {
  font-size: 14px;
  margin-bottom: 10px;
}

.list-style--icon-color li:last-child {
  margin-bottom: 0;
}

.list-style--icon-color li i {
  margin-right: 5px;
  color: #4241ff;
}

.list-style--color-full li {
  font-size: 14px;
  margin-bottom: 10px;
  color: #4241ff;
}

.list-style--color-full li:last-child {
  margin-bottom: 0;
}

.list-style--color-full li i {
  margin-right: 5px;
}

/*=================================
=        34. Toggle Style Css
===================================*/
.toggle_style--1 .card {
  border: 0 none;
  margin-bottom: 7px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-bottom: 1px solid #d4d4d4;
  border-radius: 0;
  background: transparent;
}

.toggle_style--1 .card-header {
  position: relative;
  background: transparent;
  padding: 0;
  border: 0 none;
}

.toggle_style--1 .card-header h5 a.acc-btn {
  color: #535353;
  font-weight: 700;
  line-height: 1;
  border: 0 none;
  -webkit-appearance: none;
  display: block;
  position: relative;
  font-size: 14px;
  line-height: 1.2;
  padding: 15px;
}

.toggle_style--1 .card-header h5 a.acc-btn::before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "\f0da";
  left: 0;
  font-size: 14px;
  font-family: FontAwesome;
  width: 1em;
}

.toggle_style--1 .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: transparent;
  color: #f53e6a;
}

.toggle_style--1 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
  content: "\f0d8";
}

.toggle_style--1 .card-body {
  padding: 15px;
  color: #535353;
  font-size: 14px;
  line-height: 1.714286;
  font-weight: 400;
}

.toggle_style--1.toggle-icon .card-header h5 a.acc-btn::before {
  content: "\f152";
}

.toggle_style--1.toggle-icon .card-header h5 a.acc-btn[aria-expanded="true"]::before {
  content: "\f150";
}

.toggle_style--1.space-between .card {
  border-bottom: 1px solid #f53e6a;
}

.toggle_style--1.space-between .card-header h5 a.acc-btn {
  padding-left: 23px;
}

.toggle_style--1.space-between .card-header h5 a.acc-btn::before {
  content: "\ecdd";
  font-family: 'dliconoutline';
}

.toggle_style--1.space-between .card-header h5 a.acc-btn[aria-expanded="true"]::before {
  content: "\ecde";
}

.toggle_style--1.icon-right .card {
  border-bottom: 1px solid #f53e6a;
}

.toggle_style--1.icon-right .card-header h5 a.acc-btn::before {
  left: auto;
  right: 0;
}

.toggle_style--2 .card {
  border: 0 none;
  margin-bottom: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border: 1px solid #d4d4d4;
  border-radius: 0;
  background: transparent;
}

.toggle_style--2 .card-header {
  position: relative;
  background: transparent;
  padding: 0;
  border: 0 none;
}

.toggle_style--2 .card-header h5 {
  font-family: "Poppins", sans-serif;
}

.toggle_style--2 .card-header h5 a.acc-btn {
  color: #2f2f2f;
  font-weight: 400;
  line-height: 1;
  border: 0 none;
  -webkit-appearance: none;
  display: block;
  position: relative;
  font-size: 18px;
  line-height: 1.2;
  padding: 30px;
  margin-left: 10px;
}

.toggle_style--2 .card-header h5 a.acc-btn::before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "\eac2";
  left: 6px;
  font-size: 18px;
  font-family: 'dliconoutline';
  width: 1em;
}

.toggle_style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: transparent;
  color: #f53e6a;
}

.toggle_style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
  content: "\eac5";
}

.toggle_style--2 .card-body {
  background-color: #fff;
  color: #2f2f2f;
  padding: 10px 30px 30px;
  font-size: 16px;
  line-height: 2.25;
}

.tab-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
}

/*=====================================
=           35. Progress Bar           =
=====================================*/
.progress-charts {
  overflow: hidden;
}

.progress-charts h6.heading {
  margin-bottom: 13px;
  text-transform: uppercase;
}

.progress-charts h6.heading span.percent-label {
  color: #222;
  font-weight: 600;
}

.progress-charts h6.custom-color--2 {
  color: #000;
}

.progress-charts h6.custom-color--3 {
  color: #66a7e8;
}

.progress-charts h6.custom-color--4 {
  color: #f10;
}

.progress-charts h6.custom-color--5 {
  color: #23a455;
}

.progress-charts h6.custom-color--6 {
  color: #a0a0a0;
}

.progress-charts h6.custom-color--7 {
  color: #000;
}

.progress-charts h6.custom-color--8 {
  color: #7971ea;
}

.progress-charts .progress {
  height: 6px;
  overflow: visible;
  font-size: 14px;
  background-color: #eee;
  border-radius: 0;
}

.progress-charts .progress--4 {
  height: 4px;
}

.progress-charts .progress--8 {
  height: 8px;
}

.progress-charts .progress--10 {
  height: 10px;
}

.progress-charts .progress.bg-color-1 {
  background-color: rgba(102, 167, 232, 0.3);
}

.progress-charts .progress.bg-color-2 {
  background-color: rgba(244, 0, 61, 0.3);
}

.progress-charts .progress.bg-color-3 {
  background-color: rgba(35, 164, 85, 0.3);
}

.progress-charts .progress .progress-bar {
  overflow: visible;
  position: relative;
  background: #4241ff;
}

.progress-charts .progress .progress-bar.custom-color--2 {
  background-color: #000;
}

.progress-charts .progress .progress-bar.custom-color--3 {
  background-color: #66a7e8;
}

.progress-charts .progress .progress-bar.custom-color--4 {
  background-color: #f10;
}

.progress-charts .progress .progress-bar.custom-color--5 {
  background-color: #23a455;
}

.progress-charts .progress .progress-bar.custom-color--6 {
  background-color: #a0a0a0;
}

.progress-charts .progress .progress-bar.custom-color--7 {
  background-color: #000;
}

.progress-charts .progress .progress-bar.custom-color--8 {
  background-color: #7971ea;
}

.progress-charts .progress .progress-bar span.percent-label {
  position: absolute;
  color: #222;
  right: -7px;
  top: -35px;
  font-size: 14px;
  font-weight: 600;
}

.prog-title {
  font-size: 14px;
  margin-bottom: 10px;
}

.progress-charts {
  margin-bottom: 20px;
}

.radial-progress-single.progress-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .radial-progress-single.progress-flex {
    display: block;
  }
}

/*===================================
=           36. Icon Style Css         =
====================================*/
.icon__style--1 .inner {
  padding: 30px 50px;
  transition: all 0.3s ease-in-out;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .icon__style--1 .inner {
    padding: 60px 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .icon__style--1 .inner {
    padding: 60px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .icon__style--1 .inner {
    padding: 60px 15px;
  }
}

.icon__style--1 .inner .content {
  margin-top: 20px;
}

.icon__style--1 .inner .content .title {
  font-weight: 600;
}

.icon__style--1 .inner .content p {
  font-size: 14px;
}

.icon__style--1 .inner .content .button-link {
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0);
  color: #2f2f2f;
  padding: 15px 30px;
  font-weight: 600;
}

.icon__style--1 .inner .content .button-link:hover {
  text-decoration: underline;
}

.icon__style--1 .inner .content .button-link2 {
  color: #2f2f2f;
  font-size: 16px;
  line-height: 1.4074;
}

.icon__style--2 {
  margin-top: 40px;
}

.icon__style--2 .icon i {
  width: 90px;
  height: 90px;
  font-size: 40px;
  border-radius: 50%;
  line-height: 87px;
  background-color: #11ccd3;
  color: #ffffff;
}

.icon__style--2 .icon.icon_themecolor i {
  background: #f53e6a;
  color: #fff;
  font-size: 30px;
}

.icon__style--2 .content {
  margin-top: 25px;
}

.icon__style--2 .content .title {
  font-weight: 600;
  margin-bottom: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .icon__style--2 .content .title {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .icon__style--2 .content .title {
    font-size: 18px;
  }
}

.icon__style--2 .content p {
  line-height: 1.5em;
}

.icon__style--3 {
  display: flex;
  margin-bottom: 60px;
}

.icon__style--3 .icon {
  width: 26%;
  margin-top: 19px;
}

@media only screen and (max-width: 767px) {
  .icon__style--3 .icon {
    width: auto;
    margin-right: 28px;
  }
}

.icon__style--3 .icon i {
  width: 1em;
  font-size: 64px;
  line-height: 1;
  color: #2f21b3;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .icon__style--3 .icon i {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .icon__style--3 .icon i {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .icon__style--3 .icon i {
    font-size: 30px;
  }
}

.icon__style--3 .content {
  width: 73.924%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .icon__style--3 .content .title {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .icon__style--3 .content .title {
    font-size: 18px;
  }
}

.icon__style--3 .content p {
  line-height: 28px;
  font-size: 14px;
}

/*========================================
=           36. Blog pages Css           =
========================================*/
.latest-blog-news-area {
  overflow: hidden;
}

.architecture-blog-slider-1 .slick-list {
  margin-right: -100px;
  padding-right: 20% !important;
}

.architecture-blog-slider-1 .slick-active + .slick-active {
  opacity: .3;
}

.architecture-blog-slider-1 .slick-dots {
  text-align: left;
  margin-top: 20px;
  margin-left: 15px;
}

.architecture-blog-slider-1 .slick-dots li {
  display: inline-block;
  margin: 0 4px;
}

.architecture-blog-slider-1 .slick-dots li.slick-active button {
  background: #333;
}

.architecture-blog-slider-1 .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 14px;
  height: 14px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: #ddd;
  border-radius: 50%;
}

.single-blog-wrap.blog-style-one .blog-contenet {
  margin-top: 10px;
  font-size: 14px;
}

.single-blog-wrap.blog-style-one .blog-contenet .blog-media {
  color: #4241ff;
  font-style: italic;
  margin-bottom: 8px;
}

.single-blog-wrap.blog-style-one .blog-contenet .blog-media a:hover {
  text-decoration: underline;
}

.single-blog-wrap.blog-style-one .blog-contenet h6 {
  font-weight: 600;
}

.real_estate-single-blog-item .latest-blog-content {
  margin-top: 20px;
}

.real_estate-single-blog-item .latest-blog-content .blog-media_tag {
  color: #4241ff;
}

.real_estate-single-blog-item .latest-blog-content .blog-media_tag a {
  color: #75ad1c;
  text-transform: uppercase;
  font-weight: 600;
}

.real_estate-single-blog-item .latest-blog-content .blog-media_tag a:hover {
  text-decoration: underline;
}

.real_estate-single-blog-item .latest-blog-content .blog-media_box {
  margin-top: 10px;
  margin-bottom: 15px;
}

.single-latest-blog-content .blog-media {
  font-size: 12px;
}

.single-latest-blog-content .blog-media .author {
  font-weight: 600;
}

.single-latest-blog-content .border-gray {
  position: relative;
}

.single-latest-blog-content .border-gray::before {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  background: #ddd;
  height: 2px;
  width: 50px;
}

.single-latest-blog-content .btn-lg:hover {
  background: #ea622b;
  color: #fff;
}

.single-blog-item .post-info .post-categories {
  color: #4241ff;
  font-size: 14px;
}

.single-blog-item .post-info .post-categories a:hover {
  text-decoration: underline;
}

.single-blog-item .post-meta {
  display: flex;
}

.single-blog-item .post-meta .meta-item {
  font-size: 12px;
  margin-right: 10px;
}

.single-blog-item .post-meta .meta-item i {
  margin-right: 3px;
}

.single-blog-item .post-meta .meta-item a:hover {
  text-decoration: underline;
}

.single-blog-item .post-excerpt p {
  font-size: 14px;
}

.single-blog-item .box-post {
  background: #ffffff;
  padding: 30px 20px;
  box-shadow: 0 0 41px 0px rgba(0, 0, 0, 0.1);
}

.post-read-more {
  position: relative;
  display: flex;
  align-items: center;
}

.post-read-more:after {
  content: '';
  border-top: 1px solid #d8d8d8;
  display: inline-block;
  width: 80%;
  margin-left: 25px;
}

.single-blog-item-style-tow {
  overflow: hidden;
}

.single-blog-item-style-tow .blog-thumbnail {
  position: relative;
}

.single-blog-item-style-tow .blog-thumbnail > a {
  display: block;
}

.single-blog-item-style-tow .blog-thumbnail > a img {
  width: 100%;
}

.single-blog-item-style-tow .blog-post-absolute {
  position: absolute;
  bottom: 0%;
  padding: 40px;
  height: 0%;
  width: 100%;
  transform: translateY(0%);
  display: flex;
  align-items: center;
  opacity: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  z-index: 88;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .single-blog-item-style-tow .blog-post-absolute {
    padding: 20px;
  }
}

.single-blog-item-style-tow .blog-post-absolute .content-box {
  z-index: 9;
}

.single-blog-item-style-tow .blog-post-absolute .content-box .post-title a:hover {
  text-decoration: underline;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-blog-item-style-tow .blog-post-absolute .content-box .post-title {
    font-size: 18px;
  }
}

.single-blog-item-style-tow .blog-post-absolute .post-categories {
  color: #ffffff;
  font-size: 14px;
}

.single-blog-item-style-tow .blog-post-absolute .post-categories a:hover {
  color: #ffffff;
  text-decoration: underline;
}

.single-blog-item-style-tow .blog-post-absolute .meta-item a {
  color: #ffffff;
}

.single-blog-item-style-tow .blog-post-absolute.overlay--default::after {
  background: rgba(66, 65, 255, 0.9);
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  height: 100%;
  width: 100%;
}

.single-blog-item-style-tow .post-meta {
  display: flex;
}

.single-blog-item-style-tow .post-meta .meta-item {
  font-size: 12px;
  margin-right: 10px;
  color: #ffffff;
}

.single-blog-item-style-tow .post-meta .meta-item i {
  margin-right: 3px;
}

.single-blog-item-style-tow .post-meta .meta-item a:hover {
  text-decoration: underline;
}

.single-blog-item-style-tow:hover .blog-post-absolute {
  height: 100%;
  opacity: 1;
  overflow: visible;
}

.single-blog-item-style-three {
  overflow: hidden;
  position: relative;
}

.single-blog-item-style-three .blog-thumbnail {
  position: relative;
}

.single-blog-item-style-three .blog-thumbnail img {
  width: 100%;
}

.single-blog-item-style-three .blog-thumbnail.overlay--default::before {
  background-image: linear-gradient(180deg, rgba(19, 19, 19, 0.66) 0, #4241FF 100%);
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 1;
}

.single-blog-item-style-three .blog-thumbnail.overlay--default::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-image: linear-gradient(180deg, rgba(69, 69, 69, 0) 0%, #212121 100%);
  transition: all .5s ease-in-out;
  z-index: 1;
}

.single-blog-item-style-three .blog-post-absolute {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 3;
  color: #fff;
  transition: all .5s ease-in-out;
  animation-name: m1ServicesIn;
  z-index: 88;
  padding: 30px;
}

.single-blog-item-style-three .blog-post-absolute .post-title a {
  color: #ffffff;
}

.single-blog-item-style-three .blog-post-absolute .post-title a:hover {
  text-decoration: underline;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-blog-item-style-three .blog-post-absolute .post-title {
    font-size: 18px;
  }
}

.single-blog-item-style-three .blog-post-absolute .content-box {
  overflow: hidden;
  display: none;
  -webkit-animation-duration: .7s;
  animation-duration: .7s;
  -webkit-animation-name: m1ServicesIn;
  animation-name: m1ServicesIn;
  color: #fff;
  bottom: 0;
}

.single-blog-item-style-three .blog-post-absolute .post-categories {
  color: #ffffff;
  font-size: 14px;
}

.single-blog-item-style-three .blog-post-absolute .post-categories a:hover {
  text-decoration: underline;
}

.single-blog-item-style-three .post-meta {
  display: flex;
}

.single-blog-item-style-three .post-meta .meta-item {
  font-size: 12px;
  margin-right: 10px;
  color: #ffffff;
}

.single-blog-item-style-three .post-meta .meta-item i {
  margin-right: 3px;
}

.single-blog-item-style-three .post-meta .meta-item a:hover {
  text-decoration: underline;
}

.single-blog-item-style-three .post-excerpt p {
  font-size: 12px;
  color: #ffffff;
}

.single-blog-item-style-three:hover .blog-thumbnail.overlay--default::before {
  background-image: linear-gradient(180deg, rgba(19, 19, 19, 0.66) 0, #4241FF 100%);
  opacity: 1;
}

.single-blog-item-style-three:hover .blog-thumbnail.overlay--default::after {
  opacity: 0;
}

.single-blog-item-style-three:hover .blog-post-absolute .post-categories, .single-blog-item-style-three:hover .blog-post-absolute .post-title {
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
  -webkit-animation-name: m1ServicesIn;
  animation-name: m1ServicesIn;
}

.single-blog-item-style-three:hover .blog-post-absolute.overlay--default::before {
  background-image: linear-gradient(180deg, rgba(19, 19, 19, 0.66) 0, #4241FF 100%);
  opacity: 1;
}

.single-blog-item-style-three:hover .blog-post-absolute.overlay--default::after {
  opacity: 0;
}

.single-blog-item-style-three:hover .content-box {
  display: block;
  -webkit-animation-duration: 1.45s;
  animation-duration: 1.45s;
  -webkit-animation-name: m1ServicesIn;
  animation-name: m1ServicesIn;
}

.single-blog-item-style-three::after {
  opacity: 8;
}

.blog-posttestimonial-inner-box {
  background: #4241ff;
  padding: 100px 30px;
  position: relative;
}

.blog-posttestimonial-inner-box p {
  font-family: "Alegreya", serif;
  font-size: 20px;
  color: #ffffff;
  max-width: 470px;
  margin: auto;
}

.blog-posttestimonial-inner-box h6 {
  font-weight: 600;
  font-size: 16px;
}

.blog-posttestimonial-inner-box h6:before {
  content: '';
  width: 50px;
  border-top: 3px solid;
  display: block;
  margin: 0 auto 15px;
}

.blog-slider-active img {
  width: 100%;
}

.blog-slider-active .slick-arrow {
  position: absolute;
  align-items: center;
  transition: all .2s;
  border: none;
  top: 50%;
  color: #fff;
  left: 15px;
  right: auto;
  transform: translateY(-50%);
  font-size: 30px;
  z-index: 9;
  background-color: #fff;
  color: #212121;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 24px;
  border: 0;
}

.blog-slider-active .slick-arrow.slick-next {
  right: 15px;
  left: auto;
}

.blog-slider-active .slick-arrow:hover {
  color: #fff;
  background: #222;
}

.blog-slider-active .slick-slide {
  opacity: 0.5;
}

.blog-slider-active .slick-slide.slick-current {
  opacity: 1;
}

.blog-slider-lg-active .slick-dots {
  position: relative;
  bottom: 0;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 5px;
  margin-bottom: 20px;
}

.blog-slider-lg-active .slick-dots li {
  margin: 0 3px;
  width: 20px;
  display: inline-block;
}

.blog-slider-lg-active .slick-dots li button {
  background: transparent;
  border: none;
  font-weight: 600;
  color: #ffffff;
  position: relative;
  font-size: 0px;
  height: 12px;
  width: 12px;
  background: rgba(225, 225, 225, 0.4);
  border-radius: 5000px;
  margin: 5px;
  padding: 0;
  transition: all 0.4s ease-in-out;
}

.blog-slider-lg-active .slick-dots li.slick-active button, .blog-slider-lg-active .slick-dots li:hover button {
  background: #333;
}

.blog-slider-lg-active .single-slider-item {
  position: relative;
}

.blog-slider-lg-active .single-slider-item img {
  width: 100%;
}

.blog-slider-lg-active .blog-post-absolute {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 30px 60px;
}

.blog-slider-lg-active .blog-post-absolute:before {
  content: '';
  opacity: .7;
  background-image: linear-gradient(270deg, transparent 24%, #000 100%);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -0;
}

@media only screen and (max-width: 767px) {
  .blog-slider-lg-active .blog-post-absolute {
    padding: 15px 25px;
  }
}

@media only screen and (max-width: 575px) {
  .blog-slider-lg-active .blog-post-absolute {
    padding: 6px 15px;
  }
}

.blog-slider-lg-active .blog-post-absolute .content-box {
  position: relative;
}

.blog-slider-lg-active .blog-post-absolute .content-box .post-categories a {
  color: #ffffff;
}

.blog-slider-lg-active .blog-post-absolute .content-box .post-title {
  color: #ffffff;
}

.blog-slider-lg-active .blog-post-absolute .content-box .post-title:hover {
  text-decoration: underline;
}

.blog-slider-lg-active .blog-post-absolute .content-box .post-meta .meta-item {
  color: #ffffff;
}

.post-tag-box {
  display: flex;
}

@media only screen and (max-width: 575px), only screen and (min-width: 768px) and (max-width: 991px) {
  .post-tag-box {
    flex-direction: column;
  }
  .post-tag-box .title {
    margin-bottom: 10px;
  }
}

.post-socail-share-box {
  align-items: center;
  justify-content: flex-end;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .post-socail-share-box {
    justify-content: flex-start;
  }
}

.width-100 img {
  width: 100%;
}

.post-navigation-wrap .single-categories-post {
  display: flex;
  margin-bottom: 20px;
}

.post-navigation-wrap .single-categories-post .pr-item-left-img {
  display: block;
}

.post-navigation-wrap .single-categories-post .pr-item-left-img img {
  max-width: 70px;
}

.post-navigation-wrap .single-categories-post .pr-item-left-img .text {
  font-size: 12px;
  margin-top: 10px;
  font-weight: 600;
}

.post-navigation-wrap .single-categories-post .pr-item--right {
  margin-left: 15px;
  max-width: 200px;
}

.post-navigation-wrap .single-categories-post .pr-item--right h6 {
  font-size: 14px;
  font-weight: 600;
}

.post-navigation-wrap .single-categories-post .pr-item--right span {
  font-size: 12px;
}

.post-navigation-wrap .single-categories-post.next-post .pr-item--right {
  margin-left: 0px;
}

.comment-list {
  margin-top: 0;
  margin-bottom: 0rem;
  padding: 0;
  list-style: none;
}

.comment-body {
  display: flex;
  margin-top: 30px;
}

.comment-body .author-image {
  margin-right: 20px;
}

.comment-body .author-image img {
  max-width: 80px;
}

@media only screen and (max-width: 575px) {
  .comment-body .author-image {
    margin-right: 10px;
  }
  .comment-body .author-image img {
    max-width: 60px;
  }
}

.comment-body .comment-content-outer {
  border: 1px solid #ddd;
  padding: 30px 30px;
}

@media only screen and (max-width: 575px) {
  .comment-body .comment-content-outer {
    padding: 20px 10px;
  }
}

.comment-body .comment-content-outer .comment-author {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.comment-body .comment-content-outer .comment-author .comment-date {
  font-size: 12px;
  margin-left: 10px;
}

.comment-body .comment-content-outer .comment-content {
  margin-bottom: 15px;
}

.comment-body .comment-content-outer .comment-content p {
  font-size: 14px;
}

.comment-body .comment-content-outer .comment-meta .comment-reply-link {
  font-weight: 600;
  font-size: 12px;
}

.comments-form input[type="text"], .comments-form input[type="email"], .comments-form textarea {
  border: none;
  border: 1px solid #ddd;
  width: 100%;
  padding: 8px 10px;
  margin-bottom: 30px;
  background: transparent;
  color: #000000;
}

.comments-form textarea {
  height: 200px;
}

.has-drop-cap:not(:focus):after {
  content: "";
  display: table;
  clear: both;
  padding-top: 14px;
}

blockquote.quote-style-two {
  color: #fff;
  width: 100%;
  padding: 100px 50px 50px;
  border: none;
  position: relative;
  margin: 0 0 20px;
  font-family: "Alegreya", serif;
  background: #212121 url(../images/others/quote2-bg.jpg) no-repeat center;
  background-size: cover;
}

blockquote.quote-style-two::before {
  content: "“";
  font-size: 150px;
  line-height: 40px;
  position: absolute;
  top: 75px;
}

blockquote.quote-style-two p {
  font-size: 36px;
  line-height: 1.22;
  font-weight: 700;
}

blockquote.quote-style-two cite {
  font-size: 18px;
  font-weight: 700;
}

blockquote.quote-style-three {
  background: #f9f9f9 url(../images/icons/quote-3.png) no-repeat center;
  border: none;
  padding: 70px 50px 70px 200px;
  background-position: 80px 80px;
  background-size: 80px auto;
  margin: 50px 0;
}

blockquote.quote-style-three p {
  font-weight: 700;
  font-size: 32px;
  line-height: 1.375;
  font-family: "Montserrat",sans-serif !important;
  margin-bottom: 20px;
}

blockquote.quote-style-three cite {
  font-weight: 400;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  blockquote.quote-style-three {
    padding: 33px 15px 60px 62px;
    background-position: 15px 40px;
    background-size: 34px auto;
  }
  blockquote.quote-style-three p {
    font-size: 28px;
  }
}

@media only screen and (max-width: 575px) {
  blockquote.quote-style-three {
    padding: 33px 15px 60px 62px;
    background-position: 15px 40px;
    background-size: 34px auto;
  }
  blockquote.quote-style-three p {
    font-size: 18px;
  }
}

.fix-bg-images {
  height: 400px;
  background: url(../images/bg/sp-bg9.jpg) no-repeat center;
  background-attachment: fixed;
}

@media only screen and (max-width: 767px) {
  .fix-bg-images {
    height: 300px;
  }
}

.widget-inner_banner .inner-img {
  position: relative;
  cursor: pointer;
  width: 100%;
}

.widget-inner_banner .inner-img a {
  display: block;
}

.widget-inner_banner .inner-img img {
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
}

.widget-inner_banner .inner-img:before {
  background-color: #000000;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in;
}

.widget-inner_banner .inner-img:hover:before {
  width: calc(100% - 40px);
  height: calc(100% - 30px);
  top: 15px;
  left: 20px;
  opacity: 0.3;
}

.widget-inner_banner .inner-img:hover .banner-add-content {
  transform: translateY(-10px);
}

.widget-inner_banner .inner-img .banner-add-content {
  position: absolute;
  bottom: 0;
  padding: 30px;
  transition: all 0.4s ease-in-out;
  margin: auto;
  text-align: center;
  left: 0;
  right: 0;
}

.widget-inner_banner .inner-img .banner-add-content h5 {
  color: #ffffff;
}

.widget-inner_banner .inner-img .banner-add-content p {
  color: #ffffff;
}

.widget-inner_banner:hover:before {
  width: calc(100% - 40px);
  height: calc(100% - 30px);
  top: 15px;
  left: 20px;
  opacity: 0.3;
}

.blog-element-active img {
  width: 100%;
}

.blog-element-active .slick-arrow {
  position: absolute;
  align-items: center;
  transition: all .2s;
  border: none;
  top: 50%;
  color: #fff;
  left: -25px;
  right: auto;
  transform: translateY(-50%);
  font-size: 30px;
  z-index: 9;
  background-color: #fff;
  color: #212121;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  font-size: 24px;
  border: 0;
  margin-bottom: 50px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.blog-element-active .slick-arrow.slick-next {
  right: -25px;
  left: auto;
}

.blog-element-active .slick-arrow:hover {
  color: #fff;
  background: #222;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .blog-element-active .slick-arrow {
    left: 15px;
  }
  .blog-element-active .slick-arrow.slick-next {
    right: 15px;
    left: auto;
  }
}

.boder-inner {
  position: relative;
}

.boder-inner::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 40px;
  height: 4px;
  background: #ddd;
}

/*==========================================
=           37. About us pages Css          =
============================================*/
.full-width-row {
  display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .full-width-row {
    flex-direction: column;
  }
}

@media only screen and (max-width: 767px) {
  .full-width-row {
    flex-direction: column;
  }
}

.elementor-col-30 {
  width: 30%;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .elementor-col-30 {
    width: 100%;
  }
}

.elementor-col-40 {
  width: 40%;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .elementor-col-40 {
    width: 100%;
  }
}

.elementor-col-60 {
  width: 60%;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .elementor-col-60 {
    width: 100%;
  }
}

.elementor-col-70 {
  width: 70%;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .elementor-col-70 {
    width: 100%;
  }
}

.about-me-content-one {
  padding: 80px 50px;
}

.about-me-content-one p {
  font-size: 18px;
}

.about-me-content-one .list-contact li {
  font-size: 20px;
  font-weight: 600;
}

.about-me-content-experians {
  padding: 80px 50px;
}

.single-milestones {
  margin-top: 50px;
}

.single-milestones h6 {
  font-weight: 600;
  font-size: 20px;
}

.single-milestones .drak {
  color: #999;
}

.parallax-imgae-about-me-01 {
  max-width: 440px;
}

.parallax-imgae-about-me-02 {
  bottom: 0;
  max-width: 440px;
  margin-left: auto;
  position: absolute;
  right: 0;
}

.about-me-two p {
  font-size: 18px;
  line-height: 2.5;
  font-weight: 300;
}

.img-before {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover  !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .img-before {
    min-height: 400px;
    height: 100%;
  }
}

.about-two-box-wrap {
  margin-top: -80px;
  background: #fff;
  padding-top: 60px;
}

@media only screen and (max-width: 767px) {
  .about-two-box-wrap {
    margin-top: 0;
  }
}

.about-section-bg {
  background: #F9F9F9;
}

.about-section-bg-img {
  z-index: 0;
  width: 100%;
  max-width: 100%;
  bottom: 0;
  right: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-section-bg-img {
    margin-top: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-section-bg-img {
    margin-top: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .about-section-bg-img {
    margin-top: -180px;
  }
}

@media only screen and (max-width: 575px) {
  .about-section-bg-img {
    margin-top: -80px;
  }
}

.about-section-content {
  position: absolute;
  top: 0;
  padding: 200px 0;
}

.about-section-content h2 {
  font-size: 56px;
}

.about-section-content p {
  line-height: 2em;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .about-section-content {
    padding: 100px 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-section-content {
    padding: 100px 0;
  }
  .about-section-content h2 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-section-content {
    padding: 100px 0;
  }
  .about-section-content h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .about-section-content {
    position: inherit;
    padding: 50px 0 0;
  }
  .about-section-content h2 {
    font-size: 24px;
  }
}

.section-space-400 {
  height: 350px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-space-400 {
    height: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .section-space-400 {
    height: 170px;
  }
}

.about-section-two-area .about-us-absolate {
  margin-top: -250px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about_images-box_two {
    margin-top: 80px;
  }
}

.about-us-two-text {
  padding: 0 70px;
  margin-top: 130px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-two-text {
    padding: 0 30px;
    margin-top: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .about-us-two-text {
    margin-top: 150px;
    margin-bottom: 60px;
    padding: 0 30px;
  }
}

.about-us-two-text .dec__text {
  margin-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .about-us-two-text .dec__text {
    margin-top: 80px;
  }
}

.about-us-two-text .read-btn {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
}

.about-us-two-text .read-btn i {
  margin-left: 10px;
  color: #4241ff;
  transition: all 0.3s ease-in-out;
}

.about-us-two-text .read-btn:hover i {
  margin-left: 20px;
}

.bg-about-parallax {
  background-position: right top !important;
  background-size: cover !important;
}

.about-section-three h2 {
  font-size: 66px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-section-three h2 {
    font-size: 46px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .about-section-three h2 {
    font-size: 26px;
  }
}

.about-section-three p {
  font-size: 20px;
  margin-top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .about-section-three p {
    font-size: 18px;
  }
}

/*=======================================
=           19. Timeline Css           =
=========================================*/
.ht-timeline.style-01.mb-different .item {
  position: relative;
  padding: 0px 0 17px 45px;
}

.ht-timeline.style-01.mb-different .item:last-child {
  padding-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .ht-timeline.style-01.mb-different .item {
    padding: 0px 0 17px 35px;
  }
}

.ht-timeline.style-01 .item {
  position: relative;
  padding: 0px 0 35px 45px;
}

.ht-timeline.style-01 .item:last-child {
  padding-bottom: 0;
}

.ht-timeline.style-01 .item:last-child::before {
  display: none;
}

@media only screen and (max-width: 575px) {
  .ht-timeline.style-01 .item {
    padding: 0px 0 35px 35px;
  }
}

.ht-timeline.style-01 .item::before {
  position: absolute;
  left: 15px;
  margin-left: -1px;
  height: 100%;
  width: 1px;
  content: '';
  background: #4241ff;
}

@media only screen and (max-width: 575px) {
  .ht-timeline.style-01 .item::before {
    left: 5px;
    margin-top: 2px;
  }
}

.ht-timeline.style-01 .dots .middle-dot {
  position: absolute;
  top: 0px;
  left: 15px;
  transform: translate(-50%, 0);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  z-index: 2;
  background: #4241ff;
}

@media only screen and (max-width: 575px) {
  .ht-timeline.style-01 .dots .middle-dot {
    left: 5px;
  }
}

.ht-timeline.style-01 .tm-timeline-list .timeline-col {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-top: -5px;
}

.ht-timeline.style-01 .tm-timeline-list .timeline-col .content-body h5 {
  font-size: 18px;
  margin-bottom: 3px;
}

.ht-timeline.style-01 .tm-timeline-list .timeline-col .content-body p {
  line-height: 1.3;
}

.ht-timeline.style-01 .tm-timeline-list .timeline-col .content-body p span {
  font-weight: 300;
}

.ht-timeline.style-01 .tm-timeline-list .timeline-col:last-child {
  padding-bottom: 0;
}

.about-four_image-one {
  max-width: 400px;
  margin-right: 150px;
  margin-left: auto;
}

.about-four_image-two {
  margin-right: -60px;
  margin-top: -300px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-section-content-four h2 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .about-section-content-four {
    margin: 60px 0;
  }
}

.about-border-btn {
  padding-left: 120px;
  position: relative;
  font-weight: 700;
}

.about-border-btn::after {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  content: '';
  background: #4241ff;
  height: 4px;
  width: 90px;
}

.mission-video-bg-01 {
  background: url("../images/bg/video-bg-2.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.our-mission-content {
  padding: 80px;
  max-width: 755px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .our-mission-content {
    padding: 60px 30px;
  }
}

.our-mission-content .mission-title {
  font-weight: 600;
  font-size: 66px;
  margin-bottom: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .our-mission-content .mission-title {
    font-size: 46px;
  }
}

@media only screen and (max-width: 767px) {
  .our-mission-content .mission-title {
    font-size: 36px;
  }
}

.our-mission-content .mission-title--two {
  font-size: 56px;
  font-weight: 700;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .our-mission-content .mission-title--two {
    font-size: 46px;
  }
}

@media only screen and (max-width: 767px) {
  .our-mission-content .mission-title--two {
    font-size: 36px;
  }
}

.our-mission-content .info_text {
  font-weight: 400;
  font-size: 20px;
  line-height: 2.2;
}

.our-mission-content .mission-list li {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  padding-left: 25px;
  position: relative;
}

.our-mission-content .mission-list li::after {
  position: absolute;
  left: 0;
  top: 50%;
  background: #4241ff;
  height: 10px;
  width: 10px;
  border-radius: 500px;
  content: "";
  transform: translateY(-50%);
}

.our-mission-content .mission-list li:not(:last-child) {
  margin-bottom: calc(20px/2);
}

.our-mission-content .mission-list.white-list li {
  font-size: 20px;
  font-weight: 700;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
  padding-left: 25px;
  position: relative;
  color: #fff;
}

.our-mission-content .mission-list.white-list li::after {
  background: #fff;
}

.mission-video {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mission-video a {
  background: #fff;
  color: #111;
  height: 120px;
  width: 120px;
  line-height: 120px;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  color: #4241ff;
}

.mission-video a:hover {
  transform: scale(1.2);
}

.mission-bg-02 {
  background: url("../images/bg/m5-bg-1.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.our-values-content {
  padding: 80px;
  max-width: 755px;
}

.our-values-content .info_text {
  font-size: 20px;
}

.our-values-content .values-list li {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.our-values-content .values-list li img {
  margin-right: 15px;
  width: 52px;
}

.our-values-content .values-list li:last-child {
  margin-bottom: 0px;
}

.parallax-imgae-01 {
  max-width: 400px;
}

.parallax-imgae-02 {
  margin-top: -150px;
  max-width: 400px;
  margin-left: auto;
  margin-right: 0;
}

.properties-apps-area {
  overflow: hidden;
}

.app-parallax-imgae-01 {
  margin-top: 40px;
  max-width: 470px;
}

.app-parallax-imgae-02 {
  max-width: 400px;
  margin-left: auto;
  margin-right: 0;
  margin-top: -450px;
}

.welcome-text-title {
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 15px;
}

@media only screen and (max-width: 575px) {
  .welcome-text-title {
    letter-spacing: 2px;
    font-size: 22px;
  }
}

.welcome-title-wrap p {
  font-family: "Alegreya",Sans-serif;
  font-size: 24px;
  line-height: 1.6em;
}

.what-we-work-inner {
  padding: 100px 200px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .what-we-work-inner {
    padding: 100px 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .what-we-work-inner {
    padding: 100px 100px;
  }
}

@media only screen and (max-width: 767px) {
  .what-we-work-inner {
    padding: 100px 20px;
  }
}

.what-we-work-inner p {
  font-size: 22px;
}

.about-content-12 p {
  font-size: 22px;
}

@media only screen and (max-width: 767px) {
  .our-values-text {
    margin: 30px 0 60px;
  }
}

.listing-one li {
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 16px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .listing-one li {
    font-size: 14px;
  }
}

.listing-one li .icon-list-icon {
  border: 1px solid #3e3e3e;
  width: 32px;
  height: 32px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  transition: all .3s;
  top: 0;
  display: inline-block;
  margin-right: 5px;
}

.listing-one li:hover .icon-list-icon {
  border: 1px solid #4241ff;
  background-color: #4241ff;
  color: #ffffff;
}

.single-award-list {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.single-award-list img {
  width: 120px;
  margin-right: 40px;
}

.about__counter {
  margin-top: 30px;
}

/*======================================================
=           38. Real Estate hero Slider css           =
======================================================*/
.real_estate-slier-three-active .slick-arrow {
  background: transparent;
  border: none;
  position: absolute;
  left: 10px;
  top: 50%;
  right: auto;
  font-size: 30px;
  color: #fff;
  z-index: 99;
}

.real_estate-slier-three-active .slick-arrow.slick-next {
  left: auto;
  right: 10px;
}

.single-real_estate-item {
  height: 800px;
  display: flex !important;
  align-items: center;
}

.single-real_estate-item-16 {
  height: 600px;
  display: flex !important;
  align-items: center;
}

.single-real_estate-item-16 h1 {
  font-size: 36px;
}

@media only screen and (max-width: 575px) {
  .single-real_estate-item-16 h1 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 575px) {
  .single-real_estate-item-16 {
    height: 480px;
  }
}

.real_estate-hero-content {
  position: relative;
  padding: 50px;
  background: rgba(0, 0, 0, 0.7);
  max-width: 750px;
}

@media only screen and (max-width: 575px) {
  .real_estate-hero-content {
    padding: 50px 20px;
  }
}

.real_estate-hero-content .listing__content-types {
  position: absolute;
  top: 0;
  right: 0;
}

.real_estate-hero-content .listing__content-types .job-type {
  background-color: #3377fa;
  font-family: sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  font-size: 12px;
  padding: 7px 15px 5px;
  vertical-align: middle;
  line-height: 20px;
  color: #fff;
}

.real_estate-hero-content .properties-location {
  color: #ffffff;
}

.real_estate-hero-content .properties-location i {
  color: #ea622b;
}

.real_estate-hero-content .properties-price {
  color: #75ad1c;
  font-size: 28px;
  letter-spacing: -1px;
  margin: 0 0 5px;
}

.real_estate-hero-content .listing__content-metaitem {
  display: inline-block;
  margin-right: 30px;
}

.real_estate-hero-content .listing__content-metaitem i {
  color: #ea622b;
}

.real_estate-hero-content .listing__content-metaitem .listing__content-metavalue {
  color: #ffffff;
  margin-left: 5px;
}

.find-house-absolate {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .find-house-absolate {
    left: 0;
    right: 0;
    transform: translate(0%, -50%);
  }
}

.find-house-absolate-inner {
  max-width: 800px;
  margin: auto;
  padding: 60px 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .find-house-absolate-inner {
    padding: 60px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .find-house-absolate-inner {
    padding: 60px 30px;
    width: 100%;
  }
}

.find-house-box-wrap {
  margin-top: -100px;
  background: #ffffff;
  z-index: 99;
  position: relative;
  padding: 80px 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .find-house-box-wrap {
    padding: 50px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .find-house-box-wrap {
    margin-top: -30px;
    padding: 40px 20px;
  }
}

.find-house-box-wrap .real-estate-form .real-estate-input {
  margin-top: 30px;
}

.find-house-box-wrap .real-estate-form .real-estate-input input, .find-house-box-wrap .real-estate-form .real-estate-input select {
  width: 100%;
  height: 70px;
  background: #fff;
  border-radius: 0;
  padding: 15px;
  border: 1px solid #555;
  color: #111;
}

.find-house-box-wrap .real-estate-form .real-estate-input input:focus, .find-house-box-wrap .real-estate-form .real-estate-input select:focus {
  border: 1px solid #555;
}

.find-house-box-wrap .real-estate-form .real-estate-input select {
  background: #fff url(../images/icons/selector-icon.png) no-repeat center right 20px;
  background-color: #fff;
}

.find-house-box-wrap .real-estate-form button {
  width: 100%;
  height: 70px;
  background: #75ad1c;
  border: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
  padding: 20px;
}

.find-house-box-wrap .real-estate-form button i {
  font-size: 28px;
  float: left;
}

.real-estate-form .real-estate-input {
  margin-top: 30px;
}

.real-estate-form .real-estate-input input, .real-estate-form .real-estate-input select {
  width: 100%;
  height: 70px;
  background: #fff;
  border-radius: 0;
  padding: 15px;
  border: none;
  color: #111;
}

.real-estate-form .real-estate-input input:focus, .real-estate-form .real-estate-input select:focus {
  border: none;
}

.real-estate-form .real-estate-input select {
  background: #fff url(../images/icons/selector-icon.png) no-repeat center right 20px;
  background-color: #fff;
}

.real-estate-form .real-estate-input textarea {
  width: 100%;
  height: 200px;
  background: #fff;
  padding: 15px;
  border: none;
}

.real-estate-form button {
  width: 100%;
  height: 70px;
  background: #75ad1c;
  border: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
  padding: 20px;
}

.real-estate-form button i {
  font-size: 28px;
  float: left;
}

.real_estate_hero_video {
  position: absolute;
  right: 0;
  left: 75%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.real_estate_hero_video .video-btn {
  height: 100px;
  width: 100px;
  line-height: 100px;
  font-size: 30px;
  color: #333;
}

.real_estate_hero_video .video-btn:hover {
  background: #75ad1c;
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .real_estate_hero_video {
    display: none;
  }
}

/*======================================================
=           39. Architecture hero Slider Css           =
======================================================*/
.single-hero-slider-one {
  height: 700px;
  display: flex !important;
  align-items: center;
  background-size: cover;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-one {
    height: 620px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-one {
    height: 520px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-slider-one {
    height: 420px;
  }
}

.single-hero-slider-one h1 {
  font-size: 87px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-one h1 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-one h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-slider-one h1 {
    font-size: 30px;
  }
}

.single-hero-slider-one a {
  margin-top: 30px;
  font-size: 16px;
}

.single-hero-slider-one a i {
  height: 46px;
  width: 46px;
  line-height: 43px;
  text-align: center;
  background: transparent;
  border: 1px solid #ddd;
  border-radius: 5000px;
  margin-left: 10px;
}

.single-hero-slider-one a:hover i {
  background: #4241ff;
  border: 1px solid #4241ff;
  color: #ffffff;
}

.hreo-slider-one .slick-dots {
  right: 60px;
  position: absolute;
  bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .hreo-slider-one .slick-dots {
    bottom: 20px;
  }
}

.hreo-slider-one .slick-dots li {
  margin-bottom: 10px;
}

.hreo-slider-one .slick-dots li button {
  background: transparent;
  border: none;
  font-weight: 600;
  color: #ffffff;
  position: relative;
  font-size: 20px;
}

.hreo-slider-one .slick-dots li button::after {
  position: absolute;
  left: 5px;
  bottom: 0;
  width: 0px;
  height: 4px;
  background: #fff;
  content: '';
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.hreo-slider-one .slick-dots li.slick-active button::after {
  opacity: 1;
  width: 30px;
}

.single-hero-slider-two {
  height: 700px;
  display: flex !important;
  align-items: center;
  background-size: cover;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-two {
    height: 620px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-two {
    height: 520px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-slider-two {
    height: 440px;
  }
}

.single-hero-slider-two h1 {
  font-size: 87px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-two h1 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-two h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-slider-two h1 {
    font-size: 30px;
  }
}

.single-hero-slider-two a {
  margin-top: 30px;
  font-size: 16px;
}

.single-hero-slider-two a i {
  height: 46px;
  width: 46px;
  line-height: 43px;
  text-align: center;
  background: transparent;
  border: 1px solid #ddd;
  border-radius: 5000px;
  margin-left: 10px;
}

.single-hero-slider-two a:hover i {
  background: #4241ff;
  border: 1px solid #4241ff;
}

.hero-slider-content_03 h1 {
  font-size: 62px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content_03 h1 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content_03 h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content_03 h1 {
    font-size: 30px;
  }
}

.hero-slider-content_03 .hero-arrow-button a {
  margin-top: 30px;
  color: #fff;
}

.hero-slider-content_03 .hero-arrow-button a .hero-icon {
  font-size: 80px;
}

.hero-slider-content_03 .hero-arrow-button a .hero-icon:hover {
  color: #4241ff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content_03 .hero-arrow-button a .hero-icon {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content_03 .hero-arrow-button a .hero-icon {
    font-size: 40px;
  }
}

.hero-slider-content_05 h1 {
  font-size: 79px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content_05 h1 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content_05 h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content_05 h1 {
    font-size: 30px;
  }
}

.single-hero-slider-14 {
  height: 700px;
  display: flex !important;
  align-items: center;
  background-size: cover;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-14 {
    height: auto;
    padding: 50px 0 70px;
  }
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-14 .hero-slider-content {
    margin-top: 30px;
  }
}

.single-hero-slider-14 .hero-slider-content h1 {
  font-size: 119px;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-14 .hero-slider-content h1 {
    font-size: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-slider-14 .hero-slider-content h1 {
    font-size: 60px;
  }
}

.single-hero-slider-14 .hero-slider-content .hreo-price {
  font-size: 36px;
  font-weight: 400;
}

.single-hero-slider-14 .hero-slider-content .hreo-price .old-price {
  font-size: 19px;
  line-height: 24px;
}

.single-hero-slider-14 .hero-slider-content .hero-btn {
  background: #ea622b;
  color: #ffffff;
  font-weight: 400;
  text-transform: uppercase;
}

.single-hero-slider-14 .hero-slider-content .hero-btn:hover {
  background: #111;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .single-hero-slider-14 .hero-slider-content .hero-btn.btn--lg {
    padding: 20px 30px;
  }
}

.hero-slider-two .slick-dots, .hero-slider-12-active .slick-dots, .hero-slider-14-active .slick-dots {
  position: absolute;
  bottom: 30px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.hero-slider-two .slick-dots li, .hero-slider-12-active .slick-dots li, .hero-slider-14-active .slick-dots li {
  margin: 0 5px;
  width: 20px;
  display: inline-block;
}

.hero-slider-two .slick-dots li button, .hero-slider-12-active .slick-dots li button, .hero-slider-14-active .slick-dots li button {
  background: transparent;
  border: none;
  font-weight: 600;
  color: #ffffff;
  position: relative;
  font-size: 0px;
  height: 12px;
  width: 12px;
  background: rgba(225, 225, 225, 0.4);
  border-radius: 5000px;
  margin: 5px;
  padding: 0;
  transition: all 0.4s ease-in-out;
}

.hero-slider-two .slick-dots li button::after, .hero-slider-12-active .slick-dots li button::after, .hero-slider-14-active .slick-dots li button::after {
  position: absolute;
  content: '';
  left: -5px;
  top: -5px;
  height: 18px;
  width: 18px;
  border: 2px solid transparent;
  border-radius: 50000px;
  transition: all .4s ease-in-out;
  opacity: 0;
}

.hero-slider-two .slick-dots li.slick-active button, .hero-slider-12-active .slick-dots li.slick-active button, .hero-slider-14-active .slick-dots li.slick-active button {
  height: 8px;
  width: 8px;
  background: #e1e1e1;
}

.hero-slider-two .slick-dots li.slick-active button::after, .hero-slider-12-active .slick-dots li.slick-active button::after, .hero-slider-14-active .slick-dots li.slick-active button::after {
  border: 2px solid #ddd;
  opacity: 1;
}

.hero-slider-two .slick-dots li:hover button, .hero-slider-12-active .slick-dots li:hover button, .hero-slider-14-active .slick-dots li:hover button {
  height: 8px;
  width: 8px;
  background: #e1e1e1;
}

.hero-slider-two .slick-dots li:hover button::after, .hero-slider-12-active .slick-dots li:hover button::after, .hero-slider-14-active .slick-dots li:hover button::after {
  border: 2px solid #ddd;
  opacity: 1;
}

.hero-slider-14-active .slick-dots li button {
  background: #ea622b;
}

.hero-slider-14-active .slick-dots li.slick-active button {
  height: 8px;
  width: 8px;
  background: #ea622b;
}

.hero-slider-14-active .slick-dots li.slick-active button::after {
  border: 2px solid #ea622b;
  opacity: 1;
}

.hero-slider-14-active .slick-dots li:hover button {
  height: 8px;
  width: 8px;
  background: #ea622b;
}

.hero-slider-14-active .slick-dots li:hover button::after {
  border: 2px solid #ea622b;
  opacity: 1;
}

.hero-slider-three {
  display: flex;
  overflow: hidden;
  align-items: center;
}

.hero-slider-three .hero-item-left {
  width: 30%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-three .hero-item-left {
    width: 15%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-three .hero-item-left {
    display: none;
  }
}

.hero-slider-three .hero-item-left .hreo-social-content {
  width: 300px;
  max-width: 300px;
  margin-left: -90px;
  transform: rotate(-90deg) translateX(50%);
  bottom: calc(50% + 100px);
}

.hero-slider-three .hero-item-left .hreo-social-content .hreo-social-list {
  display: flex;
  align-items: center;
}

.hero-slider-three .hero-item-left .hreo-social-content .hreo-social-list li {
  margin-right: calc(40px/2);
  margin-left: calc(40px/2);
}

.hero-slider-three .hero-item-right {
  width: 70%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-three .hero-item-right {
    width: 85%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-three .hero-item-right {
    width: 100%;
  }
}

.hero-slider-three .hero-slider-four {
  position: relative;
}

.hero-slider-three .single-right-item {
  position: relative;
  height: 600px;
}

@media only screen and (max-width: 767px) {
  .hero-slider-three .single-right-item {
    height: 400px;
    background-size: cover;
  }
}

.hero-slider-three .single-right-item .inner-content {
  position: absolute;
  z-index: 99;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  bottom: 0;
  right: 0;
  min-width: 780px;
  color: white;
  background-color: #f9f9f9;
  margin: 0px;
  padding: 0px;
  min-height: 87px;
  padding-left: 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-slider-three .single-right-item .inner-content {
    min-width: 75%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-three .single-right-item .inner-content {
    min-width: 75%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-three .single-right-item .inner-content {
    min-width: 580px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-three .single-right-item .inner-content {
    min-width: 100%;
    width: 100%;
  }
}

.hero-slider-three .single-right-item .inner-content .text-content {
  position: relative;
  padding-left: 30px;
}

.hero-slider-three .single-right-item .inner-content .text-content::after {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #4241ff;
  content: '';
  height: 40px;
  width: 4px;
}

.hero-slider-three .single-right-item .inner-content h5 {
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 2px;
  margin-bottom: 3px;
}

.hero-slider-three .single-right-item .inner-content p {
  font-size: 10px;
  color: #111;
}

.hero-slider-four .slick-dots {
  position: relative;
  bottom: 0;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 40px;
  margin-bottom: 40px;
}

.hero-slider-four .slick-dots li {
  margin: 0 3px;
  width: 20px;
  display: inline-block;
}

.hero-slider-four .slick-dots li button {
  background: transparent;
  border: none;
  font-weight: 600;
  color: #ffffff;
  position: relative;
  font-size: 0px;
  height: 12px;
  width: 12px;
  background: rgba(225, 225, 225, 0.4);
  border-radius: 5000px;
  margin: 5px;
  padding: 0;
  transition: all 0.4s ease-in-out;
}

.hero-slider-four .slick-dots li.slick-active button, .hero-slider-four .slick-dots li:hover button {
  background: #4241ff;
}

.hero-testimonial-inner-box {
  margin-top: -350px;
  background: #4241ff;
  padding: 140px 50px 60px 50px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-testimonial-inner-box {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-testimonial-inner-box {
    margin-top: 0px;
    padding: 140px 20px 60px 20px;
  }
}

.hero-testimonial-inner-box::after {
  position: absolute;
  content: '';
  left: 80px;
  top: 50px;
  height: 110px;
  width: 110px;
  background: url(../images/icons/m4-quote.png);
  background-repeat: no-repeat;
  background-size: 80px auto;
}

.hero-testimonial-inner-box p {
  font-family: "Alegreya", serif;
  font-size: 26px;
  color: #ffffff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-testimonial-inner-box p {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-testimonial-inner-box p {
    font-size: 20px;
  }
}

.hero-testimonial-inner-box h6 {
  font-weight: 600;
}

.hero-slider-six-wrap .hero-six-text {
  padding: 0 20px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-six-wrap .hero-six-text {
    margin-top: 60px;
    margin-bottom: 40px;
  }
  .hero-slider-six-wrap .hero-six-text br {
    display: none;
  }
}

.hero-slider-six-wrap .single-hero-slider-item {
  margin-top: 20px;
  margin-bottom: 20px;
}

.hero-slider-six-wrap .single-hero-slider-item img {
  width: 100%;
}

.hero-slider-six-wrap .hero-btn-six {
  font-weight: 700;
  position: relative;
  color: #111;
  letter-spacing: 0px;
  font-size: 14px;
  padding-top: 3px;
}

.hero-slider-six-wrap .hero-btn-six:before {
  background: #4241ff;
  content: "";
  left: 0;
  top: 0;
  height: 3px;
  width: 100%;
  position: absolute;
}

.hero-slider-six-wrap .hero-btn-six:hover {
  color: #4241ff;
}

.hero-slider-six-wrap .hero-btn-six:hover:before {
  background: #111;
}

.hero-slider-six .slick-arrow {
  position: absolute;
  left: -120px;
  bottom: 50px;
  background: transparent;
  border: none;
  color: #111;
  font-size: 30px;
}

.hero-slider-six .slick-arrow.slick-next {
  left: -70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .hero-7-project-wrap {
    margin-top: 50px;
  }
}

.hero-7-project-wrap .col-lg-6:nth-child(2) .single-project--three {
  margin-bottom: 70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-7-project-wrap .col-lg-6:nth-child(2) .single-project--three {
    margin-bottom: 55px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-7-project-wrap .col-lg-6:nth-child(2) .single-project--three {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .hero-7-project-wrap .col-lg-6:nth-child(2) .single-project--three {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .hero-7-project-wrap .col-lg-6:nth-child(1) .single-project--three {
    margin-bottom: 30px;
  }
}

.single-hero-slider-nine {
  height: 800px;
  display: flex !important;
  align-items: center;
  background-size: cover;
}

.single-hero-slider-nine h1 {
  font-size: 87px;
  line-height: 1.2;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-hero-slider-nine {
    height: 680px;
  }
  .single-hero-slider-nine h1 {
    font-size: 75px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-nine {
    height: 650px;
  }
  .single-hero-slider-nine h1 {
    font-size: 68px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-nine {
    height: 520px;
  }
  .single-hero-slider-nine h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-slider-nine {
    height: 420px;
  }
  .single-hero-slider-nine h1 {
    font-size: 30px;
  }
}

.hero-slider-9 .slick-arrow {
  position: absolute;
  align-items: center;
  transition: all .2s;
  border: none;
  top: 50%;
  background: transparent;
  color: #fff;
  left: 15px;
  right: auto;
  transform: translateY(-50%);
  font-size: 30px;
  z-index: 9;
}

.hero-slider-9 .slick-arrow.slick-next {
  right: 15px;
  left: auto;
}

.hero-slider-9 .slick-arrow:hover {
  color: #fff;
}

.hero-slider-9 .slick-slide {
  opacity: 0.5;
}

.hero-slider-9 .slick-slide.slick-current {
  opacity: 1;
}

.hero-btn-white {
  background: #ffffff;
  color: #222;
  min-width: 210px;
  max-width: 210px;
  height: 70px;
  border-radius: 40px;
  line-height: 70px;
  font-weight: 600;
  font-family: "Roboto Slab", serif;
  text-transform: uppercase;
}

.hero-btn-white:hover {
  background: #222;
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-btn-white {
    min-width: 180px;
    height: 50px;
    line-height: 51px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-btn-white {
    min-width: 170px;
    height: 50px;
    line-height: 51px;
    font-size: 12px;
  }
}

.single-hero-slider-12 {
  position: relative;
}

.single-hero-slider-12-wrap {
  background: #222;
  padding-left: 450px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-12-wrap {
    padding-left: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-12-wrap {
    padding-left: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-slider-12-wrap {
    padding-left: 150px;
  }
}

.single-hero-slider-12 {
  height: 800px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-12 {
    height: 700px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-12 {
    height: 600px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-slider-12 {
    height: 540px;
  }
}

.slider-12-text-content {
  position: absolute;
  z-index: 999;
  bottom: 150px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-12-text-content {
    bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-12-text-content {
    bottom: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-12-text-content .hero-slider-content-12 h1 {
    font-size: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-12-text-content .hero-slider-content-12 {
    max-width: 477px;
  }
  .slider-12-text-content .hero-slider-content-12 h1 {
    font-size: 27px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-12-text-content .hero-slider-content-12 {
    max-width: 280px;
  }
  .slider-12-text-content .hero-slider-content-12 h1 {
    font-size: 27px;
  }
}

.slider-12-text-content .hero-slider-content-12 h1 {
  font-size: 47px;
}

.slider-12-text-content .hero-slider-content-12 .hero-media li {
  display: inline-block;
  color: #ffffff;
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
}

.slider-12-text-content .hero-slider-content-12 .btn--white:hover {
  background: "Roboto Slab", serif;
  color: #fff;
}

/*==========================================
=           40. Shop style Css                =       
===========================================*/
.shop-top-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
}

@media only screen and (max-width: 575px) {
  .shop-top-bar {
    display: block;
  }
}

.shop-top-bar .shop-top-bar-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .shop-top-bar .shop-top-bar-left {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-left {
    display: flex;
  }
}

.shop-top-bar .shop-top-bar-left .shop-top-show {
  line-height: 1;
}

.shop-top-bar .shop-top-bar-left .shop-top-show span {
  font-weight: 700;
  line-height: 24px;
  display: block;
  line-height: 1;
}

.shop-top-bar .shop-top-bar-left .shop-page-list {
  margin-left: 45px;
  line-height: 1;
}

@media only screen and (max-width: 575px) {
  .shop-top-bar .shop-top-bar-left .shop-page-list {
    margin-left: 0px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-left .shop-page-list {
    margin-left: 20px;
    margin-top: 0px;
  }
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li {
  display: inline-block;
  color: #777777;
  margin-right: 11px;
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li:last-child {
  margin-right: 0px;
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li.active {
  border-bottom: 1px solid #262626;
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li.active a {
  color: #262626;
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li a {
  color: #777777;
}

.shop-top-bar .shop-top-bar-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shop-top-bar .shop-top-bar-right .shop-filter {
  line-height: 1;
  position: relative;
}

.shop-top-bar .shop-top-bar-right .shop-filter a {
  color: #282828;
  font-weight: 700;
}

.shop-top-bar .shop-top-bar-right .shop-filter a i {
  color: #282828;
  font-weight: 700;
  margin-left: 3px;
  transition: all .4s ease 0s;
}

.shop-top-bar .shop-top-bar-right .shop-filter a i.angle-up {
  position: absolute;
  right: 1px;
  top: 0px;
  opacity: 0;
  visibility: hidden;
}

.shop-top-bar .shop-top-bar-right .shop-filter a.active i.angle-down {
  opacity: 0;
  visibility: hidden;
}

.shop-top-bar .shop-top-bar-right .shop-filter a.active i.angle-up {
  opacity: 1;
  visibility: visible;
}

.shop-top-bar .shop-top-bar-right .shop-short-by {
  position: relative;
  padding: 10px 0;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-right .shop-short-by {
    padding: 30px 0;
  }
}

.shop-top-bar .shop-top-bar-right .shop-short-by > span {
  color: #282828;
  font-weight: 700;
  position: relative;
  cursor: pointer;
}

.shop-top-bar .shop-top-bar-right .shop-short-by > span i {
  color: #282828;
  font-weight: 700;
  margin-left: 3px;
  transition: all .4s ease 0s;
}

.shop-top-bar .shop-top-bar-right .shop-short-by > span i.angle-up {
  position: absolute;
  right: 3px;
  top: 1px;
  opacity: 0;
  visibility: hidden;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul {
  right: 0;
  top: 100%;
  position: absolute;
  background-color: #fff;
  padding: 7px;
  z-index: 11;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.2);
  transition: all 250ms ease-out;
  margin-top: 30px;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li {
  display: block;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li a {
  padding: 5px 10px;
  white-space: pre;
  display: block;
  border-radius: 4px;
  font-size: 0.92em;
  color: #777777;
  line-height: 24px;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li a:hover {
  color: #222;
  background-color: #ddd;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li.active a {
  color: #222;
  background-color: #ddd;
}

.shop-top-bar .shop-top-bar-right .shop-short-by:hover span i.angle-down {
  opacity: 0;
  visibility: hidden;
}

.shop-top-bar .shop-top-bar-right .shop-short-by:hover span i.angle-up {
  opacity: 1;
  visibility: visible;
}

.shop-top-bar .shop-top-bar-right .shop-short-by:hover ul {
  opacity: 1;
  visibility: visible;
  margin-top: -5px;
}

.shop-top-bar .shop-top-bar-right .shop-tab a {
  color: #777777;
  font-size: 18px;
  margin-right: 20px;
}

.shop-top-bar .shop-top-bar-right .shop-tab a:last-child {
  margin-right: 0;
}

.shop-top-bar .shop-top-bar-right .shop-tab a.active {
  color: #262626;
}

.product-filter-wrapper {
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  font-size: 12px;
  padding: 40px 55px 34px;
  display: none;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-filter-wrapper {
    padding: 40px 25px 34px;
  }
}

@media only screen and (max-width: 575px) {
  .product-filter-wrapper {
    padding: 40px 25px 34px;
  }
}

.product-filter-wrapper .product-filter h5 {
  font-size: 16px;
  margin: 0 0 17px;
  text-transform: capitalize;
  font-weight: 700;
}

@media only screen and (max-width: 575px) {
  .product-filter-wrapper .product-filter h5 {
    margin: 0 0 10px;
  }
}

.product-filter-wrapper .product-filter .price-filter ul li {
  margin-bottom: 5px;
  display: block;
}

.product-filter-wrapper .product-filter .price-filter ul li:last-child {
  margin-bottom: 0;
}

.product-filter-wrapper .product-filter .price-filter ul li a {
  color: #535353;
  font-size: 12px;
}

.product-filter-wrapper .product-filter .price-filter ul li a:hover {
  color: #4241ff;
}

.product-filter-wrapper .product-filter .widget-nav-list li {
  position: relative;
  margin-bottom: 11px;
  padding: 1px 0;
  width: 49%;
  display: inline-block;
  font-size: 12px;
}

.product-filter-wrapper .product-filter .price-filter ul li {
  margin-bottom: 10px;
}

.product-filter-wrapper .product-filter .product-tags ul {
  width: 100%;
}

.product-filter-wrapper .product-filter .product-tags ul li {
  width: 50%;
  display: inline-block;
  float: left;
}

.product-filter-wrapper .product-filter .fliter-size ul li {
  display: inline-block;
  margin-right: 9px;
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-filter-wrapper .product-filter .fliter-size ul li {
    margin-right: 2px;
  }
}

.product-filter-wrapper .product-filter .fliter-size ul li:last-child {
  margin-right: 0px;
}

.product-filter-wrapper .product-filter .fliter-size ul li a {
  color: #535353;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 9px 16px;
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-filter-wrapper .product-filter .fliter-size ul li a {
    padding: 3px 8px;
  }
}

.product-filter-wrapper .product-filter .fliter-size ul li a:hover {
  color: #fff;
  border: 1px solid #4241ff;
  background-color: #4241ff;
}

.product-filter-wrapper .filter-close-wrap a {
  font-size: 16px;
  color: #4241ff;
}

.product-filter-wrapper .filter-close-wrap a i {
  position: relative;
  top: 3px;
  font-size: 20px;
  margin-right: 3px;
}

.ui-widget-content {
  background: #ddd;
  border: medium none;
  border-radius: 0px;
  height: 3px;
  margin: 35px 0 25px;
}

.ui-widget-content .ui-state-default {
  background: #111;
  border: 3px solid #111;
  border-radius: 25px;
  box-shadow: none;
  color: #ffffff;
  cursor: ew-resize;
  font-weight: normal;
  height: 18px;
  width: 18px;
  top: -8px;
}

.ui-widget-content .ui-state-default:hover {
  background: #111;
}

.ui-widget-content .ui-slider-range {
  background: #111;
}

.filter-price-cont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  font-size: 13px;
  margin-bottom: 10px;
}

.filter-price-cont {
  font-size: 14px;
}

.filter-price-cont .input-type input {
  border: medium none;
  padding: 0 8px 0 0;
  text-align: center;
  width: 40px;
  background: transparent;
}

.filter-price-cont .input-type:last-child {
  padding-left: 8px;
}

/*================================================
=      41. Single Product details Css             =       
==================================================*/
.product-dec-small {
  margin: 5px;
}

.product-small-image {
  margin: 0 -10px;
  margin-top: 20px;
}

.product-dec-small {
  padding: 0 10px;
}

.product-small-image {
  position: relative;
}

.product-small-image .slick-arrow {
  font-size: 14px;
  line-height: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  background: #fff;
  top: calc(50% - 15px);
  text-align: center;
  left: 15px;
  right: auto;
  z-index: 3;
  color: #000;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.15);
}

.product-small-image .slick-arrow.pro-dec-next {
  right: 0px;
  left: auto;
}

.product-details-content p {
  font-size: 14px;
}

.quickview-ratting-wrap {
  display: flex;
  font-size: 14px;
}

.quickview-ratting-wrap .quickview-ratting {
  margin-right: 8px;
  color: #ddd;
}

.quickview-ratting-wrap .quickview-ratting .yellow {
  color: #F5A623;
}

.price {
  font-weight: 300;
}

.quickview-peragraph p {
  font-size: 14px;
}

.quickview-action-wrap {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 20px 0;
  padding: 20px 0;
}

.quickview-action-wrap .quickview-cart-box {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .quickview-action-wrap .quickview-cart-box {
    flex-direction: column;
  }
}

.quickview-action-wrap .quickview-quality, .quickview-action-wrap .quickview-cart {
  margin-right: 20px;
}

.quickview-action-wrap .quickview-wishlist a, .quickview-action-wrap .quickview-compare a {
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  font-size: 24px;
  margin-top: 5px;
}

.quickview-quality {
  margin: 7px 0 0;
}

.quickview-quality .cart-plus-minus {
  display: inline-block;
  height: 56px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100px;
  text-align: center;
}

.quickview-quality .cart-plus-minus .qtybutton {
  color: #333;
  cursor: pointer;
  float: inherit;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
  position: absolute;
  text-align: center;
  transition: all 0.3s ease 0s;
  width: 24px;
}

.quickview-quality .cart-plus-minus .qtybutton.dec {
  height: 54px;
  top: 17px;
  left: 4px;
  font-weight: 600;
  font-size: 20px;
}

.quickview-quality .cart-plus-minus .qtybutton.inc {
  height: 54px;
  top: 18px;
  right: 4px;
  font-weight: 600;
  font-size: 20px;
}

.quickview-quality .cart-plus-minus input.cart-plus-minus-box {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #262626;
  font-size: 14px;
  height: 54px;
  line-height: 54px;
  margin: 0;
  padding: 0px;
  text-align: center;
  width: 100px;
  font-weight: 600;
}

.quickview-quality.quality-height-dec .cart-plus-minus {
  height: 45px;
}

.quickview-quality.quality-height-dec .cart-plus-minus .qtybutton.dec {
  height: 45px;
  top: 11px;
}

.quickview-quality.quality-height-dec .cart-plus-minus .qtybutton.inc {
  height: 45px;
  top: 12px;
}

.quickview-quality.quality-height-dec .cart-plus-minus input.cart-plus-minus-box {
  height: 45px;
  line-height: 45px;
}

.quickview-quality.quality-height-dec2 .cart-plus-minus {
  height: 45px;
  margin-left: -15px;
}

.quickview-quality.quality-height-dec2 .cart-plus-minus .qtybutton.dec {
  height: 45px;
  top: 11px;
  font-weight: 500;
  left: 22px;
}

.quickview-quality.quality-height-dec2 .cart-plus-minus .qtybutton.inc {
  height: 45px;
  top: 12px;
  font-weight: 500;
  right: 22px;
}

.quickview-quality.quality-height-dec2 .cart-plus-minus input.cart-plus-minus-box {
  height: 45px;
  line-height: 45px;
  width: 80px;
  font-weight: 400;
}

.product-support-text a {
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
}

.product-support-text a i {
  margin-right: 10px;
}

.quickview-meta span {
  display: block;
  font-size: 12px;
  margin-bottom: 10px;
}

.pro-details-banner {
  overflow: hidden;
}

@media only screen and (max-width: 575px) {
  .pro-details-banner {
    margin-bottom: 20px;
  }
}

.pro-details-banner a {
  display: block;
}

.product-dec-content {
  padding-left: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .product-dec-content {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 575px) {
  .product-dec-content {
    padding-left: 0px;
  }
}

.product-dec-content p {
  line-height: 1.88;
  margin: 0;
  font-size: 14px;
}

.product-dec-content ul {
  list-style-position: inside;
  margin: 30px 0 50px 12px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-content ul {
    margin: 15px 0 30px 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-content ul {
    margin: 15px 0 30px 12px;
  }
}

@media only screen and (max-width: 575px) {
  .product-dec-content ul {
    margin: 15px 0 20px 12px;
  }
}

.product-dec-content ul li {
  margin-bottom: 16px;
  list-style: disc;
  line-height: 1.88;
  font-size: 14px;
}

.product-dec-content ul li:last-child {
  margin-bottom: 0;
}

.easyzoom-style {
  position: relative;
}

.easyzoom-style a.easyzoom-pop-up {
  position: absolute;
  right: 25px;
  bottom: 25px;
  width: 50px;
  height: 50px;
  background: #fff;
  color: #000;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 19px;
  border-radius: 50%;
  display: block;
  margin: 5px;
  transition: all .3s;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.15);
  z-index: 999;
}

.review-wrapper h2 {
  font-size: 24px;
  margin: 0 0 27px;
}

@media only screen and (max-width: 575px) {
  .review-wrapper h2 {
    font-size: 18px;
    margin: 0 0 18px;
  }
}

.single-review {
  margin: 0 0 60px;
  padding: 50px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
}

@media only screen and (max-width: 575px) {
  .single-review {
    padding: 30px;
    display: block;
    margin: 0 0 30px;
  }
}

.single-review .review-img img {
  border-radius: 100%;
}

.single-review .review-content {
  margin-left: 50px;
}

@media only screen and (max-width: 575px) {
  .single-review .review-content {
    margin-left: 0px;
  }
}

.single-review .review-content .review-top-wrap {
  display: flex;
  justify-content: space-between;
  margin: 0 0 40px;
}

@media only screen and (max-width: 575px) {
  .single-review .review-content .review-top-wrap {
    display: block;
    margin: 10px 0 10px;
  }
}

.single-review .review-content .review-top-wrap .review-name h5 {
  font-size: 12px;
  margin: 0;
}

.single-review .review-content .review-top-wrap .review-name h5 span {
  font-weight: bold;
}

.single-review .review-content .review-top-wrap .review-rating {
  line-height: 1;
}

@media only screen and (max-width: 575px) {
  .single-review .review-content .review-top-wrap .review-rating {
    margin: 10px 0 0px;
  }
}

.single-review .review-content .review-top-wrap .review-rating i {
  font-size: 12px;
  color: #d5d6d8;
}

.single-review .review-content .review-top-wrap .review-rating i.yellow {
  color: #f5b223;
}

.single-review .review-content p {
  font-size: 16px;
  color: #535353;
  line-height: 1.714286;
  margin: 0;
}

@media only screen and (max-width: 575px) {
  .single-review .review-content p {
    font-size: 15px;
  }
}

.ratting-form-wrapper > span {
  display: block;
  line-height: 1;
}

.ratting-form-wrapper p {
  font-size: 12px;
  margin: 6px 0 0;
}

.ratting-form-wrapper p span {
  color: red;
}

.ratting-form-wrapper .ratting-form {
  margin: 30px 0 0;
}

.ratting-form-wrapper .ratting-form .star-box-wrap {
  display: flex;
  margin: 16px 0 29px;
}

.ratting-form-wrapper .ratting-form .star-box-wrap .single-ratting-star {
  margin-right: 17px;
  display: flex;
}

.ratting-form-wrapper .ratting-form .star-box-wrap .single-ratting-star:hover i {
  color: #f5b223;
}

.ratting-form-wrapper .ratting-form .rating-form-style label {
  margin: 0 0 5px;
}

.ratting-form-wrapper .ratting-form .rating-form-style label span {
  color: red;
}

.ratting-form-wrapper .ratting-form .rating-form-style input, .ratting-form-wrapper .ratting-form .rating-form-style textarea {
  background: transparent;
  border: 1px solid #CDCDCD;
  height: 50px;
  padding: 5px 10px;
}

.ratting-form-wrapper .ratting-form .rating-form-style input:focus, .ratting-form-wrapper .ratting-form .rating-form-style textarea:focus {
  border: 1px solid #262626;
}

.ratting-form-wrapper .ratting-form .rating-form-style textarea {
  height: 150px;
}

.ratting-form-wrapper .ratting-form .form-submit input {
  padding: 10px 35px;
  line-height: 30px;
  color: #fff;
  border-width: 0;
  font-weight: 500;
  display: inline-block;
  width: auto;
  height: auto;
  background: #262626;
}

.ratting-form-wrapper .ratting-form .form-submit input:hover {
  background: #4241ff;
}

.about-shiping-content p {
  margin-bottom: 30px;
  line-height: 1.714286;
  font-size: 14px;
}

.about-shiping-content ul {
  margin-bottom: 20px;
  padding-left: 35px;
}

.about-shiping-content ul li {
  margin-bottom: 16px;
  list-style: disc;
  font-size: 14px;
  line-height: 1.88;
}

.about-shiping-content ul li:last-child {
  margin-bottom: 0px;
}

.description-tab-menu {
  margin: 0 -10px;
}

.description-tab-menu li {
  margin: 0 10px;
  flex-grow: 1;
  flex-basis: 0;
}

.description-tab-menu li a {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 1px solid #ddd !important;
  border: #ededed;
  background: #fff;
  color: #696969;
  padding: 21px 20px 19px;
  min-width: 200px;
  text-align: center;
}

.description-tab-menu li a.active {
  color: #4241ff;
  font-weight: 700;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .description-tab-menu li a {
    padding: 21px 10px 19px;
  }
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .description-tab-menu li a {
    padding: 12px 13px 12px;
    min-width: 150px;
    margin-bottom: 10px;
  }
}

.retting-icon {
  margin-right: 17px;
  display: flex;
}

.retting-icon:hover i {
  color: #f5b223;
}

.product-variants {
  display: flex;
}

.product-variants .produt-variants-size {
  margin-right: 30px;
  display: flex;
}

.product-variants .produt-variants-size label {
  font-size: 14px;
  margin-bottom: 0;
}

.product-variants .produt-variants-size a {
  font-weight: 600;
  margin-left: 5px;
}

.product-variants .produt-variants-color {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.product-variants .produt-variants-color label {
  font-size: 14px;
  margin-bottom: 0;
}

.product-variants .produt-variants-color .color-list li {
  display: inline-block;
  margin-left: 5px;
}

.product-variants .produt-variants-color .color-list li a {
  border: 1px solid rgba(0, 0, 0, 0);
  display: block;
  height: 18px;
  width: 18px;
  border-radius: 50000px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.product-variants .produt-variants-color .color-list li a:hover {
  border: 1px solid #333333;
}

.product-variants .produt-variants-color .color-list li a.orange-color {
  background: #f39c11;
}

.product-variants .produt-variants-color .color-list li a.orange-color.active {
  border: 1px solid #333333;
}

.product-variants .produt-variants-color .color-list li a.paste-color {
  background: #5d9cec;
}

.product-variants .produt-variants-color .color-list li a.gray-color {
  background: #919191;
}

.product-variants .produt-variants-color .color-list li a.pink-color {
  background: #ef2fec;
}

/*=========================================
=      42. Product compare Css             =       
===========================================*/
.compare-table .table tbody tr th.first-column {
  min-width: 292px;
  font-size: 15px;
  color: #262626;
  margin: 0;
  font-weight: 400;
  padding: 10px 30px;
  border: none;
  background-color: #f1f1f1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr th.first-column {
    min-width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr th.first-column {
    min-width: 150px;
    padding: 10px 20px;
  }
}

.compare-table .table tbody tr:first-child th {
  padding-top: 53px;
}

.compare-table .table tbody tr:last-child th {
  padding-bottom: 55px;
}

.compare-table .table tbody tr td {
  text-align: center;
  padding: 17px 15px;
  vertical-align: middle;
  border-color: #f1f1f1;
}

.compare-table .table tbody tr td.product-image-title {
  min-width: 293px;
  vertical-align: bottom;
  padding: 27px 10px 35px;
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td.product-image-title {
    min-width: 220px;
  }
}

.compare-table .table tbody tr td.product-image-title .compare-remove a {
  font-size: 13px;
}

.compare-table .table tbody tr td.product-image-title .compare-remove a i {
  padding-right: 5px;
}

.compare-table .table tbody tr td.product-image-title .compare-remove a:hover {
  color: #4241ff;
}

.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin: 14px 0 7px;
  display: block;
}

.compare-table .table tbody tr td.product-image-title .image img {
  width: auto;
}

.compare-table .table tbody tr td.product-image-title .category {
  float: left;
  clear: both;
  color: #4241ff;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.compare-table .table tbody tr td.product-image-title .pro-title {
  display: block;
  overflow: hidden;
}

.compare-table .table tbody tr td.product-image-title .pro-title > a {
  float: left;
  clear: both;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  margin-top: 5px;
  display: block;
  width: 100%;
}

.compare-table .table tbody tr td.product-image-title .pro-title > a:hover {
  color: #4241ff;
}

.compare-table .table tbody tr td.product-image-title .compare-btn {
  margin: 14px 0 0;
}

.compare-table .table tbody tr td.product-image-title .compare-btn a {
  background-color: #343538;
  padding: 10px 20px;
  color: #fff;
  display: inline-block;
}

.compare-table .table tbody tr td.product-image-title .compare-btn a:hover {
  background-color: #4241ff;
}

.compare-table .table tbody tr td.pro-desc p {
  margin: 0;
  font-size: 13px;
  line-height: 1.88;
}

.compare-table .table tbody tr td.pro-price {
  font-size: 14px;
  font-weight: 400;
  color: #343538;
}

.compare-table .table tbody tr td.pro-sku, .compare-table .table tbody tr td.pro-stock, .compare-table .table tbody tr td.pro-weight {
  font-size: 13px;
  font-weight: 400;
}

.compare-table .table tbody tr td.pro-dimensions {
  font-size: 13px;
  font-weight: 400;
}

/*=========================================
=      43. Cart and Checkout page Css             =       
===========================================*/
h3.cart-page-title {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 15px;
}

.cart-table-content table thead > tr th {
  border-top: medium none;
  font-size: 11px;
  text-transform: capitalize;
  vertical-align: middle;
  white-space: nowrap;
  color: #333;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 0 33px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-table-content table thead > tr th {
    padding: 0 0 33px 0;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content table thead > tr th {
    padding: 0 20px 33px 20px;
  }
}

.cart-table-content table tbody > tr td {
  font-size: 14px;
  padding: 0px 0px 40px;
}

@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td {
    padding: 0px 0px 40px;
  }
}

.cart-table-content table tbody > tr td.product-remove {
  width: 30px;
}

.cart-table-content table tbody > tr td.product-remove a {
  color: #777777;
}

.cart-table-content table tbody > tr td.product-img {
  width: 90px;
}

.cart-table-content table tbody > tr td.product-name {
  width: 370px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content table tbody > tr td.product-name {
    width: 250px;
  }
}

@media only screen and (max-width: 575px) {
  .cart-table-content table tbody > tr td.product-name {
    width: 200px;
    padding-left: 20px;
    padding-right: 0px;
  }
}

.cart-table-content table tbody > tr td.product-name a {
  font-size: 12px;
  color: #777;
}

.cart-table-content table tbody > tr td.product-name a:hover {
  color: #4241ff;
}

.cart-table-content table tbody > tr td.product-price {
  width: 90px;
}

.cart-table-content table tbody > tr td.product-price span {
  color: #1f2226;
}

.cart-table-content table tbody > tr td.cart-quality {
  width: 110px;
}

.cart-table-content table tbody > tr td.product-total {
  width: 70px;
}

.cart-table-content table tbody > tr td.product-total span {
  font-weight: 600;
}

.cart-table-content table tbody > tr td.product-wishlist-cart > a {
  background-color: #232323;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  padding: 7px 8px;
  text-transform: capitalize;
}

.cart-table-content table tbody > tr td.product-wishlist-cart > a:hover {
  background-color: #4241ff;
}

.cart-shiping-update-wrapper {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #CDCDCD;
  padding: 18px 0 0;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper {
    display: block;
  }
}

.cart-shiping-update-wrapper .discount-code {
  position: relative;
  flex: 0 0 50%;
}

.cart-shiping-update-wrapper .discount-code input {
  padding: 0 115px 0 0;
  border: none;
  height: 34px;
  font-size: 12px;
  color: #282828;
  background-color: transparent;
  border-bottom: 1px solid #D8D8D8;
}

.cart-shiping-update-wrapper .discount-code button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  padding: 0;
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
}

.cart-shiping-update-wrapper .cart-clear a {
  font-weight: 600;
  display: inline-block;
  margin: 3px 0 0 7px;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper .cart-clear {
    margin-top: 20px;
  }
}

.grand-total-wrap {
  background: #F9F9F9;
  padding: 54px 30px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grand-total-wrap {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .grand-total-wrap {
    margin-top: 40px;
  }
}

.grand-total-wrap h4 {
  font-weight: 800;
  font-size: 14px;
  margin: 0;
}

.grand-total-wrap .grand-total-content {
  padding: 50px 0 35px;
}

.grand-total-wrap .grand-total-content ul li {
  font-weight: bold;
  border-bottom: 1px solid #CDCDCD;
  padding-bottom: 16px;
  margin-bottom: 17px;
}

.grand-total-wrap .grand-total-content ul li:last-child {
  border-bottom: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.grand-total-wrap .grand-total-content ul li span {
  float: right;
  font-weight: 400;
}

.grand-total-wrap .grand-btn {
  margin-left: -30px;
  margin-right: -30px;
}

.grand-total-wrap .grand-btn a {
  background-color: #262626;
  color: #fff;
  text-align: center;
  font-size: 14px;
  display: block;
  padding: 23px 10px 20px;
}

.grand-total-wrap .grand-btn a:hover {
  background-color: #4241ff;
}

/*-------- 26. Checkout style ---------*/
.customer-zone > p {
  position: relative;
  padding: 14px 30px 14px 60px;
  background-color: #eeeff2;
  margin: 0;
}

.customer-zone > p::before {
  content: "\f058";
  color: #6dde6d;
  display: inline-block;
  position: absolute;
  font-family: fontawesome;
  left: 30px;
}

.checkout-login-info {
  display: none;
  margin-top: 30px;
}

.checkout-login-info p {
  color: #777;
  margin: 0 0 22px;
}

.checkout-login-info form input[type="text"] {
  border: 1px solid #CDCDCD;
  height: 50px;
  background-color: transparent;
  width: 48%;
  color: #777;
  font-size: 14px;
  padding: 0 20px;
}

.checkout-login-info form input[type="text"]:focus {
  border: 1px solid #262626;
}

.checkout-login-info form input[type="submit"] {
  background: #262626 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  height: 50px;
  margin-left: 45px;
  padding: 5px 30px 7px;
  text-transform: capitalize;
  font-weight: 400;
  width: inherit;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .checkout-login-info form input[type="submit"] {
    padding: 5px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-login-info form input[type="submit"] {
    padding: 5px 16px;
    margin-left: 5px;
  }
}

.checkout-login-info form input[type="submit"]:hover {
  background-color: #4241ff;
}

.billing-info-wrap h3 {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  margin: 0 0 55px;
}

.billing-info-wrap h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -15px;
  background-color: #000;
  width: 50px;
  height: 2px;
}

.billing-info-wrap .billing-info label {
  display: block;
  margin: 0 0 10px;
}

.billing-info-wrap .billing-info label abbr {
  color: red;
}

.billing-info-wrap .billing-info input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #777;
  width: 100%;
}

.billing-info-wrap .billing-info input:focus {
  border: 1px solid #262626;
}

.billing-info-wrap .billing-info input.billing-address {
  margin-bottom: 20px;
}

.billing-info-wrap .billing-select label {
  display: block;
  margin: 0 0 10px;
}

.billing-info-wrap .billing-select label abbr {
  color: red;
}

.billing-info-wrap .billing-select .select2-container {
  display: block;
}

.billing-info-wrap .billing-select .select2-container .select2-choice {
  border: 1px solid #e6e6e6;
  -moz-appearance: none;
  height: 50px;
  padding: 2px 20px;
  color: #262626;
  cursor: pointer;
}

.billing-info-wrap .billing-select .select2-container .select2-choice .select2-chosen {
  height: 50px;
}

.billing-info-wrap .additional-info-wrap {
  margin: 3px 0 0;
}

.billing-info-wrap .additional-info-wrap label {
  display: block;
  margin: 0 0 10px;
  padding: 5px 0 0;
}

.billing-info-wrap .additional-info-wrap textarea {
  min-height: 120px;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
  padding: 20px;
  color: #8a8a8a;
  width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .billing-info-wrap.mr-100 {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .billing-info-wrap.mr-100 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 575px) {
  .billing-info-wrap.mr-100 {
    margin-right: 0px;
  }
}

.select2-container--default .select2-selection--single {
  border: 1px solid #e6e6e6;
  border-radius: 0px;
  cursor: pointer;
  display: block;
  height: 50px;
  user-select: none;
  -webkit-user-select: none;
  line-height: 50px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  height: 50px;
  color: #262626;
  line-height: 50px;
  padding: 0px 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 50px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  height: 34px;
  background-color: transparent;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #0073aa;
  color: #fff;
}

.your-order-area {
  padding: 40px 45px 50px;
  border-width: 2px;
  border-style: solid;
  position: relative;
  border-color: rgba(52, 53, 56, 0.1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .your-order-area {
    padding: 40px 25px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .your-order-area {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .your-order-area {
    padding: 40px 12px 50px;
    margin-top: 30px;
  }
}

.your-order-area h3 {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  margin: 0 0 45px;
}

.your-order-area h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -14px;
  background-color: #000;
  width: 50px;
  height: 2px;
}

.your-order-area .your-order-wrap .your-order-info {
  border-bottom: 1px solid #CDCDCD;
}

.your-order-area .your-order-wrap .your-order-info ul li {
  padding: 0 0 7px;
}

.your-order-area .your-order-wrap .your-order-info ul li span {
  float: right;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal ul li span {
  font-size: 16px;
}

.your-order-area .your-order-wrap .your-order-info.order-total {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-total ul li span {
  font-size: 18px;
}

.your-order-area .your-order-wrap .your-order-middle {
  border-bottom: 1px solid #CDCDCD;
  padding: 24px 0 23px;
}

.your-order-area .your-order-wrap .your-order-middle ul li {
  font-size: 14px;
  margin: 0 0 11px;
}

.your-order-area .your-order-wrap .your-order-middle ul li:last-child {
  margin-bottom: 0;
}

.your-order-area .your-order-wrap .your-order-middle ul li span {
  float: right;
  font-size: 16px;
}

.your-order-area .your-order-wrap .payment-method {
  margin: 25px 0 0;
}

.your-order-area .your-order-wrap .payment-method h5 {
  text-transform: uppercase;
}

.your-order-area .your-order-wrap .payment-method p {
  color: #777;
  font-size: 12px;
  margin: 14px 0 0;
}

.your-order-area .your-order-wrap .condition-wrap {
  margin: 40px 0 0;
}

.your-order-area .your-order-wrap .condition-wrap p {
  color: #777;
  line-height: 1.88;
  margin: 0;
}

.your-order-area .your-order-wrap .condition-wrap p a {
  color: #777;
}

.your-order-area .your-order-wrap .condition-wrap .condition-form input {
  border: 1px solid #9fa0a2;
  display: inline-block;
  height: 10px;
  width: 10px;
  position: relative;
  top: 6px;
}

.your-order-area .your-order-wrap .condition-wrap .condition-form span {
  color: #262626;
  font-weight: 400;
  margin: 0 0 0 5px;
}

.your-order-area .your-order-wrap .condition-wrap .condition-form span span.star {
  color: red;
}

.your-order-area .Place-order a {
  background-color: #262626;
  display: block;
  cursor: pointer;
  padding: 22px 50px 21px;
  width: 100%;
  color: #fff;
  text-align: center;
}

.your-order-area .Place-order a:hover {
  background-color: #4241ff;
}

/*===========================================
=          44. My account style Css            =
==========================================*/
.myaccount-tab-menu {
  display: flex;
  justify-content: center;
  width: 100%;
  background: #f7f7f7;
}

@media only screen and (max-width: 767px) {
  .myaccount-tab-menu {
    padding: 10px 10px 5px;
  }
}

@media only screen and (max-width: 575px) {
  .myaccount-tab-menu {
    padding: 0px 0px 0px;
  }
}

.myaccount-tab-menu a {
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
  padding: 13px 30px 12px;
  text-transform: capitalize;
  margin: 0 3px 0 3px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .myaccount-tab-menu a {
    padding: 10px 15px 9px;
  }
}

@media only screen and (max-width: 767px) {
  .myaccount-tab-menu a {
    padding: 4px 6px;
    margin: 0 2px 3px 2px;
  }
}

@media only screen and (max-width: 767px) {
  .myaccount-tab-menu a {
    font-size: 13px;
    margin: 0 2px 0px 2px;
  }
}

.myaccount-tab-menu a:hover, .myaccount-tab-menu a.active {
  background-color: #4241ff;
  border-color: #4241ff;
  color: #ffffff;
}

#myaccountContent {
  margin-top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #myaccountContent {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  #myaccountContent {
    margin-bottom: 30px;
  }
}

.myaccount-content form {
  margin-top: -20px;
}

.myaccount-content .welcome {
  margin: 0 0 29px;
}

.myaccount-content .welcome p {
  color: #777;
}

.myaccount-content .welcome p a {
  color: #777;
}

.myaccount-content .welcome p a:hover {
  color: #4241ff;
}

.myaccount-content .welcome strong {
  color: #777777;
}

.myaccount-content > p {
  color: #777;
}

.myaccount-content fieldset {
  margin-top: 20px;
}

.myaccount-content fieldset legend {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.myaccount-content .account-details-form {
  margin-top: 50px;
}

.myaccount-content .account-details-form .single-input-item {
  margin-bottom: 25px;
}

.myaccount-content .account-details-form .single-input-item label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.myaccount-content .account-details-form .single-input-item label span {
  color: red;
}

.myaccount-content .account-details-form .single-input-item input {
  border: 1px solid #CDCDCD;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #262626;
  font-size: 13px;
  width: 100%;
}

.myaccount-content .account-details-form .single-input-item input:focus {
  border: 1px solid #262626;
}

.myaccount-content .account-details-form .single-input-item button {
  border: none;
  background-color: #262626;
  padding: 17px 32px;
  color: #fff;
  font-size: 14px;
  border-radius: 50px;
}

.myaccount-content .account-details-form .single-input-item button:hover {
  background-color: #4241ff;
}

.myaccount-table {
  white-space: nowrap;
  font-size: 14px;
}

.myaccount-table table th,
.myaccount-table .table th {
  padding: 10px;
  font-weight: 600;
  background-color: #f8f8f8;
  border-color: #ccc;
  border-bottom: 0;
  color: #1f2226;
}

.myaccount-table table td,
.myaccount-table .table td {
  padding: 10px;
  vertical-align: middle;
  border-color: #ccc;
}

.saved-message {
  background-color: #fff;
  border-top: 3px solid #4241ff;
  font-size: 14px;
  padding: 20px 0;
  color: #333;
}

/*-------- Start My Account Page Wrapper --------*/
/*-------- 2. Header style ---------*/
.order-tracking-area {
  position: relative;
}

.order-tracking-area .order-tracking-img {
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  width: 50%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .order-tracking-area .order-tracking-img {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .order-tracking-area .order-tracking-img {
    width: 100%;
    position: static;
  }
}

.order-tracking-area .order-tracking-img img {
  height: 100%;
  width: 100%;
  transition: all 0.3s linear 0s;
  will-change: transform, top;
  z-index: 0;
}

.order-tracking-content {
  padding: 94px 135px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .order-tracking-content {
    padding: 50px 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .order-tracking-content {
    padding: 50px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .order-tracking-content {
    padding: 30px 30px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .order-tracking-content {
    padding: 30px 15px 50px;
  }
}

.order-tracking-content p {
  line-height: 1.714286;
  margin: 0;
  font-size: 14px;
  text-align: center;
  width: 98%;
}

.order-tracking-content .order-tracking-form {
  margin: 30px 0 0;
}

.order-tracking-content .order-tracking-form .sin-order-tracking {
  margin: 0 0 30px;
}

.order-tracking-content .order-tracking-form .sin-order-tracking label {
  font-size: 14px;
}

.order-tracking-content .order-tracking-form .sin-order-tracking input {
  background: transparent;
  border: 1px solid #CDCDCD;
  height: 70px;
  padding: 10px 20px;
  color: #737373;
  font-size: 14px;
  width: 100%;
}

.order-tracking-content .order-tracking-form .sin-order-tracking input:focus {
  border: 1px solid #262626;
}

.order-tracking-content .order-tracking-form .order-track-btn {
  text-align: center;
}

.order-tracking-content .order-tracking-form .order-track-btn a {
  display: inline-block;
  font-weight: 700;
  color: #fff;
  background-color: #262626;
  line-height: 1;
  padding: 18px 40px;
}

.order-tracking-content .order-tracking-form .order-track-btn a:hover {
  background-color: #4241ff;
}

/*===========================================
=          45. Preview Page Css            =
==========================================*/
ul.privew-menu {
  display: flex;
}

ul.privew-menu li {
  margin-right: 30px;
}

ul.privew-menu li a {
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  padding: 30px 0;
}

.preview-hero {
  padding: 150px 0 0;
  background: #4241ff;
  overflow: hidden;
}

.preview-hero .preview-hero-text {
  margin-bottom: 100px;
  z-index: 3;
  position: relative;
}

.preview-hero .preview-hero-text h1 {
  font-size: 66px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 40px;
  color: #ffffff;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .preview-hero .preview-hero-text h1 {
    font-size: 40px;
    letter-spacing: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .preview-hero .preview-hero-text h1 {
    font-size: 40px;
    letter-spacing: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .preview-hero .preview-hero-text h1 {
    font-size: 30px;
    letter-spacing: 30px;
  }
}

.preview-hero .preview-images-inner-box {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .preview-hero .preview-images-inner-box {
    display: none;
  }
}

.preview-hero .preview-images-inner-box .preview-image-02 {
  z-index: 0;
  width: 930px;
  max-width: 930px;
  bottom: 0;
  right: -20%;
  position: absolute;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .preview-hero .preview-images-inner-box .preview-image-02 {
    text-align: right;
  }
  .preview-hero .preview-images-inner-box .preview-image-02 img {
    max-width: 700px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .preview-hero .preview-images-inner-box .preview-image-02 {
    text-align: right;
  }
  .preview-hero .preview-images-inner-box .preview-image-02 img {
    max-width: 500px;
  }
}

.single-preview-item {
  position: relative;
  margin-top: 50px;
}

.single-preview-item::after {
  content: 'Check it now';
  position: absolute;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  color: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all .5s;
}

.single-preview-item::before {
  content: '';
  position: absolute;
  background-color: rgba(66, 65, 255, 0.9);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all .5s;
}

.single-preview-item h2 {
  font-size: 16px;
  font-weight: 700;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  left: 30px;
  right: 30px;
  text-transform: uppercase;
  background-color: #fff;
  text-align: center;
  line-height: 16px;
  padding: 30px;
  margin: 0;
  bottom: -60px;
  transition: all .3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.single-preview-item:hover::after {
  opacity: 1;
}

.single-preview-item:hover::before {
  opacity: 1;
}

.single-preview-item:hover h2 {
  opacity: 1;
  visibility: visible;
  bottom: -30px;
}

.single-icon-box-feautres {
  display: flex;
  margin-top: 60px;
}

.single-icon-box-feautres .feautre-image {
  width: 110px;
  display: inline-block;
  margin-right: 15px;
}

@media only screen and (max-width: 767px) {
  .single-icon-box-feautres .feautre-image {
    width: auto;
  }
}

.single-icon-box-feautres .feautre-image img {
  width: 105px;
}

.single-icon-box-feautres .content {
  width: 73.924%;
}

.single-icon-box-feautres .content .title {
  font-weight: 600;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-icon-box-feautres .content .title {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-icon-box-feautres .content .title {
    font-size: 18px;
  }
}

.single-icon-box-feautres .content p {
  line-height: 28px;
  font-size: 14px;
}

.preview-inner-page-area {
  overflow: hidden;
}

.element-preview-display-area {
  height: 480px;
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-end;
}

.element-preview-display-area .overlay {
  display: block;
  position: relative;
}

.element-preview-display-area .overlay::before {
  content: 'Check it now';
  color: #fff;
  transition: all .4s;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  position: absolute;
  opacity: 0;
  z-index: 2;
}

.element-preview-display-area .overlay::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all .4s;
  background-color: rgba(66, 65, 255, 0.9);
  opacity: 0;
}

.element-preview-display-area .overlay:hover {
  z-index: 555;
}

.element-preview-display-area .overlay:hover::before {
  opacity: 1;
  top: 50%;
}

.element-preview-display-area .overlay:hover::after {
  opacity: 1;
}

.element-preview-display-area .overlay img {
  width: 380px;
}

.element-preview-display-area .single-inner-pages {
  position: relative;
}

.element-preview-display-area .inner-pages-layout-1 {
  text-align: center;
  z-index: 2;
  bottom: 0;
  position: absolute;
}

.element-preview-display-area .inner-pages-layout-1:hover {
  z-index: 99;
}

.element-preview-display-area .inner-pages-layout-2 {
  z-index: 1;
  bottom: 0;
  left: 15%;
  position: absolute;
}

.element-preview-display-area .inner-pages-layout-2:hover {
  z-index: 99;
}

.element-preview-display-area .inner-pages-layout-3 {
  z-index: 1;
  bottom: 0;
  left: 35%;
  position: absolute;
}

.element-preview-display-area .inner-pages-layout-3:hover {
  z-index: 99;
}

.element-preview-display-area .inner-pages-layout-4 {
  z-index: 1;
  bottom: 0;
  left: 30%;
}

.element-preview-display-area .inner-pages-layout-4:hover {
  z-index: 99;
}

.element-preview-display-area .inner-pages-layout-5 {
  z-index: 1;
  bottom: 0;
  left: 55%;
  position: absolute;
}

.element-preview-display-area .inner-pages-layout-5:hover {
  z-index: 99;
}

.element-preview-display-area .inner-pages-layout-8 {
  z-index: 1;
  bottom: 0;
  left: 52%;
  position: absolute;
  z-index: 6;
}

.element-preview-display-area .inner-pages-layout-8:hover {
  z-index: 99;
}

.element-preview-display-area .inner-pages-layout-6 {
  z-index: 1;
  bottom: 0;
  right: 20%;
  position: absolute;
}

.element-preview-display-area .inner-pages-layout-6:hover {
  z-index: 99;
}

.element-preview-display-area .inner-pages-layout-7 {
  z-index: 1;
  bottom: 0;
  right: 0;
  position: absolute;
}

.element-preview-display-area .inner-pages-layout-7:hover {
  z-index: 99;
}

.preview-footer-bg {
  background-color: #4241ff;
  background-image: url(../images/preview/landing-bg3.jpg);
  background-position: top center;
  background-repeat: no-repeat;
}

.preview-footer-bg .thank-you-text {
  text-align: center;
}

.preview-footer-text p {
  position: relative;
  color: #ffffff;
  display: inline-block;
  padding: 0 60px;
}

.preview-footer-text p::after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  height: 1px;
  background: #ffffff;
  width: 40px;
}

.preview-footer-text p::before {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  height: 1px;
  background: #ffffff;
  width: 40px;
}

.testimonials-content-preview {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.2);
  margin: 10px 0;
}

.testimonials-content-preview .testimonials-inner {
  padding: 50px 100px;
  text-align: center;
}

.testimonials-content-preview .testimonials-inner .testimonials-text {
  font-family: "Alegreya", serif;
  font-size: 24px;
}

.testimonials-content-preview .testimonials-inner .testimonials-text .testimonials__start {
  margin-bottom: 10px;
}

.testimonials-content-preview .testimonials-inner .testimonials-text .testimonials__start:before {
  content: "\ea5e\ea5e\ea5e\ea5e\ea5e";
  font-family: "LaStudioIcons";
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #f5a623;
  font-size: 14px;
  letter-spacing: 5px;
  display: block;
}

.testimonials-content-preview .testimonials-inner .testimonials-author {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.testimonials-content-preview .testimonials-inner .testimonials-author .author-name {
  text-align: left;
  margin-left: 15px;
}

.testimonials-content-preview .testimonials-inner .testimonials-author .author-name p {
  font-weight: 600;
}
