/*================================================
=      41. Single Product details Css             =       
==================================================*/

.product-dec-small {
     margin: 5px;
}

.product-small-image {
     margin: 0 -10px;
     margin-top: 20px;
}
.product-dec-small {
    padding: 0 10px;
}

.product-small-image {
     position: relative;
     .slick-arrow {
          font-size: 14px;
          line-height: 30px;
          width: 30px;
          height: 30px;
          cursor: pointer;
          position: absolute;
          background: #fff;
          top: calc(50% - 15px);
          text-align: center;
          left: 15px;
          right: auto;
          z-index: 3;
          color: #000;
          box-shadow: 0 0 5px 0px rgba(0,0,0,0.15);
          &.pro-dec-next {
               right: 0px;
               left: auto;
          }
     }
}


.product-details-content {
     p {
          font-size: 14px;
     }
}



.quickview-ratting-wrap {
     display: flex;
     font-size: 14px;
     .quickview-ratting {
          margin-right: 8px;
          color: #ddd;
          & .yellow {
               color: #F5A623;
          }
     }
}

.price {
     font-weight: 300;
}
.quickview-peragraph {
     p {
          font-size: 14px;
     }
}
.quickview-action-wrap {
     border-top: 1px solid #ddd;
     border-bottom: 1px solid #ddd;
     margin: 20px 0;
     padding: 20px 0;
     .quickview-cart-box {
          display: flex;
          align-items: center;
          @media #{$small-mobile}{
               flex-direction: column;
          }
     }
     .quickview-quality ,.quickview-cart {
          margin-right: 20px;
     }
     .quickview-wishlist, .quickview-compare {
          a {
               width: 50px;
               height: 50px;
               line-height: 50px;
               display: inline-block;
               font-size: 24px;
               margin-top: 5px;
          }
     }
}




.quickview-quality {
    margin: 7px 0 0;
    .cart-plus-minus {
        display: inline-block;
        height: 56px;
        overflow: hidden;
        padding: 0;
        position: relative;
        width: 100px;
        text-align: center;
        .qtybutton {
            color: #333;
            cursor: pointer;
            float: inherit;
            font-size: 18px;
            line-height: 20px;
            margin: 0;
            position: absolute;
            text-align: center;
            transition: all 0.3s ease 0s;
            width: 24px;
            &.dec {
                height: 54px;
                top: 17px;
                left: 4px;
                font-weight: 600;
                font-size: 20px;
            }
            &.inc {
                height: 54px;
                top: 18px;
                right: 4px;
                font-weight: 600;
                font-size: 20px;
            }
        }
        input {
            &.cart-plus-minus-box {
                background: transparent none repeat scroll 0 0;
                border: medium none;
                color: #262626;
                font-size: 14px;
                height: 54px;
                line-height: 54px;
                margin: 0;
                padding: 0px;
                text-align: center;
                width: 100px;
                font-weight: 600;
            }
        }
    }
    &.quality-height-dec {
        .cart-plus-minus {
            height: 45px;
            .qtybutton {
                &.dec {
                    height: 45px;
                    top: 11px;
                }
                &.inc {
                    height: 45px;
                    top: 12px;
                }
            }
            input {
                &.cart-plus-minus-box {
                    height: 45px;
                    line-height: 45px;
                }
            }
        }
    }
    &.quality-height-dec2 {
        .cart-plus-minus {
            height: 45px;
            margin-left: -15px;
            .qtybutton {
                &.dec {
                    height: 45px;
                    top: 11px;
                    font-weight: 500;
                    left: 22px;
                }
                &.inc {
                    height: 45px;
                    top: 12px;
                    font-weight: 500;
                    right: 22px;
                }
            }
            input {
                &.cart-plus-minus-box {
                    height: 45px;
                    line-height: 45px;
                    width: 80px;
                    font-weight: 400;
                }
            }
        }
    }
}


.product-support-text {
     a {
          font-size: 14px;
          display: inline-block;
          margin-right: 10px;
          & i {
               margin-right: 10px;
          }
     }
}

.quickview-meta {
     span {
          display: block;
          font-size: 12px;
          margin-bottom: 10px;
     }
}




.pro-details-banner {
    overflow: hidden;
    @media #{$small-mobile} {
        margin-bottom: 20px;
    }
    a {
        display: block;
    }
}
.product-dec-content {
    padding-left: 20px;
    @media #{$tablet-device,$large-mobile} {
        padding-left: 0px;
    }
    @media #{$small-mobile} {
        padding-left: 0px;
    }
    p {
        line-height: 1.88;
        margin: 0;
        font-size: 14px;
    }
    ul {
        list-style-position: inside;
        margin: 30px 0 50px 12px;
        @media #{$desktop-device} {
            margin: 15px 0 30px 12px;
        }
        @media #{$tablet-device} {
            margin: 15px 0 30px 12px;
        }
        @media #{$small-mobile} {
            margin: 15px 0 20px 12px;
        }
        li {
            margin-bottom: 16px;
            list-style: disc;
            line-height: 1.88;
            font-size: 14px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.easyzoom-style {
     position: relative;
     a.easyzoom-pop-up {
          position: absolute;
          right: 25px;
          bottom: 25px;
          width: 50px;
          height: 50px;
          background: #fff;
          color: #000;
          width: 50px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          font-size: 19px;
          border-radius: 50%;
          display: block;
          margin: 5px;
          transition: all .3s;
          box-shadow: 0 0 5px 0px rgba(0,0,0,0.15);
          z-index: 999;
     }
}

.review-wrapper {
    h2 {
        font-size: 24px;
        margin: 0 0 27px;
        @media #{$small-mobile} {
            font-size: 18px;
            margin: 0 0 18px;
        }
    }
}

.single-review {
	margin: 0 0 60px;
	padding: 50px;
	border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    @media #{$small-mobile} {
        padding: 30px;
        display: block;
        margin: 0 0 30px;
    }
    .review-img {
        img {
            border-radius: 100%;
        }
    }
    .review-content {
        margin-left: 50px;
        @media #{$small-mobile} {
            margin-left: 0px;
        }
        .review-top-wrap {
            display: flex;
            justify-content: space-between;
            margin: 0 0 40px;
            @media #{$small-mobile} {
                display: block;
                margin: 10px 0 10px;
            }
            .review-name {
                h5 {
                    font-size: 12px;
                    margin: 0;
                    span {
                        font-weight: bold;
                        
                    }
                }
            }
            .review-rating {
                line-height: 1;
                @media #{$small-mobile} {
                    margin: 10px 0 0px;
                }
                i {
                    font-size: 12px;
                    color: #d5d6d8;
                    &.yellow {
                        color: #f5b223;
                    }
                }
            }
        }
        p {
            font-size: 16px;
            color: #535353;
            line-height: 1.714286;
            margin: 0;
            @media #{$small-mobile} {
                font-size: 15px;
            }
        }
    }
}

.ratting-form-wrapper {
    > span {
        display: block;
        line-height: 1;
    }
    p {
        font-size: 12px;
        margin: 6px 0 0;
        span {
            color: red;
        }
    }
    .ratting-form {
        margin: 30px 0 0;
        .star-box-wrap {
            display: flex;
            margin: 16px 0 29px;
            .single-ratting-star {
                margin-right: 17px;
                display: flex;
                &:hover i {
                    color: #f5b223;
                }
            }
        }
        .rating-form-style {
            label {
                margin: 0 0 5px;
                span {
                    color: red;
                }
            }
            input , textarea {
                background: transparent;
                border: 1px solid #CDCDCD;
                height: 50px;
                padding: 5px 10px;
                &:focus {
                    border: 1px solid #262626;
                }
            }
            textarea {
                height: 150px;
            }
        }
        .form-submit {
            input {
                padding: 10px 35px;
                line-height: 30px;
                color: #fff;
                border-width: 0;
                font-weight: 500;
                display: inline-block;
                width: auto;
                height: auto;
                background: #262626;
                &:hover {
                    background: $theme-color--default;
                }
            }
        }
    }
}

.about-shiping-content {
    p {
        margin-bottom: 30px;
        line-height: 1.714286;
        font-size: 14px;
    }
    ul {
        margin-bottom: 20px;
        padding-left: 35px;
        li {
            margin-bottom: 16px;
            list-style: disc;
            font-size: 14px;
            line-height: 1.88;
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
}


.description-tab-menu {
    margin: 0 -10px;
    & li {
        margin: 0 10px;
        flex-grow: 1;
        flex-basis: 0;
        & a {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.3;
            text-transform: uppercase;
            letter-spacing: 1px;
            border: 1px solid #ddd !important;
            border: rgb(237, 237, 237);
            background: #fff;
            color: #696969;
            padding: 21px 20px 19px;
            min-width: 200px;
            text-align: center;
            &.active{
                color: $theme-color--default;
                font-weight: 700;
            }
            @media #{$desktop-device}{
                padding: 21px 10px 19px;
            }
            @media #{$large-mobile,$tablet-device}{
                padding: 12px 13px 12px;
                min-width: 150px;
                margin-bottom: 10px;
            }
        }
    }
}

.retting-icon {
     margin-right: 17px;
     display: flex;
     &:hover i {
          color: #f5b223;
     }
}


.product-variants {
    display: flex;
    & .produt-variants-size {
        margin-right: 30px;
        display: flex;
        & label {
            font-size: 14px;
            margin-bottom: 0;
        }
        & a {
            font-weight: 600;
            margin-left: 5px;
        }
    }
    .produt-variants-color {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          & label {
               font-size: 14px;
               margin-bottom: 0;
          }
          & .color-list {
               & li {
                    display: inline-block;
                    margin-left: 5px;
                    & a {
                         border: 1px solid rgba(0, 0, 0, 0);
                         display: block;
                         height: 18px;
                         width: 18px;
                         border-radius: 50000px;
                         -webkit-transition: all 0.3s ease-in-out 0s;
                         transition: all 0.3s ease-in-out 0s;
                         
                         &:hover {
                              border: 1px solid #333333;
                         }
                         &.orange-color {
                              background: #f39c11;
                              &.active {
                                   border: 1px solid #333333;
                              }
                         }
                         &.paste-color {
                              background: #5d9cec;
                         }
                         &.gray-color {
                              background: #919191;
                         }
                         &.pink-color {
                              background: #ef2fec;
                         }
                    }
               }  
        }
    }
}