/*======================================================
=           38. Real Estate hero Slider css           =
======================================================*/
.real_estate-slier-three-active {
     & .slick-arrow {
          background: transparent;
          border: none;
          position: absolute;
          left: 10px;
          top: 50%;
          right: auto;
          font-size: 30px;
          color: #fff;
          z-index: 99;
          &.slick-next {
               left: auto;
               right: 10px;
          }
     }
}


.single-real_estate-item {
     height: 800px;
     display: flex !important;
     align-items: center;
}


.single-real_estate-item-16 {
     height: 600px;
     display: flex !important;
     align-items: center;
     & h1 {
          font-size: 36px;
          @media #{$small-mobile}{
               font-size: 28px;
          }
     }
     @media #{$small-mobile}{
          height: 480px;
     }
}


.real_estate-hero-content {
     position: relative;
     padding: 50px;
     background:  rgba(0, 0, 0, 0.7);
     max-width: 750px;
     @media #{$small-mobile}{
          padding: 50px 20px;
     }

     .listing__content-types {
          position: absolute;
          top: 0;
          right: 0;
          .job-type {
               background-color: #3377fa;
               font-family: sans-serif;
               font-weight: 700;
               text-transform: uppercase;
               display: inline-block;
               font-size: 12px;
               padding: 7px 15px 5px;
               vertical-align: middle;
               line-height: 20px;
               color: #fff;
          }
     }

     .properties-location {
          color: $white;
          & i {
               color: $theme-color--four;
          }
     }
     .properties-price {
          color: #75ad1c;
          font-size: 28px;
          letter-spacing: -1px;
          margin: 0 0 5px;
     }
     .listing__content-metaitem {
          display: inline-block;
          margin-right: 30px;
          i {
               color: $theme-color--four;
          }
          .listing__content-metavalue {
               color: $white;
               margin-left: 5px;
          }
     }
}


.find-house-absolate {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    margin: auto;
     // @media #{$desktop-device}{
          
     // }
     @media #{$tablet-device,$large-mobile}{
          left: 0;
          right: 0;
          transform: translate(0%,-50%);
     }

}


.find-house-absolate-inner {
    max-width: 800px;
    margin: auto;
    padding: 60px 60px;
    @media #{$tablet-device}{
         padding: 60px 30px;
     }
    @media #{$large-mobile}{
         padding: 60px 30px;
         width:  100%;
     }
}

.find-house-box-wrap {
     margin-top: -100px;
     background: $white;
     z-index: 99;
     position: relative;
     padding: 80px 120px;
     @media #{$tablet-device} {
          padding: 50px 50px;
     }
          
     @media #{$large-mobile} {
          margin-top: -30px;
          padding: 40px 20px;
     }
          
     .real-estate-form {
          .real-estate-input {
               margin-top: 30px;
               input,select {
                    width: 100%;
                    height: 70px;
                    background: #fff;
                    border-radius: 0;
                    padding: 15px;
                    border: 1px solid #555;
                    color: #111;
                    &:focus {
                         border: 1px solid #555;
                    }
               }
               select {
                    background: #fff url(../images/icons/selector-icon.png) no-repeat center right 20px;
                    background-color: #fff;
               }
          }
          & button {
               width: 100%;
               height: 70px;
               background: $theme-color--five;
               border: none;
               color: $white;
               font-weight: 600;
               font-size: 20px;
               padding: 20px;
               & i {
                    font-size: 28px;
                    float: left;
               }
          }
     }
}



.real-estate-form {
     .real-estate-input {
          margin-top: 30px;
          input,select {
               width: 100%;
               height: 70px;
               background: #fff;
               border-radius: 0;
               padding: 15px;
               border: none;
               color: #111;
               &:focus {
                    border: none;
               }
          }
          select {
               background: #fff url(../images/icons/selector-icon.png) no-repeat center right 20px;
               background-color: #fff;
          }
          textarea {
               width: 100%;
               height: 200px;
               background: #fff;
               padding: 15px;
               border: none;
          }
          
     }
     & button {
          width: 100%;
          height: 70px;
          background: $theme-color--five;
          border: none;
          color: $white;
          font-weight: 600;
          font-size: 20px;
          padding: 20px;
          & i {
               font-size: 28px;
               float: left;
          }
     }
}
.real_estate_hero_video {
    position: absolute;
    right: 0;
    left: 75%;
    top: 50%;
    transform: translate(-50%, -50%);
    .video-btn {
          height: 100px;
          width: 100px;
          line-height: 100px;
          font-size: 30px;
          color: #333;
          &:hover {
               background: $theme-color--five;
               color: #fff;
          }
     }
     @media #{$tablet-device,$large-mobile}{
         display: none;
     }
}
