/*=========================================
=       27. Newsletter Area Css          =
===========================================*/

.newsletter-box-one {
     input {
        border: none;
        border-bottom: 2px solid #111;
        width: 100%;
        font-size: 20px;
        font-weight: 700;
        color: #111;
        position: relative;
        padding: 10px 0;
        &:focus {
             border-color: #111;
             color: #111;
        }
     }  
     .submit-newsletter-btn {
          border: none;
          background: transparent;
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 26px;
          background-color: rgba(0,0,0,0);
          color: #212121;
          padding: 15px 0 13px
     }
}


.newsletter-box-three {
     &--inner {
          position: relative;
          & input {
               padding: 25px 125px 25px 30px;
               color: #fff;
               font-size: 18px;
               line-height: 26px;
               width: 100%;
               border: 2px solid #ddd;
               background: transparent;
               &::placeholder {
                    color: $white;
               }
               &:focus {
                    border: 2px solid #ddd;;
                    background: transparent;
                    color: #fff;
               }
          }
          & .submit-btn {
               position: absolute;
               right: 0;
               top: 0;
               padding: 25px 25px 25px 30px;
               background: transparent;
               border: none;
               color: #fff;
               font-size: 18px;
               font-weight: 700;
               text-transform: uppercase;
               &:hover {
                    color: $theme-color--four;
               }
          }
     }
    
}


.single-order-element {
     position: relative;
     margin-top: 60px;
     .order-element-icon {
          height: 46px;
          margin-bottom: 20px;
          display: flex;
          align-items: flex-end;
          justify-content: center;
     }
     .order-element-content {
          h6 {
               margin-bottom: 10px;
          }
          p {
               font-size: 18px;
               max-width: 250px;
               margin: auto;
          }
          position: relative;
          &::after {
               position: absolute;
               right: -25px;
               top: 50%;
               -webkit-transform: translateY(-50%);
               -ms-transform: translateY(-50%);
               transform: translateY(-50%);
               content: "";
               color: #333;
               height: 50px;
               width: 1px;
               background: #333;
               @media #{$large-mobile}{
                    display: none;
               }
          }
     }
     

     
}
.col-lg-3 {
     &:last-child {
          .single-order-element {
               .order-element-content {
                    &::after {
                         display: none;
                    }
               } 
          }
     }
     @media #{$tablet-device} {
          &:nth-child(2) {
               .single-order-element {
                    .order-element-content {
                         &::after {
                              display: none;
                         }
                    } 
               }
          }
     }
}


