/*============================
    CSS Index         
==============================

    01. Default Css
        - Breadvrumb  
        - Scroll top 
    02. Section Spacing Css
    03. Preloader Css
    04. Offcanvas Search Area
    05. Offcanvas Menu Wrapper   
    06. Offcanvas Minicart Area
    07. Popup Search Css 
    08. Theme Color Css
    09. Humburger Area Css

    10. container Css 
    11. Animate Css 

    12. Header Css
        - Navigation Menu Css
        - Multilevel Submenu Css
        - Mega Menu Css
        - Mobile Menu Overlay Css
    13. Footer Css
    14. Sidebar Css
    15. Contact Form Css
    16. Services Css
    17. Feaures Css
    18. Work Process Css 
    19. Project Css 
    20. Button Css
    21. tastimonials Css 
    22. Team Css
    23. Counter Css  
    24. Brand Area Css 
    25. Product Area Css   
    26. Special Offer Area Css
    27. Newsletter Area Css 
    28. Properties Area Css 
    29. Accordions Css 
    30. Pricing table Css  
    31. Coming Soon Css 
    32. Career Css 
    33. Listing style Css
    34. Toggle Style Css
    35. Progress Bar
    36. Icon Style Css
    36. Blog pages Css
    37. About us pages Css
    38. Real Estate hero Slider css
    39. Architecture hero Slider Css 
    40. Shop style Css
    41. Single Product details Css  
    42. Product compare Css
    43. Cart and Checkout page Css 
    44. My account style Css 
    45. Preview Page Css   

/*=====  End of CSS Index  ======*/


@import 'variabls';
@import 'global/default';
@import 'global/spacing';

@import 'global/preloader';
@import 'global/popup-search';
@import 'global/offcanvas';
@import 'global/theme-color--diffrent';
@import 'global/hamber_menu';


@import 'layout/container';
@import 'layout/animate';
@import 'layout/header';
@import 'layout/footer';
@import 'layout/sidebar';


@import 'elements/contact-us';
@import 'elements/services';
@import 'elements/feaures';
@import 'elements/work-process';
@import 'elements/project';
@import 'elements/button';
@import 'elements/testimonials';
@import 'elements/team';
@import 'elements/counter';
@import 'elements/counterup';
@import 'elements/brand';
@import 'elements/product';
@import 'elements/special-offer';
@import 'elements/newsletter';
@import 'elements/properties';
@import 'elements/faq';
@import 'elements/pricing-table';
@import 'elements/coming-soon';
@import 'elements/career';
@import 'elements/list-style';
@import 'elements/taggles';
@import 'elements/progress-bar';
@import 'elements/icon-box';


@import 'page-section/blog';
@import 'page-section/about';
@import 'page-section/real_estate-hero-slider';
@import 'page-section/architecture-hero';
@import 'page-section/shop';
@import 'page-section/single-product';
@import 'page-section/compare';
@import 'page-section/cart-checkout';
@import 'page-section/my-account';
@import 'page-section/preview-page';

