/*=========================================
=          19. Project Css               =
===========================================*/

.ht-tab-menu-two {
     button {
          text-align: center;
          font-size: 22px;
          font-weight: 400;
          line-height: 1.4em;
          letter-spacing: -2px;
          padding: 0 20px;
          background: transparent;
          border: none;
          color: $white;
          &.is-checked,
          &:hover {
               color: $theme-color--four;
          }
     }
}

.single-project--one {
     .project-top-wrap {
          display: block;
          position: relative;
          & .project-image {
               display: block;  
               img {
                    width: 100%;
               }
          }
          .default-overlay {
               position: absolute;
               height: 100%;
               width: 100%;
               top: 0;
               left: 0;
               &::after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: rgba(66,65,255,.9);
                    content: '';
                    height: 100%;
                    width: 100%;
                    opacity: 0;
                    transition: all 0.4s ease-in-out;
               }
          }
          .project-link-icon {
               position: absolute;
               top: 60%;
               left: 50%;
               transform: translate(-50%, -50%);
               opacity: 0;
               visibility: hidden;   
               transition: all 0.4s ease-in-out;
               text-align: center;
               & .icon-box {
                    background: $white;
                    width: 50px;
                    height: 50px;
                    line-height: 55px;
                    color: #111;
                    margin: 10px;
                    border-radius: 50%;
                    text-align: center;
                    font-size: 20px;
                    &:hover{
                         transform: scale(1.2);
                    }
                    @media #{$large-mobile}{
                         margin: 3px;
                    }
               }
          }
     }
     &:hover {
          & .default-overlay  {
               &::after {
                    opacity: 1;
               }
          }
          & .project-link-icon {
               opacity: 1;
               visibility: visible;
               top: 50%;
          }

     }
}

// Populer Project 

.populere-portfolio__inner{
    display: flex;
    flex-wrap: wrap;
}

.populer-portfolio__content{
     -webkit-flex: 0 0 35.15%;
     -ms-flex: 0 0 35.15%;
     flex: 0 0 35.15%;
     max-width: 35.15%;
     @media #{$tablet-device,$large-mobile}{
          flex: 0 0 100%;
          max-width: 100%;
     }

}
.populere-portfolio__image {
     -webkit-flex: 0 0 64.85%;
     -ms-flex: 0 0 64.85%;
     flex: 0 0 64.85%;
     max-width: 64.85%;
     position: relative;
     & a {
         display: block;
     }
     @media #{$tablet-device,$large-mobile}{
          flex: 0 0 100%;
          max-width: 100%;
     }
}
.populer-portfolio__content_inner {
    background: #000;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: stretch;
    text-align: center;
    box-sizing: border-box;
    height: 100%;
    padding: 20px;

    .project-num-title {
          font-weight: 600;
               padding-top: 20px;
          position: relative;
          &::after{
               position: absolute;
               left: 50%;
               bottom: -10px;
               content: '';
               height: 2px;
               width: 30px;
               background: #fff;
               transform: translateX(-50%);
          }
     }
     @media #{$tablet-device,$large-mobile}{
          padding-bottom: 50px;
     }
}

.populer-project-slider {
     .slick-arrow {
          position: absolute;
          align-items: center;
          cursor: pointer;
          z-index: 999;
          transition: all .2s; 
          border: none;
          width: 50px;
          height: 50px;
          top: calc(100% - 100px);
          background: #fff;
          color: #212121;
          border-radius: 0;
          left: calc(35% + 50px);
          margin-top: 0;
          right: auto;
          &.slick-next {
               margin-left: 60px;
          }
          &:hover {
               background: #111;
               color: #fff;
          }
          @media #{$tablet-device}{
               top: calc(100% - 400px);
          }
          @media #{$large-mobile}{
               top: calc(100% - 400px);
          }
          @media #{$small-mobile}{
               top: calc(100% - 420px);
               left: calc(35% + 0px);
          }
    }
}

.single-project--two {
     &:hover {
          .project-top-wrap {
               .project-image {
                    transform: scale(1.1);
                    &::after {
                         opacity: 1;
                    }
               }
          }
     }
     .project-top-wrap {
          overflow: hidden;
          .project-image {
               position: relative;
               display: block;
               overflow: hidden;
               transform: scale(1);
               &::after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: '';
                    height: 100%;
                    width: 100%;
                    background: rgba(0,0,0,0.2);
                    transition: all 0.3s ease-in-out;
                    opacity: 0;
               }
               img {
                    width: 100%;
               }
          }
     }
     .project-text-content {
          @media #{$large-mobile,$tablet-device} {
               margin-top: 30px;
          }
          .text-dec {
               font-size: 18px;
          }
          @media #{$tablet-device}{
               p {
                    font-size: 16px;
               }
          }
          .more-arrow-btn {
               width: 70px;
               margin-top: 30px;
               svg {
                    & > * {
                         color: #666; 
                    }
               }
          }
     }
}

.single-project {
     &--three{
          position: relative;
          overflow: hidden;
          .project-top-wrap {
               display: block;
               position: relative;
               & .project-image {
                    display: block;  
                    & img {
                         width: 100%;
                    }
               }
               .project-link-icon {
                    position: absolute;
                    top: 60%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    opacity: 0;
                    visibility: hidden;   
                    transition: all 0.4s ease-in-out;
                    & .icon-box {
                         background: $white;
                         width: 50px;
                         height: 50px;
                         line-height: 55px;
                         color: #111;
                         margin: 10px;
                         border-radius: 50%;
                         text-align: center;
                         font-size: 20px;
                         &:hover{
                              transform: scale(1.2);
                         }
                         @media #{$large-mobile}{
                              margin: 5px;
                         }
                    }
               }
          }
          .project-text-content {
               position: absolute;
               bottom: 0;
               width: 100%;
               position: absolute;
               bottom: -50px;
               left: 0;
               background: #212121;
               color: #fff;
               z-index: 2;
               text-align: left;
               padding: 30px;
               transition: all .6s ease-in-out;
               visibility: hidden;
               opacity: 0;
               & p {
                    font-size: 16px;
               }
          }
          &:hover {
               .project-text-content {
                    opacity: 1;
                    visibility: visible;
                    bottom: 0;
               }
               & .project-link-icon {
                    opacity: 1;
                    visibility: visible;
                    top: 50%;
               }

          }
     }
     &--four {
          position: relative;
          overflow: hidden;
          background: #fff;
          margin-top: 40px;
          .project-top-wrap {
               display: block;
               position: relative;
               & .project-image {
                    display: block;  
                    position: relative;
                    &::after {
                         position: absolute;
                         left: 0;
                         top: 0;
                         width: 100%;
                         height: 100%;
                         content: '';
                         background: rgba(0,0,0,0.2);
                         transition: all 0.3s ease-in-out;
                         opacity: 0;
                         visibility: hidden;
                    }
               }
               .project-link-icon {
                    position: absolute;
                    top: 60%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    opacity: 0;
                    visibility: hidden;   
                    transition: all 0.4s ease-in-out;
                    & .icon-box {
                         background: $white;
                         width: 50px;
                         height: 50px;
                         line-height: 55px;
                         color: #111;
                         margin: 10px;
                         border-radius: 50%;
                         text-align: center;
                         font-size: 20px;
                         &:hover{
                              transform: scale(1.2);
                         }
                    }
               }
          }
          .project-text-content {
               padding: 0 20px 40px;
               & .project_catag {
                    font-size: 18px;
                    margin-bottom: 5px;
               } 
               .text-dec {
                    font-size: 18px;
               }
          }
          &:hover {
                & .project-image {
                    &::after{
                         visibility: visible;
                         opacity: 1;
                    }
                } 
               & .project-link-icon {
                    opacity: 1;
                    visibility: visible;
                    top: 50%;
               }

          }   
     }
     &--five{
          position: relative;
          overflow: hidden;
               & .project-image {
                    display: block; 
                    img {
                         width: 100%;
                    } 
               }
               .project-content {
                    background: rgba(0,0,0,0.8);
                    padding: 26px;
                    position: absolute;
                    left: 20px;
                    right: 20px;
                    top: 50px;
                    bottom: -20px;
                    opacity: 0;
                    visibility: hidden;   
                    transition: all 0.4s ease-in-out;

                    h5 {
                         font-size: 20px;
                         margin-bottom: 5px;
                    }
                    .product-number {
                         color: $white;
                         padding-bottom: 12px;
                         font-size: 12px;
                    }
                    p {
                         margin-top: 20px;
                         color: #fff;
                         font-size: 16px;
                    }
                    .btn-lg {
                         background: #fff;
                         color: #111;
                         font-size: 14px;
                         &:hover {
                              background: $theme-color--four;
                              color: #fff;
                         }
                    }
               }
          
          &:hover {
               & .project-content {
                    top: 20px;
                    bottom: 20px;
                    opacity: 1;
                    visibility: visible;
               }

          }
     }
     &--six {
          .images-thum {
               display: block;
               img {
                    width: 100%;
               }
          }
          .project-content {
               display: flex;
               align-items: center;
               justify-content: space-between;
               .content-box {
                    width: 65%;
               }
               @media #{$large-mobile}{
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    .content-box {
                         width: 100%;
                    }
                    p {
                         font-size: 15px;
                    }
                    .button-box {
                         margin-top: 20px;
                         .btn--md {
                              padding: 18px 35px;
                              font-size: 14px;
                              font-weight: 600;
                         }
                    }
               }
          }
     }
}

.prject-divider-one {
     position: relative;
     &::after {
          position: absolute;
          left: 0;
          bottom: 0;
          content: "";
          background: $theme-color--four;
          width: 40px;
          height: 4px;
     }
}
.project-slider-nine-active {
     .slick-arrow {
          position: absolute;
          align-items: center;
          transition: all .2s; 
          border: none;
          width: 70px;
          height: 70px;
          bottom: -80px;
          background: #fff;
          color: #212121;
          border-radius: 50%;
          box-shadow: 0px 0px 23px 0px rgba(0,0,0,.13);
          margin-top: 0;
          left: 45%;
          transform: translateX(-50%);
          font-size: 30px;
          &.slick-next {
               margin-left: 90px;
          }
          &:hover {
               background: $theme-color--default;
               color: #fff;
          }
          @media #{$large-mobile}{
               width: 60px;
               height: 60px;
               font-size: 20px;
               &.slick-next {
                    margin-left: 70px;
               }
          }
     }
     .slick-slide {
          opacity: 0.5;
          &.slick-current {
               opacity: 1;
          }
     }
}

.single-custom-style-03 {
     padding: 250px 0;
     position: relative;
     @media #{$tablet-device}{
          padding: 160px 0;
     }
     @media #{$large-mobile}{
          padding: 100px 0;
     }
     .project-text-content  {
          z-index: 1;
     }
     .more-arrow-btn {
          .icon {
               font-size: 60px;
               color: #fff;
               &:hover {
                    transform: scale(1.1);
               }
          }
     }
}

.single-project-custom-style--04, .single-project-custom-style--05 {
     .project-top-wrap {
          display: block;
          position: relative;
          & .project-image {
               display: block;  
               & img {
                    width: 100%;
               }
          }
          .default-overlay {
               position: absolute;
               height: 100%;
               width: 100%;
               top: 0;
               left: 0;
               &::after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: rgba(66,65,255,.9);
                    content: '';
                    height: 100%;
                    width: 100%;
                    opacity: 0;
                    transition: all 0.4s ease-in-out;
               }
          }
          .project-link-icon {
               position: absolute;
               top: 60%;
               left: 50%;
               transform: translate(-50%, -50%);
               opacity: 0;
               visibility: hidden;   
               transition: all 0.4s ease-in-out;
               text-align: center;
               & .icon-box {
                    background: $white;
                    width: 50px;
                    height: 50px;
                    line-height: 55px;
                    color: #111;
                    margin: 10px;
                    border-radius: 50%;
                    text-align: center;
                    font-size: 20px;
                    &:hover{
                         transform: scale(1.2);
                    }
                    @media #{$large-mobile}{
                         margin: 3px;
                    }
               }
          }
     }
     .project-text-content {
          padding: 20px 30px 30px 30px;
          border: 1px solid #ddd;
          display: flex;
          justify-content: space-between;
          align-items: center;
          & .icon {
               width: 40px;
          }
     }
     &:hover {
          & .default-overlay  {
               &::after {
                    opacity: 1;
               }
          }
          & .project-link-icon {
               opacity: 1;
               visibility: visible;
               top: 50%;
          }

     }
}
.single-project-custom-style--05 {
     
     .project-text-content {
          position: absolute;
          width: 80%;
          margin: auto;
          left: 0;
          right: 0;
          background: #fff;
          bottom: 0;
          padding: 20px 30px 30px 30px;
          border: 1px solid transparent;
          display: flex;
          justify-content: space-between;
          align-items: center;
          & .icon {
               width: 40px;
          }
     }
     &:hover {
          & .default-overlay  {
               &::after {
                    opacity: 1;
               }
          }
          & .project-link-icon {
               opacity: 1;
               visibility: visible;
               top: 50%;
          }

     }
}

.project-breadcrumb-list {
     & .breadcrumb-item {
        display: inline-block;
        font-size: 12px;
        & a {
          position: relative;
          &:hover {
               &::after {
                    width: 100%;
                    left: 0;
                    right: auto;
                    z-index: 0;
               }
          }
     }
          &.active {
               color: #222;
          }
     }
     .breadcrumb-item + .breadcrumb-item::before {
          display: inline-block;
          padding-right: .5rem;
          content: ">";
          color: #111;
     }
}

.bullet-icon-list-items {
     margin-top: 25px;
     .icon-list-item {
          display: flex;
          margin-bottom: 20px;
          align-items: center;
          .list-icon {
               font-size: 12px;
               color: $theme-color--default;
               margin-right: 20px;
          }
     }
}
.project-item-list {
     margin-top: 25px;
     li {
          display: flex;
          font-size: 18px;
          margin-bottom: 20px;
          align-items: center;
          .list-icon {
               margin-right: 20px;
          }
     }
}
.social-list-wrap {
     & li {
          display: inline-block;
          margin-right: 10px;
          & a {
               font-size: 16px;
               height: 40px;
               width: 40px;
               line-height: 40px;
               text-align: center;
               background: #111;
               color: #fff;
               border-radius: 5000px;
               &:hover {
                    background: $theme-color--default;
               }
          }
     }
}

.view-all-page-wrap {
     font-size: 24px;
     font-weight: 600;
     border-bottom: 1px solid #ddd;
     border-top: 1px solid #ddd;
     padding: 30px 0;

     i {
          margin-right: 10px;
          font-size: 18px;
     }
}
.single-project-slider-03--active {
     .slick-arrow {
          position: absolute;
          align-items: center;
          transition: all .2s; 
          border: none;
          width: 70px;
          height: 70px;
          bottom: 50px;
          background: #fff;
          color: #212121;
          border-radius: 50%;
          box-shadow: 0px 0px 23px 0px rgba(0,0,0,.13);
          margin-top: 0;
          left: 50%;
          transform: translateX(-55%);
          font-size: 30px;
          z-index: 9;
          
          &.slick-next {
               margin-left: 90px;
          }
          &:hover {
               background: $theme-color--default;
               color: #fff;
          }
          @media #{$large-mobile}{
               width: 40px;
               height: 40px;
               line-height: 40px;
               bottom: 30px;
               font-size: 16px;
               &.slick-next {
                    margin-left: 50px;
               }
          }
     }
     .slick-slide {
          opacity: 0.5;
          &.slick-current {
               opacity: 1;
          }
     }
}


.single-project-slider-08--active {
     position: relative;
     .slick-arrow {
          position: absolute;
          align-items: center;
          transition: all .2s; 
          border: none;
          width: 70px;
          height: 70px;
          bottom: 50px;
          background: #fff;
          color: #212121;
          border-radius: 50%;
          box-shadow: 0px 0px 23px 0px rgba(0,0,0,.13);
          margin-top: 0;
          left: 50%;
          transform: translateX(-55%);
          font-size: 30px;
          z-index: 9;
          
          &.slick-next {
               margin-left: 90px;
          }
          &:hover {
               background: $theme-color--default;
               color: #fff;
          }
          @media #{$large-mobile}{
               width: 40px;
               height: 40px;
               line-height: 40px;
               bottom: 30px;
               font-size: 16px;
               &.slick-next {
                    margin-left: 50px;
               }
          }
     }
}
.pages-breadcrumb-area {
     align-items: center;
     justify-content: space-between;
     @media #{$small-mobile}{
          flex-direction: column;
     }
}
.post-new-item-list {
     display: flex;
     align-items: center;
     margin: 20px 0;
     @media #{$small-mobile}{
          margin-top: 0;
          margin-bottom: 0px;
     }
     li {
          margin: 0 10px;
          &:last-child {
               margin-right: 0;
          }
     }
}
.single-project-post-details {
     .post-list {
          margin-top: 30px;
          h6 {
               font-weight: 600;
               margin-bottom: 10px;
          }
          p {
              font-size: 14px;  
              color: $theme-color--default;
          }
     }
}

.details-listing {
     .btn--md {
          @media #{$large-mobile}{
               padding: 12px 30px;
          }
     }
}