
/*============================================
=           03. Preloader Css          =
=============================================*/

.la-loader.spinner1, .la-loader.spinner2, .la-loader.spinner3 [class*="bounce"], .la-loader.spinner4 [class*="dot"], .la-loader.spinner5 div {
    background-color: $theme-color--default;
}


.la-image-loading.loaded{
    opacity: 0;
    visibility: hidden;
}
.la-image-loading {
    opacity: 1;
    position: fixed;
    z-index: 999999;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    overflow: hidden;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    visibility: visible;
}

.la-image-loading .content {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    margin-left: -25px;
    margin-top: -25px;
}

.la-loader.spinner1 {
    width: 40px;
    height: 40px;
    margin: 5px;
    display: block;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.15);
    -webkit-box-shadow: 0 0 20px 0 rgba(0,0,0,.15);
    -webkit-animation: la-rotateplane 1.2s infinite ease-in-out;
    animation: la-rotateplane 1.2s infinite ease-in-out;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}
 
.la-image-loading .content::after {
    content: 'zephys';
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 15px;
    text-transform: uppercase;
    white-space: pre;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
}

@-webkit-keyframes la-rotateplane{0%{-webkit-transform:perspective(120px)}50%{-webkit-transform:perspective(120px) rotateY(180deg)}100%{-webkit-transform:perspective(120px) rotateY(180deg) rotateX(180deg)}}@keyframes la-rotateplane{0%{transform:perspective(120px) rotateX(0deg) rotateY(0deg)}50%{transform:perspective(120px) rotateX(-180.1deg) rotateY(0deg)}100%{transform:perspective(120px) rotateX(-180deg) rotateY(-179.9deg)}}@-webkit-keyframes la-scaleout{0%{-webkit-transform:scale(0)}100%{-webkit-transform:scale(1);opacity:0}}@keyframes la-scaleout{0%{transform:scale(0);-webkit-transform:scale(0)}100%{transform:scale(1);-webkit-transform:scale(1);opacity:0}}@-webkit-keyframes la-bouncedelay{0%,80%,100%{-webkit-transform:scale(0)}40%{-webkit-transform:scale(1)}}@keyframes la-bouncedelay{0%,80%,100%{transform:scale(0)}40%{transform:scale(1)}}@-webkit-keyframes la-rotate{100%{-webkit-transform:rotate(360deg)}}@keyframes la-rotate{100%{transform:rotate(360deg);-webkit-transform:rotate(360deg)}}@-webkit-keyframes la-bounce{0%,100%{-webkit-transform:scale(0)}50%{-webkit-transform:scale(1)}}@keyframes la-bounce{0%,100%{transform:scale(0)}50%{transform:scale(1)}}@-webkit-keyframes la-cubeGridScaleDelay{0%{-webkit-transform:scale3d(1,1,1)}35%{-webkit-transform:scale3d(0,0,1)}70%{-webkit-transform:scale3d(1,1,1)}100%{-webkit-transform:scale3d(1,1,1)}}@keyframes la-cubeGridScaleDelay{0%{transform:scale3d(1,1,1)}35%{transform:scale3d(0,0,1)}70%{transform:scale3d(1,1,1)}100%{transform:scale3d(1,1,1)}}