/*=========================================
=          23. Counter Css               =
===========================================*/


.counter__style{
    &--1{
         margin-top: 30px;
        .icon{
             color: $theme-color--default;
             margin-bottom: 20px;
               i{
                    width: 1em;
                    height: 1em;
                    font-size: 60px;
               }
          }
        .content{
            .title{
                color: #2f2f2f;
                font-size: 32px;
                line-height: 1.4em;
            }
            .title2{
                font-size: 32px;
                line-height: 1.4em;
            }
        }
    }

     &--2{
          margin-top: 30px;
          .counter-text {
               font-weight: 400;
          }
          .title{
               color: #666;
               font-weight: 300;
               line-height: 1.5;
          }
     }

     &--3 {
          margin-top: 30px;
          
          .title{
               color: #666;
               font-weight: 300;
               line-height: 1.5;
          }
          .counter-text {
               font-weight: 400;
               position: relative;
               display: inline-block;
               padding: 0 20px;
               &::before{
                    position: absolute;
                    content: "~";
                    left: 0;
               }
          }
    }


    &--4 {
          margin-top: 30px;
          .title{
               color: #666;
               line-height: 2.5;
          }
          .counter-text {
               font-weight: 400;
               position: relative;
               display: inline-block;
               padding: 0 20px;
               &::before{
                    position: absolute;
                    content: "+";
                    right: 0;
               }
          }
     }

}

