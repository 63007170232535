/*===================================
=           36. Icon Style Css         =
====================================*/

.icon__style--1 {
    .inner {
          padding: 30px 50px;
          transition: all 0.3s ease-in-out;
          @media #{$tablet-device} {
               padding: 60px 15px;
          }

          @media #{$desktop-device} {
               padding: 60px 15px;
          }

          @media #{$large-mobile} {
               padding: 60px 15px;
          }

          .content {
               margin-top: 20px;
               .title {
                    font-weight: 600;
               }
               p {
                    font-size: 14px;
               }
               .button-link {
                    font-size: 12px;
                    background-color: rgba(255, 255, 255, 0);
                    color: #2f2f2f;
                    padding: 15px 30px;
                    font-weight: 600;
                    &:hover {
                         text-decoration: underline;
                    }

               }
               .button-link2{
                    color: #2f2f2f;
                    font-size: 16px;
                    line-height: 1.4074;
               }
          }
     }
}

.icon__style {
     &--2 {
     margin-top: 40px;
     .icon {
          i {
                width: 90px;
                height: 90px;
                font-size: 40px;
                border-radius: 50%;
                line-height: 87px;
                background-color: #11ccd3;
                color: $white;
          }

          &.icon_themecolor {
               i {
                    background: #f53e6a;
                    color: #fff;
                    font-size: 30px;;                  
               }

          }
     }

     .content {
          margin-top: 25px;
          .title{
               font-weight: 600;
               margin-bottom: 10px;
               @media #{$desktop-device} {
               font-size: 18px;
               }

               @media #{$tablet-device} {
               font-size: 18px;
               }
          }
          p {
               line-height: 1.5em;

          }
     }
}

    &--3 {
     display: flex;
     margin-bottom: 60px;
     .icon {
          width: 26%;
          margin-top: 19px;
          @media #{$large-mobile} {
               width: auto;
               margin-right: 28px;
          }

          i {
               width: 1em;
               font-size: 64px;
               line-height: 1;
               color: #2f21b3;
               @media #{$desktop-device} {
                    font-size: 40px;
               }
               
               @media #{$tablet-device} {
                    font-size: 40px;
               }

               @media #{$large-mobile} {
                    font-size: 30px;
               }
          }
     }

        .content {
            width: 73.924%;
            .title{
                @media #{$desktop-device} {
                    font-size: 18px;
                }

                @media #{$tablet-device} {
                    font-size: 18px;
                }
            }
            p {
                line-height: 28px;
                font-size: 14px;
            }
        }
    }

   

}
