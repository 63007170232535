/*===========================================
=          45. Preview Page Css            =
==========================================*/

ul.privew-menu {
    display: flex;
    li {
         a {
              font-size: 16px;
              color: $white;
              font-weight: 600;
              padding: 30px 0;
         }
         margin-right: 30px;
    }
}


.preview-hero {
     padding: 150px 0 0;
     background: $theme-color--default;
     overflow: hidden;
     .preview-hero-text {
          margin-bottom: 100px;
          z-index: 3;
          position: relative;
         
          & h1 {
               font-size: 66px;
               font-weight: 800;
               text-transform: uppercase;
               letter-spacing: 40px;
               color: $white;
               margin-bottom: 20px;
               @media #{$tablet-device}{
                    font-size: 40px;
                    letter-spacing: 30px;
               }
               @media #{$large-mobile}{
                    font-size: 40px;
                    letter-spacing: 30px;
               }
               @media #{$small-mobile}{
                    font-size: 30px;
                    letter-spacing: 30px;
               }
          }
     }

     .preview-images-inner-box {
          position: relative;
           @media #{$large-mobile}{
               display: none;
          }
          .preview-image-01 {
               
          }
          .preview-image-02 {
               z-index: 0;
               width: 930px;
               max-width: 930px;
               bottom: 0;
               right: -20%;
               position: absolute;
               @media #{$desktop-device}{
                    text-align: right;
                    img {
                         max-width: 700px;
                    }
               }
               @media #{$tablet-device}{
                    img {
                         max-width: 500px;
                    }
                    text-align: right;
               }
          }
     }
}



.single-preview-item {
     position: relative;
     margin-top: 50px;
     &::after {
          content: 'Check it now';
          position: absolute;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 12px;
          color: #fff;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          opacity: 0;
          transition: all .5s;
     }
     &::before {
          content: '';
          position: absolute;
          background-color: rgba(66, 65, 255, 0.9);
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: all .5s;
     }
     h2 {
          font-size: 16px;
          font-weight: 700;
          box-shadow: 0 0 10px 0 rgba(0,0,0,.16);
          position: absolute;
          left: 30px;
          right: 30px;
          text-transform: uppercase;
          background-color: #fff;
          text-align: center;
          line-height: 16px;
          padding: 30px;
          margin: 0;
          bottom: -60px;
          transition: all .3s ease-in-out;
          opacity: 0;
          visibility: hidden;
     }
     &:hover {
          &::after {
               opacity: 1;
          }
          &::before {
               opacity: 1;
          }
          h2 {
               opacity: 1;
               visibility: visible;
               bottom: -30px;
          }
     }
}



.single-icon-box-feautres {
     display: flex;
     margin-top: 60px;
     .feautre-image {
          width: 110px;
          display: inline-block;
          @media #{$large-mobile} {
               width: auto;
          }
          margin-right: 15px;
          & img {
               width: 105px;
          }
     }

     .content {
          width: 73.924%;
          .title{
               font-weight: 600;
               @media #{$desktop-device} {
                    font-size: 18px;
               }

               @media #{$tablet-device} {
                    font-size: 18px;
               }
          }
          p {
               line-height: 28px;
               font-size: 14px;
          }
     }
}
.preview-inner-page-area {
     overflow: hidden;
}

.element-preview-display-area {
     height: 480px;
     position: relative;
     display: flex;
     width: 100%;
     flex-wrap: wrap;
     align-content: flex-end;

     .overlay{
          display: block;
          position: relative;
          &::before {
               content: 'Check it now';
               color: #fff;
               transition: all .4s;
               font-size: 12px;
               text-transform: uppercase;
               font-weight: 700;
               top: 50%;
               left: 60%;
               transform: translate(-50%,-50%);
               position: absolute;
               opacity: 0;
               z-index: 2;
          }
          &::after {
               content: '';
               position: absolute;
               left: 0;
               top: 0;
               width: 100%;
               height: 100%;
               transition: all .4s;
               background-color: rgba(66, 65, 255, 0.9);
               opacity: 0;
          }
          &:hover {
               &::before {
                    opacity: 1;
                    top: 50%;
               }
               &::after {
                    opacity: 1;
               }
               z-index: 555;
          }
          & img {
               width: 380px;
          }
          
     }

          .single-inner-pages {
               position: relative;
          }
   
          .inner-pages-layout-1{
               text-align: center;
               z-index: 2;
               bottom: 0;
               position: absolute;
               &:hover {
                    z-index: 99;
               }
          }
          .inner-pages-layout-2{
               z-index: 1;
               bottom: 0;
               left: 15%;
               position: absolute;
               &:hover {
                    z-index: 99;
               }
          }
          
          .inner-pages-layout-3 {
               z-index: 1;
               bottom: 0;
               left: 35%;
               position: absolute;
               &:hover {
                    z-index: 99;
               }
          }
          
          .inner-pages-layout-4 {
               z-index: 1;
               bottom: 0;
               left: 30%;
               &:hover {
                    z-index: 99;
               }
          }
          
          
          .inner-pages-layout-5 {
               z-index: 1;
               bottom: 0;
               left: 55%;
               position: absolute;
               &:hover {
                    z-index: 99;
               }
          }
          
          
          .inner-pages-layout-8 {
               z-index: 1;
               bottom: 0;
               left: 52%;
               position: absolute;
               z-index: 6;
               &:hover {
                    z-index: 99;
               }
          }
          
          
          .inner-pages-layout-6 {
               z-index: 1;
               bottom: 0;
               right: 20%;
               position: absolute;
               &:hover {
                    z-index: 99;
               }
          }
          
          
          .inner-pages-layout-7 {
               z-index: 1;
               bottom: 0;
               right: 0;
               position: absolute;
               &:hover {
                    z-index: 99;
               }
          }
          
     }


.preview-footer-bg {
     background-color: $theme-color--default;
     background-image: url(../images/preview/landing-bg3.jpg);
     background-position: top center;
     background-repeat: no-repeat;
     & .thank-you-text {
          text-align: center;
     }
}

.preview-footer-text {
     p {
          position: relative;
          color: $white;
          display: inline-block;
          padding: 0 60px;
          &::after {
               content: '';
               position: absolute;
               left: 0;
               top: 50%;
               height: 1px;
               background: $white;
               width: 40px;
          }
          &::before {
               content: '';
               position: absolute;
               right: 0;
               top: 50%;
               height: 1px;
               background: $white;
               width: 40px;

          }
     }
}


.testimonials-content-preview {
     background-color: #fff;
     border-radius: 5px;
     box-shadow: 0px 0px 11px 0px rgba(0,0,0,.2);
     margin: 10px 0;
     .testimonials-inner {
          padding: 50px 100px;
          text-align: center;
          & .testimonials-text {
               font-family: $font-family-three;
               font-size: 24px;
               & .testimonials__start {
                    margin-bottom: 10px;
                    &:before {
                         content: "\ea5e\ea5e\ea5e\ea5e\ea5e";
                         font-family: "LaStudioIcons";
                         text-transform: none;
                         -webkit-font-smoothing: antialiased;
                         -moz-osx-font-smoothing: grayscale;
                         color: #f5a623;
                         font-size: 14px;
                         letter-spacing: 5px;
                         display: block;
                    }
               }
          }
          .testimonials-author {
               display: flex;
               justify-content: center;
               margin-top: 30px;
               .author-name {
                    text-align: left;
                    margin-left: 15px;
                    p {
                         font-weight: 600;
                    }
               }
          }
     }
}