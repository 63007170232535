/*===========================================
=          44. My account style Css            =
==========================================*/

.myaccount-tab-menu {
    display: flex;
    justify-content: center;
    width: 100%;
    background: #f7f7f7;
    @media #{$large-mobile} {
        padding: 10px 10px 5px;
    }
    @media #{$small-mobile} {
        padding: 0px 0px 0px;
    }
    a {
        font-weight: 400;
        font-size: 14px;
        display: inline-block;
        padding: 13px 30px 12px;
        text-transform: capitalize;
        margin: 0 3px 0 3px;
        @media #{$tablet-device} {
            padding: 10px 15px 9px;
        }
        @media #{$large-mobile} {
            padding: 4px 6px;
            margin: 0 2px 3px 2px;
        }
        @media #{$large-mobile} {
            font-size: 13px;
            margin: 0 2px 0px 2px;
        }
        &:hover,
        &.active {
            background-color: $theme-color--default;
            border-color: $theme-color--default;
            color: $white;
        }
    }
}

#myaccountContent {
    margin-top: 30px;
    @media #{$tablet-device} {
        margin-bottom: 50px;
    }
    @media #{$large-mobile} {
        margin-bottom: 30px;
    }
}

.myaccount-content {
    form {
        margin-top: -20px;
    }
    .welcome {
        margin: 0 0 29px;
        p {
            color: #777;
            a {
                color: #777;
                &:hover {
                    color: $theme-color--default;
                }
            }
        }
        strong {
            color: #777777;
        }
    }
    > p {
        color: #777;
    }
    fieldset {
        margin-top: 20px;
        & legend {
            font-size: 16px;
            margin-bottom: 20px;
            font-weight: 600;
            padding-bottom: 10px;
            border-bottom: 1px solid #ccc;
        }
    }
    .account-details-form {
        margin-top: 50px;
        .single-input-item {
            margin-bottom: 25px;
            label {
                font-size: 14px;
                text-transform: capitalize;
                display: block;
                margin: 0 0 5px;
                span {
                    color: red;
                }
            }
            input {
                border: 1px solid #CDCDCD;
                height: 50px;
                background-color: transparent;
                padding: 2px 20px;
                color: #262626;
                font-size: 13px;
                width: 100%;
                &:focus {
                    border: 1px solid #262626;
                }
            }
            button {
                border: none;
                background-color: #262626;
                padding: 17px 32px;
                color: #fff;
                font-size: 14px;
                border-radius: 50px;
                &:hover {
                    background-color: $theme-color--default;
                }
            }
        }
    }
}

.myaccount-table {
    white-space: nowrap;
    font-size: 14px;
    table,
    .table {
        th {
            padding: 10px;
            font-weight: 600;
            background-color: #f8f8f8;
            border-color: #ccc;
            border-bottom: 0;
            color: #1f2226;
        }

        td {
            padding: 10px;
            vertical-align: middle;
            border-color: #ccc;
        }
    }
}

.saved-message {
    background-color: #fff;
    border-top: 3px solid $theme-color--default;
    font-size: 14px;
    padding: 20px 0;
    color: #333;
}

/*-------- Start My Account Page Wrapper --------*/




/*-------- 2. Header style ---------*/

.order-tracking-area {
    position: relative;
    .order-tracking-img {
        height: 100%;
        padding: 0;
        position: absolute;
        top: 0;
        width: 50%;
        @media #{$tablet-device}{
            width: 50%;
        }
        @media #{$large-mobile}{
            width: 100%;
            position: static;
        }
        img {
            height: 100%;
            width: 100%;
            transition: all 0.3s linear 0s;
            will-change: transform, top;
            z-index: 0;
        }
    }
}

.order-tracking-content {
    padding: 94px 135px;
    @media #{$laptop-device}{
        padding: 50px 50px;
    }
    @media #{$desktop-device}{
        padding: 50px 50px;
    }
    @media #{$tablet-device}{
        padding: 30px 30px 50px;
    }
    @media #{$large-mobile}{
        padding: 30px 15px 50px;
    }
    p {
        line-height: 1.714286;
        margin: 0;
        font-size: 14px;
        text-align: center;
        width: 98%;
    }
    .order-tracking-form {
        margin: 30px 0 0;
        .sin-order-tracking {
            margin: 0 0 30px;
            label {
                 font-size: 14px;
            }
            input {
                background: transparent;
                border: 1px solid #CDCDCD;
                height: 70px;
                padding: 10px 20px;
                color: #737373;
                font-size: 14px;
                width: 100%;
                &:focus {
                    border: 1px solid #262626;
                }
            }
        }
        .order-track-btn {
            text-align: center;
            a {
                display: inline-block;
                font-weight: 700;
                color: #fff;
                background-color: #262626;
                line-height: 1;
                padding: 18px 40px;
                &:hover {
                    background-color: $theme-color--default;
                }
            }
        }
    }
}










