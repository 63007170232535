/*==========================================
=           37. About us pages Css          =
============================================*/
.full-width-row {
     display: flex;
     @media #{$tablet-device}{
          flex-direction: column;
     }
     @media #{$large-mobile}{
          flex-direction: column;
     }
}

.elementor-col-30 {
     width: 30%;
     @media #{$tablet-device,$large-mobile}{
          width: 100%;
     }
}

.elementor-col-40 {
     width: 40%;
     @media #{$tablet-device,$large-mobile}{
          width: 100%;
     }
}

.elementor-col-60 {
     width: 60%;
     @media #{$tablet-device,$large-mobile}{
          width: 100%;
     }
}

.elementor-col-70 {
     width: 70%;
     @media #{$tablet-device,$large-mobile}{
          width: 100%;
     }
}

.about-me-content-one {
     padding: 80px 50px;
     p {
          font-size: 18px;
     }
     .list-contact {
          li {
               font-size: 20px;
               font-weight: 600;
          }
     }
}

.about-me-content-experians {
     padding: 80px 50px;
}

.single-milestones {
     margin-top: 50px;
     h6 {
          font-weight: 600;
          font-size: 20px;
     }
     .drak {
          color: #999;
     }
}

.parallax-imgae-about-me-01 {
     max-width: 440px;
}

.parallax-imgae-about-me-02 {
     bottom: 0;
     max-width: 440px;
     margin-left: auto;
     position: absolute;
     right: 0;
}
.about-me-two {
     p {
          font-size: 18px;
          line-height: 2.5;
          font-weight: 300;
     }
}
.img-before {
     background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover  !important;
     @media #{$tablet-device,$large-mobile}{
          min-height: 400px;
          height: 100%;
     }
}
.about-two-box-wrap {
    margin-top: -80px;
    background: #fff;
    padding-top: 60px;
    @media #{$large-mobile}{
         margin-top: 0;
    }
}


.about-section-bg {
     background: #F9F9F9;
}
.about-section-bg-img {
     z-index: 0;
     width: 100%;
     max-width: 100%;
     bottom: 0;
     right: 0;
     @media #{$desktop-device}{
          margin-top: 150px;
     }
     @media #{$tablet-device}{
          margin-top: 100px;
     }
     @media #{$large-mobile}{
          margin-top: -180px;
     }
     @media #{$small-mobile}{
          margin-top: -80px;
     }
}

.about-section-content {
    position: absolute;
    top: 0;
    padding: 200px 0;


     h2 {
          font-size: 56px;
     }
     p {
          line-height: 2em;
     }

     @media #{$laptop-device}{
          padding: 100px 0; 
     }

     @media #{$desktop-device}{
          padding: 100px 0; 
          h2 {
               font-size: 40px;
          }
     }

     @media #{$tablet-device}{
          padding: 100px 0; 
           h2 {
               font-size: 30px;
          }
     }

     @media #{$large-mobile}{
          position: inherit;
          padding: 50px 0 0; 
           h2 {
               font-size: 24px;
          }
     }
}


// About Us Two 

.section-space-400 {
     height: 350px;
     @media #{$desktop-device}{
          height: 250px;
     }
     @media #{$tablet-device,$large-mobile}{
          height: 170px;
     }
}
.about-section-two-area {
     .about-us-absolate {
          margin-top: -250px;
          position: relative;
     }
}

.about_images-box_two {
     @media #{$desktop-device}{
          margin-top: 80px;
     }
}
.about-us-two-text {
     padding: 0 70px;
     margin-top: 130px;
     @media #{$desktop-device}{
          padding: 0 30px;
          margin-top: 150px;
     }
     @media #{$tablet-device,$large-mobile}{
          margin-top: 150px;
          margin-bottom: 60px;
          padding: 0 30px;
     }
     .dec__text {
         margin-top: 60px;
         @media #{$tablet-device,$large-mobile}{
               margin-top: 80px;
          }
     }
     .read-btn {
         font-size: 18px;
         text-transform: uppercase;
         font-weight: 700;
          & i {
              margin-left: 10px;
              color: $theme-color--default;
              transition: all 0.3s ease-in-out;
         }
          &:hover {
               i {
                   margin-left: 20px;
               }
          }
    }
}

.bg-about-parallax {
     background-position: right top !important;
     background-size: cover !important;
}

.about-section-three {
     h2 {
          font-size: 66px;
          @media #{$desktop-device}{
               font-size: 46px;
          }
          @media #{$tablet-device,$large-mobile}{
               font-size: 26px;
          }
     }
     p {
          font-size: 20px;
          margin-top: 30px;
          @media #{$tablet-device,$large-mobile}{
               font-size: 18px;
          }
     }
}

/*=======================================
=           19. Timeline Css           =
=========================================*/

.ht-timeline {
    &.style-01{
        &.mb-different {
            .item{
                position: relative; 
                padding: 0px 0 17px 45px;
                &:last-child {
                    padding-bottom: 0;
                }
                @media #{$small-mobile}{
                    padding: 0px 0 17px 35px;
                }
            }
        }
        .item{
            position: relative; 
            padding: 0px 0 35px 45px;
            &:last-child {
                padding-bottom: 0;
                &::before {
                    display: none;
                }
            }
            @media #{$small-mobile}{
                padding: 0px 0 35px 35px;
            }

            &::before {
                position: absolute;
                left: 15px;
                margin-left: -1px;
                height: 100%;
                width: 1px;
                content: '';
                background: $theme-color--default;
                @media #{$small-mobile}{
                    left: 5px;
                    margin-top: 2px;
                }
            }


        }
        .dots {
            .middle-dot {
                position: absolute;
                top: 0px;
                left: 15px;
                transform: translate(-50%, 0);
                width: 10px;
                height: 10px;
                border-radius: 50%;
                z-index: 2;
                background: $theme-color--default;
                @media #{$small-mobile}{
                    left: 5px;
                }
            }
        }
        & .tm-timeline-list {
            .timeline-col {
                position: relative;
                display: inline-block;
                width: 100%;
                margin-top: -5px;
                .content-body {
                    & h5 {
                        font-size: 18px;
                        margin-bottom: 3px;
                    }
                    & p {
                        line-height: 1.3;
                        & span {
                            font-weight: 300;
                        }
                    }
                }

                &:last-child {
                    padding-bottom: 0;
                }

            }
        }
    }
    
}


.about-four_image-one {
     max-width: 400px;
     margin-right: 150px;
     margin-left: auto;
}

.about-four_image-two {
     margin-right: -60px;
     margin-top: -300px;
}




.about-section-content-four {
     @media #{$tablet-device}{
          & h2 {
               font-size: 24px;
          }
     }
     @media #{$large-mobile}{
          margin: 60px 0;
     }
}

.about-border-btn {
     padding-left: 120px;
     position: relative;
     font-weight: 700;
     &::after {
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
          content: '';
          background: $theme-color--default;
          height: 4px;
          width: 90px;
     }
}



// Mission Css

.mission-video-bg-01{
     background: url("../images/bg/video-bg-2.jpg");
     background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.our-mission-content {
     padding: 80px;
     max-width: 755px;
     @media #{$large-mobile,$tablet-device}{
          padding: 60px 30px;
     }
     & .mission-title {
          font-weight: 600;
          font-size: 66px;
          margin-bottom: 5px;
          @media #{$tablet-device}{
               font-size: 46px;
          }
          @media #{$large-mobile}{
               font-size: 36px;
          }
          &--two {
               font-size: 56px;
               font-weight: 700;
               @media #{$tablet-device}{
                    font-size: 46px;
               }
               @media #{$large-mobile}{
                    font-size: 36px;
               }
          }
     }
     .info_text {
          font-weight: 400;
          font-size: 20px;
          line-height: 2.2;
     }
     .mission-list {
          li {
               font-size: 24px;
               font-weight: 600;
               text-transform: uppercase;
               font-family: $font-family-two;
               padding-left: 25px;
               position: relative;
               &::after{
                    position: absolute;
                    left: 0;
                    top: 50%;
                    background: $theme-color--default;
                    height: 10px;
                    width: 10px;
                    border-radius: 500px;
                    content: "";
                    transform: translateY(-50%);
               }
          }
          li:not(:last-child) {
               margin-bottom: calc(20px/2);
          }
          &.white-list {
               li {
                    font-size: 20px;
                    font-weight: 700;
                    text-transform: capitalize;
                    font-family: $font-family-name;
                    padding-left: 25px;
                    position: relative;
                    color: #fff;
                    &::after{
                         background: #fff;
                    }
               }
          }
     }
}



.mission-video{
     position: relative;
     height: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
     & a {
          background: #fff;
          color: #111;
          height: 120px;
          width: 120px;
          line-height: 120px;
          border-radius: 50%;
          text-align: center; 
          font-size: 30px;
          color: $theme-color--default;
          &:hover {
               transform: scale(1.2);
          }
     }
}

.mission-bg-02 {
     background: url("../images/bg/m5-bg-1.jpg");
     background-position: center center;
     background-repeat: no-repeat;
     background-size: cover;
}


.our-values-content {
    padding: 80px;
    max-width: 755px;
     & .info_text {
          font-size: 20px;
     }
     .values-list {
          & li {
               img {
                    margin-right: 15px;
                    width: 52px;
               }
               font-size: 20px;
               font-weight: 600;
               margin-bottom: 20px;
               &:last-child {
                   margin-bottom: 0px; 
               }
          }
     }
}


//Parallax-about us area css

.parallax-imgae-01 {
     max-width: 400px;
}
.parallax-imgae-02 {
     margin-top: -150px;
     max-width: 400px;
     margin-left: auto;
     margin-right: 0;
}

// Apps Parallax Images

.properties-apps-area {
    overflow: hidden;
}
.app-parallax-imgae-01 {
     margin-top: 40px;
     max-width: 470px;
}
.app-parallax-imgae-02 {
     max-width: 400px;
     margin-left: auto;
     margin-right: 0;
     margin-top: -450px;
}





// Welcome text Area css 


.welcome-text-title {
     font-size: 32px;
     font-weight: 700;
     letter-spacing: 15px;
     @media #{$small-mobile}{
          letter-spacing: 2px;
          font-size: 22px;
     }
     
}
.welcome-title-wrap {
     & p {
          font-family: "Alegreya",Sans-serif;
          font-size: 24px;
          line-height: 1.6em;
     }
}


.what-we-work-inner {
     padding: 100px 200px;
     @media #{$desktop-device}{
         padding: 100px 100px;
     }
     @media #{$tablet-device}{
         padding: 100px 100px;
     }
     @media #{$large-mobile}{
         padding: 100px 20px;
     }
     p {
          font-size: 22px;
     }

}

.about-content-12 {
     p {
          font-size: 22px;
     }
}

.our-values-text {
     @media #{$large-mobile}{
          margin: 30px 0 60px;
     }
}

.listing-one {
     li {
          margin-bottom: 25px;
          font-weight: 700;
          font-size: 16px;
          @media #{$tablet-device}{
               font-size: 14px;
          }
          .icon-list-icon {
               border: 1px solid #3e3e3e;
               width: 32px;
               height: 32px;
               line-height: 30px;
               text-align: center;
               border-radius: 50%;
               transition: all .3s;
               top: 0;
               display: inline-block;
               margin-right: 5px;
          }
          &:hover {
               .icon-list-icon {
                    border: 1px solid $theme-color--default;
                    background-color: $theme-color--default;
                    color: $white;
               }
          }
     }
}


.single-award-list {
     display: flex;
     align-items: center;
     margin-bottom: 30px;
     img {
          width: 120px;
          margin-right: 40px;
     }
}


.about__counter {
     margin-top: 30px;
}