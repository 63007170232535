/*=========================================
=          20. Button Css               =
===========================================*/


.btn {
    display: inline-block;
    background: #212121;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    margin: 0;
    padding: 20px 40px;
    border: 0;
    cursor: pointer;
    text-align: center;
    line-height: 1;
    transition: all .3s ease;
    display: inline-block;
    border-radius: 0px;
    &--default {
        background: $theme-color--default;
        &:hover {
            background: #111;
            color: #fff;
        }
    }
    &--black {
        background: #222 !important;
        &:hover {
            background: $theme-color--default !important;
            color: #fff !important;
        }
    } 
    &--gray {
        background: #818a91 !important;
        &:hover {
            background: $theme-color--default;
            color: #fff !important;
        }
    }
    &--info {
        background: #5bc0de;
        &:hover {
            background: $theme-color--default;
            color: #fff;
        }
    }
    &--success {
        background: #5cb85c;
        &:hover {
            background: $theme-color--default;
            color: #fff;
        }
    }
    &--warning {
        background: #f0ad4e;
        &:hover {
            background: $theme-color--default;
            color: #fff;
        }
    }
    &--xs {
        padding: 10px 26px;
        font-size: 14px;
    }
    &--sm {
        padding: 14px 30px;
        font-size: 14px;
    }
    &--md {
        padding: 25px 55px;
        font-size: 14px;
        font-weight: 600;
    }
    &--lg {
        padding: 28px 65px;
    }
    
    &--xl {
        padding: 30px 80px;
    }
    &--border-radius {
        border-radius: 50px;
    }
    &--bg-white {
        background: $white;
        color: #333;
        &:hover {
            background: #212121;
            color: #fff;
        }
    }
    &--white {
        background: $white;
        color: #333;
        &:hover {
            background: #212121;
            color: #fff;
        }
    } 
    &--four {
        background: $theme-color--four;
        color: $white;
        &:hover {
            background: #111;
        }
    }

}

.btn--border {
    display: inline-block;
    background: transparent;
    border: 1px solid #d8d8d8 !important;
    color: #333;
    font-weight: 600;
    margin: 0;
    padding: 20px 40px;
    border: 0;
    cursor: pointer;
    text-align: center;
    line-height: 1;
    transition: all .3s ease;
    display: inline-block;
    border-radius: 0px;
    &:hover {
        background: $theme-color--default;
        color: #fff;
        border: 1px solid $theme-color--default;
    }
    &--default {
        background: $theme-color--default;
        &:hover {
            background: #111;
            color: #fff;
        }
    }
    &--black {
        background: #222 !important;
        &:hover {
            background: $theme-color--default !important;
            color: #fff !important;
        }
    }
    &--gray {
        background: #818a91 !important;
        &:hover {
            background: $theme-color--default !important;
            color: #fff !important;
        }
    }
    &--sx {
        padding: 16px 30px;
        font-size: 12px;
        min-width: 150px;
    }
    &--sm {
        padding: 14px 30px;
        font-size: 14px;
    }
    &--md {
        padding: 25px 60px;
        font-size: 14px;
        font-weight: 600;
    }
    &--lg {
        padding: 30px 60px;
    }
    
    &--xl {
        padding: 30px 80px;
    }
    &--border-radius {
        border-radius: 50px;
    }
    &--white {
        background: $white;
        color: #333;
        &:hover {
            background: #212121;
            color: #fff;
        }
    } 

}

.hero-btn-white {
    background: $white;
    color: #222;
    min-width: 210px;
    max-width: 210px;
    height: 70px;
    border-radius: 40px;
    line-height: 70px;
    font-weight: 600;
    font-family: $font-family-four;
    text-transform: uppercase;
    &:hover {
        background: #222;
        color: #fff;
    }
}



.btn-full {
    background: $theme-color--default;
    width: 100%;
    &:hover {
        color: $white !important;
        background-color: #111;
    }
}
.btn-lg-border {
    border: 2px solid #ddd;
    background: transparent;
    color: #333;
    text-transform: uppercase;
    &:hover {
        color: #fff !important;
        background: $theme-color--default;
        border: 2px solid $theme-color--default;
    }
}
.btn-lg-border-1 {
    border: 1px solid #fff;
    background: transparent;
    color: #fff;
    text-transform: uppercase;
    &:hover {
        color: #fff !important;
        background: $theme-color--default;
        border: 1px solid $theme-color--default;
    }
}




// Video Button
.video-btn {
    background: #fff;
    color: #111;
    height: 120px;
    width: 120px;
    line-height: 120px;
    border-radius: 50%;
    text-align: center; 
    font-size: 30px;
    color: $theme-color--default;
    transition: all 0.3s ease-in-out;
    @media #{$large-mobile}{
        height: 80px;
        width: 80px;
        line-height: 80px;
    }
    &:hover {
        transform: scale(1.2);
    }
    &--small {
       background: #fff;
        color: #111;
        height: 80px;
        width: 80px;
        line-height: 80px;
        border-radius: 50%;
        text-align: center; 
        font-size: 20px;
        color: $theme-color--default;
        transition: all 0.3s ease-in-out !important;
        &:hover {
            transform: scale(1.2);
        } 
    }
}

.absolue-video-btn {
    position: absolute;
    z-index: 9;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.btn-three {
    background: $theme-color--five;
    font-weight: 600;
    font-size: 18px;
    &:hover {
        background: $black;
        color: $white;
    }
}






.x-small-btn {
    min-width: 88px;
    text-align: center;
    padding: 6px 10px;
    font-size: 14px;
}

.small-btn {
    min-width: 98px;
    text-align: center;
    padding: 8px 10px;
    font-size: 14px;
}


.medium-btn {
    min-width: 128px;
    text-align: center;
    padding: 14px 25px;
    font-size: 16px;
}

.large-btn {
    min-width: 148px;
    text-align: center;
    padding: 18px 38px;
    font-size: 16px;
}

.extra-large-btn {
    min-width: 170px;
    text-align: center;
    padding: 22px 48px;
    font-size: 18px;
}


.btn--border-1 {
    border: 1px solid #333;
    &:hover {
        border: 1px solid $theme-color--default;
        background: $theme-color--default;
        color: $white;
    }
}

.btn--border-2 {
    border: 2px solid #333;
    &:hover {
        border: 2px solid $theme-color--default;
        background: $theme-color--default;
        color: $white;
    }
}

.btn--border-3 {
    border: 3px solid #333;
    &:hover {
        border: 3px solid $theme-color--default;
        background: $theme-color--default;
        color: $white;
    }
}

.btn--border-4 {
    border: 4px solid #333;
    &:hover {
        border: 4px solid $theme-color--default;
        background: $theme-color--default;
        color: $white;
    }
}

.btn--border-solid-default {
    border: 1px solid $theme-color--default;
    &:hover {
        border: 1px solid $theme-color--default;
        background: $theme-color--default;
        color: $white !important;
    }
}


.btn--padding-8 {
    text-align: center;
    padding: 8px;
    font-size: 14px;
    line-height: 1;
}
.btn--padding-12 {
    text-align: center;
    padding: 12px;
    font-size: 14px;
    line-height: 1;
}
.btn--padding-16 {
    text-align: center;
    padding: 16px;
    font-size: 14px;
    line-height: 1;
}
.btn--padding-20 {
    text-align: center;
    padding: 20px;
    font-size: 14px;
    line-height: 1;
}
.btn-radius-2 {
    border-radius: 2px;
}
.btn-radius-10 {
    border-radius: 10px;
}
.btn-radius-18 {
    border-radius: 18px;
}
.btn-radius-50 {
    border-radius: 50px;
}


.btn--border-double {
    border-style: double;
    border-width: 4px;
    border-color: #2f2f2f;
    &:hover {
        border-color: $theme-color--default;
        background: $theme-color--default;
        color: $white;
    }
}

.btn--border-dotted {
    border: 2px dotted #333;
    &:hover {
        border: 2px dotted $theme-color--default;
        background: $theme-color--default;
        color: $white;
    }
}

.btn--border-dashsed {
    border: 2px dashed #333;
    &:hover {
        border: 2px solid $theme-color--default;
        background: $theme-color--default;
        color: $white;
    }
}















