/*=============================================
=            33. Listing style Css           =
==============================================*/

.list-style {
     &--default {
          li {
               font-size: 14px;
               margin-bottom: 10px;
               &:last-child {
                    margin-bottom: 0;
               }
               & i {
                    margin-right: 5px;
               }
          }
     }
     
     &--divider {
          li {
               font-size: 14px;
               margin-bottom: 10px;
               padding-bottom: 10px;
               border-bottom: 1px solid #ddd;
               &:last-child {
                    border: none;
                    margin-bottom: 0;
                    padding-bottom: 0;
               }
               & i {
                    margin-right: 5px;
               }
          }
     }
     &--dpace-between {
         &_lg​  {
               li {
                    font-size: 14px;
                    margin-bottom: 20px;
                    &:last-child {
                         margin-bottom: 0;
                    }
                    & i {
                         margin-right: 5px;
                    }
               }
          }
         &_md  {
               li {
                    font-size: 14px;
                    margin-bottom: 14px;
                    &:last-child {
                         margin-bottom: 0;
                    }
                    & i {
                         margin-right: 5px;
                    }
               }
          }
          &_sm {
               li {
                    font-size: 14px;
                    margin-bottom: 8px;
                    &:last-child {
                         margin-bottom: 0;
                    }
                    & i {
                         margin-right: 5px;
                    }
               }
          }
          
     }
     &--text-color {
          li {
               font-size: 14px;
               margin-bottom: 10px;
               color: $theme-color--default;
               &:last-child {
                    margin-bottom: 0;
               }
               & i {
                    margin-right: 5px;
                    color: #222;
               }
          }
     }
     &--icon-color {
          li {
               font-size: 14px;
               margin-bottom: 10px;
               &:last-child {
                    margin-bottom: 0;
               }
               & i {
                    margin-right: 5px; 
                    color: $theme-color--default;
               }
          }
     }
     &--color-full {
          li {
               font-size: 14px;
               margin-bottom: 10px;
               color: $theme-color--default;
               &:last-child {
                    margin-bottom: 0;
               }
               & i {
                    margin-right: 5px; 
               }
          }
     }
}