
/*=========================================
=          25. Product Area Css               =
===========================================*/


.ht-tab-menu-three {
     & li {
          margin-bottom: 10px;
          a {
               text-align: center;
               color: #212121;
               font-size: 18px;
               font-weight: 400;
               line-height: 20px;
               padding: 20px 40px;
               margin: 0 5px;
               border-style: solid;
               border-width: 1px;
               border-color: #dedede;
               &.active,
               &:hover {
                    background: $theme-color--four;
                    color: $white;
                    border: 1px solid $theme-color--four;
               }
               @media #{$large-mobile}{
                    padding: 14px 20px;
               }
          }
     }
}

.single-product-item {
     margin-top: 40px;
     .prodict-image {
          display: block;
          & img {
               width: 100%;
          }
          &.overlay-black {
               position: relative;
               &::after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: rgba(0, 0, 0, 0.4);
                    height: 100%;
                    width: 100%;
                    content: '';
                    opacity: 0;
                    transition: all 0.3s ease-in-out;
               }
          }
     }
     .product-image-wrap {
          position: relative;
     }
     .product-action {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          z-index: 2;
          text-align: center;
          // margin-top: -25px;
          a {
               background-color: #fff;
               color: #212121;
               border-radius: 50%;
               padding: 0;
               position: relative;
               margin: 5px;
               opacity: 0;
               -webkit-transform: translate(0, 50px);
               transform: translate(0, 50px);
               transition-delay: 100ms;
               width: 50px;
               height: 50px;
               line-height: 50px;
               display: inline-block;
               font-size: 22px;
               & + a {
                    transition-delay: 150ms;
               }
              
               .tooltip {
                    visibility: hidden;
                    position: absolute;
                    background-color: #fff;
                    text-align: center;
                    padding: 5px 12px;
                    z-index: 3;
                    opacity: 0;
                    transition: opacity .6s, margin .3s;
                    white-space: pre;
                    font-size: 11px;
                    line-height: 14px;
                    top: -20px;
                    left: 50%;
                    transform: translateX(-50%);
                    color: #333;
                    &::after {
                         border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff;
                         border-style: solid;
                         border-width: 5px 0 5px 5px;
                         content: "";
                         height: 0;
                         position: absolute;
                         bottom: 10px;
                         left: 50%;
                         width: 0;
                         bottom: -6px;
                         transform: translateX(-50%) rotate(90deg);
                    }
               }
                &:hover{
                    background: $theme-color--four;
                    color: $white;
                    .tooltip {
                         visibility: visible;
                         opacity: 1;
                         top: -35px;
                    }
               }
    
          }
     }
     .prodect-content {
          margin-top: 15px;
          h6 {
               font-size: 16px;
               margin: 0 0 5px;
               line-height: inherit;
               font-weight: normal;
               white-space: nowrap;
               overflow: hidden;
               text-overflow: ellipsis;
          }
          .prodect-price {
               font-weight: 600;
          }
     }
     .prodect-content-absolut {
          position: absolute;
          bottom: 0;
          transform: translate(0, 50px);
          transition-delay: 100ms;
          opacity: 0;
          padding: 20px 30px;
          transition: all 0.3s ease-in-out;
          h6 {
               font-size: 16px;
               margin: 0 0 2px;
               line-height: inherit;
               font-weight: normal;
               white-space: nowrap;
               overflow: hidden;
               text-overflow: ellipsis;
               color: #fff;
               a {
                    color: #fff;
               }
          }
          .prodect-price {
               font-weight: 600;
               .new-price {
                    color: $white;
               }
          }
     }
     &:hover{
          a {
               opacity: 1;
               transform: translate(0, 0);
               
          }
          & .overlay-black {
               &::after {
                    opacity: 1;
               }
          }
          .prodect-content-absolut {
               transform: translate(0, 0);
               opacity: 1;
          }
     }
}


.single-product-instagram {
     margin-bottom: 60px;
     @media #{$tablet-device}{
          margin-bottom: 30px;
     }
     @media #{$large-mobile}{
          margin-bottom: 30px;
     }
     .prodict-image {
          display: block;
          & img {
               width: 100%;
          }
          &.overlay-default {
               position: relative;
               &::after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: rgba(66,65,255,.9);
                    height: 100%;
                    width: 100%;
                    content: '';
                    opacity: 0;
                    transition: all 0.3s ease-in-out;
               }
          }
     }
     .product-instagram__meta {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          z-index: 2;
          text-align: center;
          color: $white;
          opacity: 0;
          transition: all 0.3s ease-in-out;
          & .icon-list {
               display: inline-block;
               margin: 5px;
               & i {
                    margin-right: 3px;
               }
          }
     }
      &:hover{
          .product-instagram__meta {
               opacity: 1;
          }
          & .overlay-default {
               &::after {
                    opacity: 1;
               }
          }
     }
}

.product-list-item {
     margin-top: 40px;
     .single-product-item {
          margin-top: 0px;
     }
     .prodect-content {
          @media #{$large-mobile}{
               margin-top: 30px;
          }
          margin-top: 0px;
          .prodect-price {
               margin-top: 15px;
               margin-bottom: 15px;
          }
          .product-action {
               a {
                    margin-right: 20px;
                    i {
                         
                         font-size: 20px;
                    }
               }
          }
          p {
               font-size: 14px;
          }
     }
}





.modal-dialog {
	margin: 5% auto 8%;
	max-width: 1358px;
	width: 1358px;
    
    @media #{$laptop-device} {
        width: 1300px;
        max-width: 1300px;
    }
    @media #{$laptop-device} {
        width: 1150px;
        max-width: 1150px;
    }
    @media #{$desktop-device} {
        width: 960px;
        max-width: 960px;
    }
    @media #{$tablet-device} {
        width: 720px;
        max-width: 720px;
    }
    @media #{$large-mobile} {
        width: 100%;
        max-width: 100%;
        padding: 35px 0;
    }
    .modal-content {
          border-radius: 0;
          .modal-header {
               border: none;
               padding: 0;
               right: -13px;
               top: -31px;
               position: absolute;
               z-index: 9999;
               @media #{$large-mobile} {
                    right: 0px;
                    top: -10px;
               }
               .close {
                    color: #fff;
                    cursor: pointer;
                    opacity: 1;
                    padding: 0;
                    margin: 0;
                    width: 54px;
                    height: 54px;
                    line-height: 60px;
                    background-color: #292929;
                    border-radius: 100%;
                    font-size: 18px;
                    text-shadow: none;
                    @media #{$large-mobile} {
                    width: 34px;
                    height: 34px;
                    line-height: 34px;
                    font-size: 15px;
                    }
                    &:hover {
                    color: $theme-color--default;
                    }
               }
          }
          .modal-body {
               padding: 0px;
               overflow-y: auto;
               max-height: 562px;
          }
     }
}

.quickview-content-wrap {
    padding: 42px 35px 20px;
    @media #{$desktop-device} {
        padding: 15px 35px 20px;
    }
    @media #{$tablet-device} {
        padding: 20px 35px 35px;
    }
    @media #{$large-mobile} {
        padding: 15px 15px 15px;
    }
    h2 {
        font-size: 26px;
        font-weight: 600;
        margin: 0 0 2px;
        @media #{$large-mobile} {
            font-size: 26px;
        }
    }

}